import { useEffect, useState } from 'react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import axios, { AxiosResponse } from 'axios';

import { parseQueryString } from '@curology-engineering/great-gatsby/utils';

import cookie from 'utils/cookie';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import APP_URLS from 'consts/appUrls';

export interface PromotionDataType {
  id: number;
  code: string;
  isActive: boolean;
  startsAt: string;
  endsAt: string;
  amountInDollars: number;
}

export function useActivePromotion() {
  const [promotion, setPromotion] = useState<PromotionDataType | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    const urlSegments = pathname.split('/').filter((piece) => !!piece);
    const isPromotionUrl = urlSegments[0] === 'promotions';
    const hasPromotionCookie = cookie.has(COOKIE_KEYS.promotionCode);
    const params = parseQueryString(window?.location);

    if (isPromotionUrl || hasPromotionCookie) {
      const promotionCode = isPromotionUrl
        ? params?.promoCode
        : cookie.get(COOKIE_KEYS.promotionCode);

      if (promotionCode) {
        axios
          .get(`${APP_URLS.api.promotions}/${promotionCode}`)
          .then((response: AxiosResponse<{ promotion: PromotionDataType }>) => {
            setPromotion(response.data.promotion);

            if (
              !cookie.has(COOKIE_KEYS.promotionCode) ||
              cookie.get(COOKIE_KEYS.promotionCode) !== promotionCode
            ) {
              cookie.set(COOKIE_KEYS.bannerPromotionCode, promotionCode, {
                expires: 1,
              });

              cookie.set(COOKIE_KEYS.promotionCode, promotionCode, {
                expires: 1,
              });
            }
          })
          .catch(() => {
            cookie.remove(COOKIE_KEYS.bannerPromotionCode);
            cookie.remove(COOKIE_KEYS.promotionCode);
            navigate('/', { replace: true });
          });
      }
    }
  }, []);

  return promotion;
}
