import * as Sentry from '@sentry/gatsby';
import { HttpClient } from '@sentry/integrations';

import { LOGGING_ENABLED } from './src/consts/environmental';
import { isDataDogSynthTestBot } from './src/utils/isDDBot';
import { Integration } from '@sentry/types';

Sentry.init({
  /*
   * We're using the enabled option here instead of conditionally loading the plugin since
   * using a different number of plugins between different builds causes a cache rejection.
   * This method allows CI caches to be shared between preview/staging/production builds.
   */
  enabled: LOGGING_ENABLED,
  denyUrls: [
    // third-party pixels
    /adsrvr/i,
    /bing\.com/i,
    /nextdoor\.com/i,
    /ocuboost\.com/i, 
    /ocurate\.com/i,
    /osano\.com/i,
    /pdst\.fm/i,
    /sc-static\.net/i,
    /spotify\.com/i,
    /tiktok/i,

    // I am not really sure what injects scripts from this domain; could be a CF worker, but it 
    // is very noisy.
    /cloudflareinsights\.com/i,

    // this is a consent manager used on some sites, sometimes the api calls to it that browsers make barf
    /privacyedge-lokker/i,

    // Chrome extensions
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    /extensions\//i,
    /safari-web-extension/i,
  ],
  dsn: 'https://e24957594a7a4d248dd9b276472720c2@o277339.ingest.sentry.io/6660211',
  integrations: [
    new HttpClient({
      failedRequestStatusCodes: [400, 599],
    }) as unknown as Integration,
  ],
  tracesSampleRate: 0,
  beforeSend(event, hint) {
    if (isDataDogSynthTestBot(event.request)) {
      return null;
    }

    const error_message = getMessageFromException(hint.originalException);

    // denyUrls seems unreliable because the error thrown from the last frame is not always the actual file where the error occurred, but sometimes 
    // where it is *caught*. See https://github.com/getsentry/sentry-javascript/issues/3045#issuecomment-726450247
    const ignoreUrlPatterns = [
      // third-party pixels
      /ocuboost\.com/i, 
      /ocurate\.com/i,
      // Sometimes/often a POST to https://evnt.byspotify.com will fail, and then just after that we will an 
      // UnhandledRejection: Non-Error promise rejection captured with value: undefined error. These don't seem to get filtered 
      // by the `denyUrls` so I am hoping to catch/filter them here -- maybe they are in one of the frames.
      /byspotify\.com/i,
      /tiktok/i,
      
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,        
    ];

    const values = event?.exception?.values || [];
    const frames = values[0]?.stacktrace?.frames || [];
    const filesFromIgnoredUrls = [];
    frames.forEach( (frame) => {
      const sourceFile = frame.filename;
      if (ignoreUrlPatterns.some( (pattern) => sourceFile && pattern.test(<string>sourceFile) )) {
        filesFromIgnoredUrls.push(sourceFile);
      }
    })

    if (filesFromIgnoredUrls.length > 0) {
      return null
    }
    
    const ignoredErrorPatterns = [
      /loading chunk \d* failed./i,
      /Minified React error./i,
      /Hydration failed because the initial UI does not match what was rendered on the server/i,
      /There was an error while hydrating/i,
      /Non-Error exception captured/i,
      /Non-Error promise rejection captured/i,
      /\(type=unhandledrejection\) captured as promise rejection/i,
      /couldn't find the correct component chunk/i,
      // issue with some chromium browsers, likely a bug in them, not our code
      /SecurityError: Failed to execute 'sendBeacon'/i,
      /Operation is insecure/i,
      // This is just chunkload errors by a different name, because Gatsby catches and rethrows them
      /We couldn't load \"\/page-data/i,

      // Network errors aren't actionable, and lots of 3p code doesn't catch them properly.
      /NetworkError/i,
    ];

    if (
      ignoredErrorPatterns.some(
        (pattern) => error_message && pattern.test(<string>error_message),
      )
    ) {
      return null;
    }

    return event;
  },
  ignoreErrors: ['Minified React error', 'Loading chunk'],
});

const getMessageFromException = (e) => {
  if (typeof e === 'string') {
    return e;
  }

  if (e !== null && typeof e === 'object') {
    return e.message ? e.message : '';
  }

  // fall through
  return '';
};
