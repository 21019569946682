import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { Z_SCALE, MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_CONTENT_MAX_WIDTH } from 'styles/constants';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

export const OuterContainer = styled.div`
  background-color: ${({ theme }) => theme.COLORS.tertiaryTint1};

  ${MEDIA_QUERIES.xlUp} {
    padding: ${SPACER.medium} 0;
  }
`;

export const InnerContainer = styled.div`
  max-width: ${LOCAL_CONTENT_MAX_WIDTH};
  margin: 0 auto;
`;

export const DesktopContainer = styled.div`
  ${desktopOnly('flex', 'xlUp')}
  align-items: center;
  justify-content: center;
  gap: ${SPACER.xlarge};
`;

export const titleStyles = (theme: ThemeType) => css`
  padding: ${SPACER.medium} 0;
  ${textStyles(theme)}

  ${MEDIA_QUERIES.xlUp} {
    padding: 0;
  }
`;

export const textStyles = (theme: ThemeType) =>
  css`
    color: ${theme.COLORS.primary};
  `;

export const listStyles = (theme: ThemeType) => css`
  list-style: none;
  counter-reset: steps-counter;
  margin-bottom: ${SPACER.large};
  position: relative;

  &:before {
    position: absolute;
    display: block;
    width: 1px;
    height: 100%;
    background-color: ${theme.COLORS.primary};
    left: 11.5px;
    content: '';
    top: 0;
  }

  ${MEDIA_QUERIES.xlUp} {
    display: flex;
    margin: auto 0;
    gap: ${SPACER.xlarge};

    &:before {
      display: none;
    }
  }
`;

export const ctaButtonStyles = css`
  padding-bottom: ${SPACER.large};
`;

export const listItemStyles = (theme: ThemeType) => css`
  counter-increment: steps-counter;
  display: flex;
  margin-bottom: ${SPACER.medium};
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:before {
    border-radius: 100%;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px ${theme.COLORS.primary};
    content: counter(steps-counter);
    font-weight: bold;
    margin-right: ${SPACER.small};
    color: ${theme.COLORS.primary};
    flex-shrink: 0;
    background-color: ${theme.COLORS.tertiaryTint1};
    z-index: ${Z_SCALE.e0};
  }

  ${MEDIA_QUERIES.xlUp} {
    margin-bottom: 0;
  }
`;

export const AccordionContainer = styled.div`
  padding: 0 ${SPACER.large};
`;
