import { parseQueryString } from 'great-gatsby/utils';

import cookie from 'utils/cookie';
import { getSingletonUuid } from 'utils/uuid';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import type { QueryStringType } from 'types/routing';

const setUuid = () => {
  if (cookie.has(COOKIE_KEYS.curologyUuid)) return;

  let queryStringObj: QueryStringType = {};

  if (typeof window !== 'undefined') {
    queryStringObj = parseQueryString(window.location);
  }

  const uuid = queryStringObj.uuid || getSingletonUuid();
  cookie.set(COOKIE_KEYS.curologyUuid, uuid, { expires: 395 });
};

export default setUuid;
