export function getBlogListPath(slug?: string, page?: number) {
  const pathSegments = ['blog'];
  if (slug) {
    pathSegments.push('tag', slug);
  }
  if (page && page > 1) {
    pathSegments.push(`${page}`);
  }

  return `/${pathSegments.join('/')}/`;
}
