import CONFIG from 'consts/config';
import { PRODUCT_SLUGS } from 'consts/products';

const ECOMMERCE_PATHS = {
  main: '',
  checkout: 'checkout',
  cleanser: PRODUCT_SLUGS.cleanser,
  acneCleanser: PRODUCT_SLUGS.acneCleanser,
  moisturizer: PRODUCT_SLUGS.moisturizer,
  richMoisturizer: PRODUCT_SLUGS.richMoisturizer,
  emergencySpotPatch: PRODUCT_SLUGS.emergencySpotPatches,
  sunscreen: PRODUCT_SLUGS.sunscreen,
  acneBodyWash: PRODUCT_SLUGS.acneBodyWash,
  micellarMakeupRemover: PRODUCT_SLUGS.micellarMakeupRemover,
  lipBalm: PRODUCT_SLUGS.lipBalm,
  starterSet: PRODUCT_SLUGS.starterSet,
  summerEssentialsSet: PRODUCT_SLUGS.summerEssentialsSet,
  acneCareSet: PRODUCT_SLUGS.acneCareSet,
  travelMinisSet: PRODUCT_SLUGS.travelMinisSet,
  barrierBalm: PRODUCT_SLUGS.barrierBalm,
  emergencySpotPatchClusters: PRODUCT_SLUGS.emergencySpotPatchClusters,
  liquidPoreExfoliant: PRODUCT_SLUGS.liquidPoreExfoliant,
  darkSpotSerum: PRODUCT_SLUGS.darkSpotSerum,
  hydrationSerum: PRODUCT_SLUGS.hydrationSerum,
};

type Routes = typeof ECOMMERCE_PATHS;

const wrapUrls = (paths: Routes) => {
  const wrappedUrls = {} as { [key in keyof Routes]: string };

  Object.entries(paths).forEach(([key, val]) => {
    wrappedUrls[key as keyof Routes] = `${CONFIG.ECOMMERCE_URL}/${val}`;
  });

  return wrappedUrls;
};

export default { ...wrapUrls(ECOMMERCE_PATHS) };
