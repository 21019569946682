import React, { useState } from 'react';

import {
  ONE_NAV_TEST_PRODUCT_CATEGORIES,
  PRODUCT_CATEGORIES,
  PRODUCT_LINKS,
} from 'consts/products';

import * as Style from './ProductCategory.style';
import { TaggedLinks } from '.';
import type { TaggedLinkType } from '../../../content';
import { NestableAccordion } from '../..';

interface ProductCategoryPropsType {
  content: {
    category:
      | valueof<typeof PRODUCT_CATEGORIES>
      | valueof<typeof ONE_NAV_TEST_PRODUCT_CATEGORIES>;
    linkType: valueof<typeof PRODUCT_LINKS>;
    links: TaggedLinkType[];
  };
  isOpen: boolean;
  setIsModalOpen?: (value: boolean) => void;
  isMobile?: boolean;
}

export const ProductCategory = ({
  content,
  isOpen,
  setIsModalOpen,
  isMobile = false,
}: ProductCategoryPropsType) => {
  const { category, linkType } = content;
  const { links: productLinks } = content;

  const links: TaggedLinkType[] =
    category === PRODUCT_CATEGORIES.overTheCounter
      ? [
          { label: 'Shop all', href: 'https://shop.curology.com' },
          ...productLinks,
        ]
      : productLinks;
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const handleAccordionClick = () => {
    setIsCategoryOpen(!isCategoryOpen);
  };

  return isMobile ? (
    <Style.NestedAccordionContainer>
      <NestableAccordion
        title={<Style.ProductCategory>{category}</Style.ProductCategory>}
        isOpen={isCategoryOpen}
        onClick={handleAccordionClick}
      >
        <Style.CategoryContainer>
          <TaggedLinks
            links={links}
            isEcommerceNavigation={linkType === PRODUCT_LINKS.eCommerce}
            isOpen={isOpen}
            setIsModalOpen={setIsModalOpen}
            isMobile={isMobile}
          />
        </Style.CategoryContainer>
      </NestableAccordion>
    </Style.NestedAccordionContainer>
  ) : (
    <Style.CategoryContainer>
      <Style.ProductCategory>{category}</Style.ProductCategory>
      <TaggedLinks
        links={links}
        isEcommerceNavigation={linkType === PRODUCT_LINKS.eCommerce}
        isOpen={isOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Style.CategoryContainer>
  );
};
