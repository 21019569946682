import type { ReactNode } from 'react';
import React from 'react';

export const ShippingMessageWrapper = ({
  children,
}: {
  children: ReactNode;
}) => (
  <div
    className="w-full rounded-md bg-radiance-purple-200 p-4 relative"
    style={{ display: 'block' }}
  >
    {children}
  </div>
);

export const MessageLayout = ({ children }: { children: ReactNode }) => (
  <div className="w-full flex justify-center items-center gap-2 flex-col">
    {children}
  </div>
);

export const ShippingIconMessage = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-row items-center gap-2">{children}</div>
);

export const MessageText = ({ children }: { children: ReactNode }) => (
  <p className="text-sm">{children}</p>
);
