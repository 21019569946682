const fontSize = {
  xxsmall: '0.625rem', // 10px
  trialLink: '11px',
  headerBody: '1.125rem', // 18px
  titleEmphasisSm: '1.365rem', // 22px
  titleMd0: '1.625rem', // 26px
  titleMd: '1.75rem', // 28px
  titleMd2: '1.875rem', // 30px
  titleLg: '2.5rem', // 40px
  titleLg2: '2.625rem', // 42px
  titleEmphasisLg: '2.75rem', // 44px
  titleEmphasisLg2: '3rem', // 48px
  titleXlg: '3.125rem', // 50px
};

const fontFamily = {
  eurostileCandySemiBold:
    "eurostile-candy-semi-bold, 'Skintype Text', 'Avant Garde', Avantgarde, 'Century Gothic' ,CenturyGothic, AppleGothic, Verdana, sans-serif;",
};

export default {
  fontSize,
  fontFamily,
};
