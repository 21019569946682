import React, { useCallback, useState } from 'react';

import type { PhysicalItem } from 'utils/eCommerce/cart/types';
import useCart from 'utils/eCommerce/cart/useCart';

import {
  CurologyDotSpinner,
  ItemError,
  ItemQuantitySection,
  Quantity,
  QuantityButton,
} from './QuantityUpdater.style';

export const QuantityUpdater = ({
  item,
  isLoading = false,
  onUpdatingStarted,
  onUpdatingFinished,
}: {
  item: PhysicalItem;
  isLoading?: boolean;
  onUpdatingStarted?: () => void;
  onUpdatingFinished?: () => void;
}) => {
  const { updateItem } = useCart();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );

  const updateQuantity = useCallback(
    (updatedQuantity: number) => {
      onUpdatingStarted?.();

      updateItem({
        itemId: item.id,
        productId: item.productId,
        quantity: updatedQuantity,
      })
        .then(() => {
          onUpdatingFinished?.();
        })
        .catch(() => {
          onUpdatingFinished?.();
          setErrorMessage(
            'We cannot update the item quantity at this time, please try again later.',
          );
        });
    },
    [updateItem, setErrorMessage],
  );

  return (
    <React.Fragment>
      <ItemQuantitySection>
        <QuantityButton
          type="decrement"
          item={item}
          onClick={() => updateQuantity(item.quantity - 1)}
        />
        <Quantity item={item} />
        <QuantityButton
          type="increment"
          item={item}
          onClick={() => updateQuantity(item.quantity + 1)}
        />
        <CurologyDotSpinner loading={isLoading} />
      </ItemQuantitySection>
      {errorMessage && <ItemError errorMessage={errorMessage} />}
    </React.Fragment>
  );
};
