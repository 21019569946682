import React from 'react';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';
import { Service } from '../../content';
import {
  NestedLinksContainer,
  ServiceLinkTitle,
  ServiceLinkSubtitle,
} from './ServiceLink.style';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { TREATMENT_CATEGORIES } from 'consts/treatmentCategories';

export interface ServiceLinkProps {
  service: Service;
  module: string;
}

export const ServiceLink = ({ service, module }: ServiceLinkProps) => {
  const handleClick = () => (event: React.SyntheticEvent) => {
    event.preventDefault();

    trackEvent(EVENTS.linkClicked, {
      path: window.location.pathname,
      module,
      treatmentCategories: TREATMENT_CATEGORIES[service.treatmentCategoryId],
      treatmentCategoryId: service.treatmentCategoryId,
    });

    navExternalWithQueryString(service.href + location.search);
  };

  return (
    <NestedLinksContainer
      onClick={handleClick()}
      data-synthetics-id={service.testID}
    >
      <ServiceLinkTitle>{service.title}</ServiceLinkTitle>
      {service.subtitle && (
        <ServiceLinkSubtitle>{service.subtitle}</ServiceLinkSubtitle>
      )}
    </NestedLinksContainer>
  );
};
