import React from 'react';
import { IconName } from '@curology/ui-components-web/react';
import { StaticImage } from 'gatsby-plugin-image';
import { NavBannerProps } from '..';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

export const NEW_YEAR_PROMO_BANNER_CONTENT: NavBannerProps = {
  iconName: IconName.Gift,
  floatingOnDesktop: true,
  badge: (
    <StaticImage
      css={desktopOnly('block', 'xlUp')}
      src="../images/holiday-promo/newYearPromoBadge@2x.png"
      alt="A purple badge with five animated Curology products that reads 'Celebrate labor day. Free gift!'"
      width={160}
      height={160}
    />
  ),
  text: (
    <span>
      <strong>Limited time offer for new customers!</strong>
    </span>
  ),
};
