import neutralImageSrc from 'images/seo/neutral-og-image.jpg';

import { SEO_CORE_CONTENT, type SEOPropsType } from '..';

export const SEO_NEUTRAL_CONTENT: SEOPropsType = {
  ...SEO_CORE_CONTENT,
  ogImage: neutralImageSrc,
};

export const SEO_NEUTRAL_MARKETING_SAFE_CONTENT: SEOPropsType = {
  title: 'Curology Custom Skin Care',
  ogImage: neutralImageSrc,
};
