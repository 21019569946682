import { EVENTS } from 'consts/metricsTrackingEvents';
import type { ModuleType } from 'consts/modules';

import type { FlagValue } from '../consts/featureFlagsAndMetrics';
import { isAmplitudeInitialized } from './analytics/amplitude';
import { trackCustomFeatureFlagEvent } from './featureFlagsAndMetrics';
import { isDataDogSynthTestBot } from './isDDBot';

export const trackEvent = (event: string, properties = {}) => {
  if (!window || !window.dataLayer || isDataDogSynthTestBot()) {
    return;
  }

  const sendEvents = () => {
    // Reset the dataLayer so properties are not recursively merged with
    // the properties of any events that were pushed previously.
    // See https://github.com/google/data-layer-helper
    window.dataLayer.push({ event: 'Reset DataLayer', properties: null });
    window.dataLayer.push({ event, properties });
    trackCustomFeatureFlagEvent(event, properties);
  };

  const sendEventsWithEventListener = () => {
    sendEvents();
    window.removeEventListener('amplitude_init', sendEventsWithEventListener);
  };

  if (isAmplitudeInitialized()) {
    sendEvents();
  } else {
    window.addEventListener('amplitude_init', sendEventsWithEventListener);
  }
};

interface IdentifyUserOptions {
  patient: Record<string, unknown>;
  traits: Record<string, unknown>;
  callback: () => void;
}

export const identifyUser = ({
  patient,
  traits = {},
  callback,
}: IdentifyUserOptions) => {
  if (!window || !window.dataLayer) {
    return;
  }

  window.dataLayer.push({ event: 'Identify', patient, traits });

  if (typeof callback === 'function') {
    setTimeout(callback, 300);
  }
};

const ctaDefaults = (type: 'signup' | 'eCommerce', module: ModuleType) => ({
  type,
  path: window.location.pathname,
  url: window.location.href,
  module,
  brand: 'curology',
});

export const trackSignupCTAClick = (module: ModuleType, properties = {}) => {
  trackEvent(EVENTS.ctaClicked, {
    ...ctaDefaults('signup', module),
    ...properties,
  });
};

export const trackECommerceCTAClick = (module: ModuleType, properties = {}) => {
  trackEvent(EVENTS.ctaClicked, {
    ...ctaDefaults('eCommerce', module),
    ...properties,
  });
};

export const trackFeatureFlagValue = (
  flagName: string,
  flagValue: FlagValue,
) => {
  trackEvent(EVENTS.launchDarklyFlagAssigned, {
    flagName,
    flagValue,
  });
};
