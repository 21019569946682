import type { IGatsbyImageData, Layout } from 'gatsby-plugin-image';
import type { ImageAsset } from '../../schema';

const SCALE_FACTORS = [4, 2, 1] as const;

const buildContentfulUrl = (
  image: ImageAsset,
  scaleFactor = 1,
  queryParams: Record<string, string> = {},
) => {
  const url = new URL(image.url);
  const width = Math.round(image.width / scaleFactor);
  const height = Math.round(image.height / scaleFactor);
  url.search = new URLSearchParams({
    w: width.toString(),
    h: height.toString(),
    q: '80',
    ...queryParams,
  }).toString();

  return [url.toString(), width] as const;
};

const buildSrcSet = (
  image: ImageAsset,
  queryParams: Record<string, string> = {},
) =>
  SCALE_FACTORS.map((factor) => {
    const [url, width] = buildContentfulUrl(image, factor, queryParams);
    return `${url} ${width}w`;
  }).join(',\n');

export const imageAssetToGatsbyImageData = (
  image: ImageAsset,
  layout?: Layout,
): IGatsbyImageData => {
  const sizes = `(min-width: ${image.width}px) ${image.width}px, 100vw`;
  const fallbackQueryParams = {
    ...(image.mimeType === 'image/jpeg' && { fl: 'progressive' }),
    fm: image.mimeType.replace('image/', ''),
  };

  return {
    layout: layout ?? image.layout ?? 'constrained',
    width: image.width,
    height: image.height,
    images: {
      fallback: {
        src: buildContentfulUrl(image, 1, fallbackQueryParams)[0],
        srcSet: buildSrcSet(image, fallbackQueryParams),
        sizes,
      },
      sources: [
        {
          srcSet: buildSrcSet(image, { fm: 'webp' }),
          type: 'image/webp',
          sizes,
        },
      ],
    },
  };
};
