import { css } from '@emotion/react';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER, Z_SCALE } from 'radiance-ui/lib/constants';

export const banner = (theme: ThemeType) => css`
  padding: 0.75rem ${SPACER.medium};
  width: 100%;
  background-color: ${theme.COLORS.primary};
  ${TYPOGRAPHY_STYLE.button(theme)};
  color: ${theme.COLORS.white};
  text-align: center;
  position: relative;
  z-index: ${Z_SCALE.modal + 1};

  a {
    color: ${theme.COLORS.white};
  }
`;

export const closeButton = () => css`
  position: absolute;
  right: 0.75rem;
  top: 0;
  bottom: 0;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.4s ease 0.2s;
  transform-origin: center;
  &:hover {
    transform: rotate(90deg);
  }
`;

export const ctaLink = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.button(theme)};
  cursor: pointer;
  color: ${theme.COLORS.white};
  background: none;
  border: none;
  outline: none;
  padding: 0;
  text-decoration: underline;
`;
