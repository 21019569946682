import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const OneNavLinksContainer = styled.div`
  width: auto;
  padding: ${SPACER.xlarge} ${SPACER.large};
  margin-left: auto;

  [class*='NestedLinksContainer'] {
    max-width: 100%;
  }

  [class*='CategoryContainer'] {
    min-width: 9.5rem;
    ${MEDIA_QUERIES.xxlUp} {
      min-width: 11.25rem;
    }
  }
`;

export const OneNavImageContainer = styled.div`
  margin: ${SPACER.medium} auto ${SPACER.x2large} 0;
  ${MEDIA_QUERIES.xxlUp} {
    min-width: 300px;
  }
`;
