import { useEffect, useRef } from 'react';

const useHoverOutside = (eventHandler: () => void) => {
  const domNode: React.MutableRefObject<HTMLElement | null> = useRef(null);

  useEffect(() => {
    const handleMouseover = (event: MouseEvent) => {
      if (!domNode?.current?.contains(event.target as HTMLElement)) {
        eventHandler();
      }
    };
    document.addEventListener('mouseover', handleMouseover);
    return () => document.removeEventListener('mouseover', handleMouseover);
  });

  return domNode;
};

export default useHoverOutside;
