import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { ANIMATION, MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding-top: ${SPACER.medium};

  ${MEDIA_QUERIES.xlUp} {
    flex-direction: row;
    padding-top: unset;
    margin-left: unset;
  }
`;

export const ColumnContainer = styled.div`
  flex: 1;
`;

export const InternalLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }

  &:hover,
  &:focus,
  &:visited {
    opacity: 1;
  }

  ${MEDIA_QUERIES.lgUp} {
    padding: 0;
    margin-bottom: ${SPACER.medium};
  }
`;

export const linkStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  text-decoration: underline transparent;
  transition: text-decoration ${ANIMATION.defaultTiming};
  font-size: ${theme.TYPOGRAPHY.fontSize.body};
  padding: ${SPACER.small} 0;
  width: 100%;
  border-bottom: 0;
  display: block;

  ${MEDIA_QUERIES.xlUp} {
    padding: 0;
    margin-bottom: ${SPACER.medium};
    font-weight: ${theme.TYPOGRAPHY.fontWeight.bold};
  }
`;

export const LinkHeader = styled.strong`
  color: ${LOCAL_COLORS.surfaceBrandBolder};
  display: block;
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.body};
  line-height: 1.5;
  padding: ${SPACER.small} 0;

  ${MEDIA_QUERIES.xlUp} {
    padding: 0 0 ${SPACER.medium} 0;
  }
`;

export const NestedAccordionContainer = styled.div`
  ${MEDIA_QUERIES.xlUp} {
    margin-left: 0.5rem;
  }
`;
