import { useCallback, useEffect, useState } from 'react';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';

import config from 'consts/config';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import { IS_BUILD_OR_TEST, IS_PRODUCTION } from 'consts/environmental';
import type {
  FlagValue,
  LaunchDarklyFlagNameType,
} from 'consts/featureFlagsAndMetrics';
import { LD_FLAGS } from 'consts/featureFlagsAndMetrics';
import cookie from 'utils/cookie';
import { getQueryParameterValue } from 'utils/queryParameters';
import { getSingletonUuid } from 'utils/uuid';

import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from '../consts/metricsTrackingEvents';

// Notion doc: https://www.notion.so/curology/How-To-Use-LaunchDarkly-Feature-Flags-951fa6eb639d4ceca0a3cfa346d1179c
let ldClient: LaunchDarkly.LDClient;
export let ldClientReady = false;

let ldClientPending = false;
const onReadyCallbacks: { (): void }[] = [];

const getLdUuidAndSetLdUuidCookie = () => {
  const ldUuidCookieSet = cookie.has(COOKIE_KEYS.ldUuid);
  let ldUuid;

  if (ldUuidCookieSet) {
    ldUuid = cookie.get(COOKIE_KEYS.ldUuid);
  } else if (cookie.has(COOKIE_KEYS.curologyUuid)) {
    ldUuid = cookie.get(COOKIE_KEYS.curologyUuid);
  } else {
    ldUuid = getSingletonUuid();
  }

  if (!ldUuidCookieSet && ldUuid) {
    cookie.set(COOKIE_KEYS.ldUuid, ldUuid, { expires: 395 });
  }

  return ldUuid;
};

export const initLd = (extraContext: Record<string, unknown> = {}) => {
  const clientSideId = config.LD_CLIENT_SIDE_ID;

  if (clientSideId) {
    const ldUuid = getLdUuidAndSetLdUuidCookie();

    const context = {
      kind: 'user',
      key: ldUuid,
      brand: 'Curology',
      deviceData: (!!window.navigator && window.navigator.userAgent) || '',
      amplitudeDeviceId: cookie.get(COOKIE_KEYS.amplitudeDeviceId),
      ...extraContext,
    };

    if (!ldClientReady && !ldClientPending) {
      ldClientPending = true;
      ldClient = LaunchDarkly.initialize(clientSideId, context, {
        sendEventsOnlyForVariation: true,
      });

      ldClient.on('ready', () => {
        ldClientPending = false;
        ldClientReady = true;

        onReadyCallbacks.forEach((onReadyCallback) => {
          onReadyCallback();
        });
      });
    } else {
      if (ldClientReady) {
        ldClient.identify(context);
      } else {
        onReadyCallbacks.unshift(() => {
          ldClient.identify(context);
        });
      }
    }
  }
};

/**
 * There are two possible ways to override a LaunchDarkly flag value in a development environment.
 *
 *  1. Add a query parameter to your request with the flag name and override value. Example: for
 *      a flag named login, add `?login=true`
 *  2. Set the `value` of the LaunchDarkly flag to anything other than undefined in `consts/featureFlagsAndMetrics`
 *
 *  Note that a query param will override a value specified in `consts/featureFlagsAndMetrics`
 *
 *  Will always return undefined in production, during tests, and during static builds
 *
 * @param flagName
 */
const getFlagOverride = (flagName: LaunchDarklyFlagNameType): FlagValue => {
  if (IS_PRODUCTION || IS_BUILD_OR_TEST) {
    return undefined;
  }

  return getQueryParameterValue(flagName) ?? LD_FLAGS[flagName];
};

export const useFlag = <T>(flagName: LaunchDarklyFlagNameType) => {
  const [flagValue, setFlagValue] = useState<T>();

  const evaluate = useCallback(() => {
    const override = getFlagOverride(flagName) as unknown as T | undefined;

    if (override !== undefined) {
      setFlagValue(override);
      return;
    }

    const variation = ldClient.variationDetail(flagName);

    if (variation.reason?.kind === 'ERROR') {
      // eslint-disable-next-line no-console
      console.warn(
        `Error evaluating flag ${flagName}: ${variation.reason.errorKind}`,
      );
    }

    trackEvent(EVENTS.flagEvaluated, {
      flag: flagName,
      variation: variation.value,
      reason: variation.reason,
    });

    setFlagValue(variation.value as T);
  }, [flagName]);

  useEffect(() => {
    if (ldClientReady) {
      evaluate();
    } else {
      onReadyCallbacks.push(evaluate);
    }
  }, [evaluate, flagName]);

  return flagValue;
};

export const trackCustomFeatureFlagEvent = (
  eventName: string,
  data?: object,
) => {
  if (ldClientReady) {
    ldClient.track(eventName, data);
  } else {
    onReadyCallbacks.push(() => {
      ldClient.track(eventName, data);
    });
  }
};
