import styled from '@emotion/styled';
import { Z_SCALE } from 'radiance-ui/lib/constants';

export const ModalContainer = styled.div<{
  isOpen: boolean;
  distanceFromTop: string;
  transitionDuration: string;
  isFullScreen: boolean;
}>`
  background: ${({ theme }) => theme.COLORS.white};
  justify-content: space-between;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: ${({ distanceFromTop }) => distanceFromTop};
  bottom: ${({ isFullScreen }) => (isFullScreen ? '0' : 'auto')};
  transform: translateY(-100%);
  transition: ${({ transitionDuration }) =>
    `opacity ${transitionDuration} ease-in-out, transform ${transitionDuration} ease-in-out`};
  z-index: ${Z_SCALE.modal};

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    transform: translateY(0);
  `};
`;
