import React from 'react';

import { CartActions } from './components';
import { MobileCartWrapper } from './styles/styles';

export const MobileShoppingCart = ({ onOpen }: { onOpen?: () => void }) => (
  <MobileCartWrapper>
    <CartActions onOpen={onOpen} />
  </MobileCartWrapper>
);
