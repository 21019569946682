import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

import { NESTED_ACCORDION_DATA } from './constants';

const { icons, iconOffsets } = NESTED_ACCORDION_DATA;

export const AccordionContainer = styled.div<{
  contentHeight: number;
}>`
  /* allow dynamic heights in nested radiance accordions */
  & div[class*='ExpansionWrapper'] {
    max-height: ${({ contentHeight }) => contentHeight}px;
  }

  & div[class*='ExpansionWrapper'][aria-hidden='true'] {
    max-height: 0;
  }

  & [class*='ArrowWrapper'] {
    background-image: url('${icons.arrow}');
    background-repeat: no-repeat;
    width: 28px;
    transform: rotate(180deg);

    & > svg {
      transition: none;
      display: none;
    }
  }

  & div[aria-expanded='true'] [class*='ArrowWrapper'] {
    transform: none;
    margin-top: 3px;
    margin-left: ${SPACER.x2large};
  }

  & div[class*='TitleWrapper'] {
    padding: 0;
  }
`;

interface Openable {
  isOpen: boolean;
}

const chooseAccordionIcon = ({ isOpen }: Openable) =>
  isOpen ? `url("${icons.opened}")` : `url("${icons.closed}")`;

const setAccordionIconOffset = ({ isOpen }: Openable) =>
  isOpen ? iconOffsets.open.top : iconOffsets.closed.top;
const setAccordionIconLeftOffset = ({ isOpen }: Openable) =>
  isOpen ? iconOffsets.open.left : iconOffsets.closed.left;

export const NestedAccordionContainer = styled(AccordionContainer)<Openable>`
  // replace the open/close arrow icons
  & [class*='ArrowWrapper'] {
    position: relative;
    background: none !important;
    width: fit-content !important;
    padding-left: ${SPACER.small};

    & > svg {
      display: none;
    }

    &::after {
      content: ${chooseAccordionIcon};
      position: relative;
      top: ${setAccordionIconOffset};
      left: ${setAccordionIconLeftOffset};
    }
  }

  & div[class*='TitleWrapper'] {
    padding-top: ${SPACER.small} !important;
  }
`;
