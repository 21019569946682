/* eslint-disable sonarjs/cognitive-complexity */
import { useTheme } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import throttle from 'lodash.throttle';
import React, { useCallback, useEffect, useState } from 'react';
import UrlActivatedPromotionsBanner from '../urlActivatedPromotionsBanner';
import APP_URLS from 'consts/appUrls';
import { CTA_FIND_YOUR_TREATMENT } from 'consts/copy';
import { BRAND, EVENTS } from 'consts/metricsTrackingEvents';
import { PAGE_URLS } from 'consts/pageUrls';
import { SYNTHETIC_TEST_IDS } from 'consts/syntheticTestIds';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { useFlag } from 'utils/featureFlagsAndMetrics';
import useHoverOutside from 'utils/hooks/useHoverOutside';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';
import { trackEvent } from 'utils/metricsTracking';
import { getFormattedLink } from 'utils/getFormattedLink';

import {
  FreeTrialText,
  FreeTrialWrapper,
  HeaderContainer,
  LogoContainer,
  Spacer,
  WordmarkCurology,
  buttonStyles,
  headerStyles,
  iconStyles,
  logoStyles,
  mobileNavElement,
  navStyles,
  skipToContentLink,
  watermarkStyles,
} from './Nav.style';

import {
  FreeSunscreenBanner,
  AnimatedMenuIcon,
  MobileShoppingCart,
  ModalMobile,
  NavBanner,
  NavBannerProps,
  NavHowItWorksBanner,
  NavLink,
  NavLinksDesktop,
  ShoppingCart,
} from './components';
import { useHairRxLandingPageRoute } from 'utils/hooks/useHairPromoVariation';
import { MODULES } from 'consts/modules';
import { isOneNavTest } from './components/OneNavTest/OneNavProductsModalDesktop';

interface NavPropsType {
  isTransparent?: boolean;
  navType?: 'primary' | 'secondary';
  freeTrial?: boolean;
  isMarketingSafe?: boolean;
  bannerProps?: NavBannerProps[];
  hasHowItWorksBanner?: boolean;
  minimalNavigation?: boolean;
  ctaTextOverride?: string;
  quizBanner?: React.ReactNode;
  isHidingProductCtas?: boolean;
  ctaLinkOverride?: string;
  holidayPromoBanner?: React.ReactNode;
}

export const Nav = ({
  isTransparent = false,
  navType = 'primary',
  freeTrial = false,
  isMarketingSafe = false,
  bannerProps,
  hasHowItWorksBanner = false,
  minimalNavigation = false,
  ctaTextOverride,
  quizBanner,
  isHidingProductCtas = false,
  ctaLinkOverride,
  holidayPromoBanner,
}: NavPropsType) => {
  const [isModalMobileOpen, setIsModalMobileOpen] = useState(false);
  const [isModalDesktopOpen, setIsModalDesktopOpen] = useState(false);
  const [isServiceStepOpen, setIsServiceStepOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [showTransparent, setShowTransparent] = useState(isTransparent);
  const theme = useTheme();
  const isHairRxLandingPage = useHairRxLandingPageRoute();

  const oneNavVariation: string | undefined = useFlag('one-nav-test');

  const aboveNavBanners = bannerProps?.filter(
    ({ aboveNavigation }) => aboveNavigation,
  );
  const belowNavBanners = bannerProps?.filter(
    ({ aboveNavigation }) => !aboveNavigation,
  );

  const breakpointSize = 'xlUp';
  const renderFreeTrialCta = freeTrial || isMarketingSafe || minimalNavigation;

  // TODO [GE-3580]: Temporarily disables the "Find your treatment" modal in favor of just being directed to the skin SUF on click. Keeping the copy "Find your treatment" for the CTA though.
  const showFindYourTreatment = false; // !renderFreeTrialCta;

  const ctaText = ctaTextOverride || CTA_FIND_YOUR_TREATMENT;
  // const ctaText =
  //   ctaTextOverride ||
  //   (showFindYourTreatment || isHairRxLandingPage
  //     ? CTA_FIND_YOUR_TREATMENT
  //     : CTA_UNLOCK_YOUR_OFFER);

  let ctaLink = APP_URLS.welcome;

  if (ctaLinkOverride) {
    ctaLink = getFormattedLink(ctaLinkOverride);
  } else if (showFindYourTreatment || isHairRxLandingPage) {
    ctaLink = '';
  }

  const handleScroll = useCallback(
    throttle(() => {
      const currentScrollDistance = window.scrollY;

      if (currentScrollDistance > 0 && !hasScrolled) {
        setHasScrolled(true);
        setShowTransparent(false);
      }

      if (currentScrollDistance === 0 && hasScrolled) {
        setHasScrolled(false);
        setShowTransparent(!isModalMobileOpen && isTransparent);
      }
    }, 100),
    [hasScrolled],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    setBannerHeight(bannerElement.clientHeight);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const openModal = (isServiceStepOpen = false) => {
    trackEvent(EVENTS.moduleInteraction, {
      path: window.location.pathname,
      module: MODULES.navigation,
      interaction: 'open nav modal',
    });

    if (isServiceStepOpen) setIsServiceStepOpen(true);

    setIsModalMobileOpen(true);
    setShowTransparent(false);
  };

  const closeModalMobile = () => {
    setIsModalMobileOpen(false);
    setIsServiceStepOpen(false);
    setTimeout(() => setShowTransparent(!hasScrolled && isTransparent), 200);
  };

  const toggleModalMobile = () => {
    if (isModalMobileOpen) {
      closeModalMobile();
    } else {
      openModal();
    }
  };

  const closeMenuModals = () => {
    setIsModalDesktopOpen(false);
    closeModalMobile();
  };

  const openSubnav = () => {
    trackEvent(EVENTS.ctaClicked, {
      path: window.location.pathname,
      url: window.location.href,
      module: MODULES.navigation,
      brand: BRAND,
    });

    if (showFindYourTreatment || isHairRxLandingPage) {
      openModal(true);
    } else {
      navExternalWithQueryString(ctaLink);
    }
  };

  const trackCTA = () => {
    trackEvent(EVENTS.ctaClicked, {
      path: window.location.pathname,
      module: MODULES.navigation,
      url: window.location.href,
      brand: BRAND,
    });

    navExternalWithQueryString(ctaLink);
  };

  const headerMouseOverHandler = () => {
    if (isModalMobileOpen) return;
    setShowTransparent(false);
  };
  const headerFocusHandler = () => {
    if (isModalMobileOpen) return;
    setShowTransparent(false);
  };
  const headerBlurHandler = () => {
    if (isModalMobileOpen) return;
    setShowTransparent(false);
  };
  const headerMouseOutHandler = () => {
    if (isModalMobileOpen) return;
    setShowTransparent(!hasScrolled && !isModalDesktopOpen && isTransparent);
  };

  const navRef = useHoverOutside(() => {
    setShowTransparent(!hasScrolled && isTransparent);
    setIsModalDesktopOpen(false);
  });

  let bannerElement: HTMLDivElement;
  const navTypeToRender =
    !showTransparent || isModalDesktopOpen ? 'primary' : navType;
  const fillColor =
    navTypeToRender === 'primary' ? theme.COLORS.primary : theme.COLORS.white;

  const showSunscreenGiveawayBanner =
    useFlag('curology-sunscreen-giveaway') && !isTransparent;

  return (
    <HeaderContainer>
      <header
        css={headerStyles(showTransparent, hasScrolled)}
        ref={navRef}
        onMouseOver={headerMouseOverHandler}
        onFocus={headerFocusHandler}
        onMouseOut={headerMouseOutHandler}
        onBlur={headerBlurHandler}
      >
        <a
          href="#main"
          aria-label="Skip to main  content"
          role="button"
          css={skipToContentLink}
        >
          Skip to main content
        </a>
        <div
          ref={(divElement: HTMLDivElement) => {
            bannerElement = divElement;
          }}
        >
          <>
            {showSunscreenGiveawayBanner && <FreeSunscreenBanner />}
            <UrlActivatedPromotionsBanner />
            {hasHowItWorksBanner && (
              <div css={desktopOnly('block', breakpointSize)}>
                <NavHowItWorksBanner />
              </div>
            )}
            {aboveNavBanners?.map((props, index) => (
              <NavBanner key={index} {...props} />
            ))}
          </>
        </div>
        <nav css={navStyles(isTransparent, isOneNavTest(oneNavVariation))}>
          <LogoContainer>
            <NavLink aria-label="Back to Homepage" href={PAGE_URLS.homepage}>
              <StaticImage
                src="./images/curologySplashLogo@2x.png"
                alt="Curology splash logo"
                width={34}
                loading="eager"
                css={logoStyles}
              />
              <WordmarkCurology fill={fillColor} css={watermarkStyles} />
            </NavLink>
          </LogoContainer>
          <NavLinksDesktop
            isHidingProductCtas={isHidingProductCtas}
            bannerHeight={bannerHeight}
            navType={navTypeToRender}
            isMarketingSafe={isMarketingSafe}
            setIsModalOpen={setIsModalDesktopOpen}
            isModalOpen={isModalDesktopOpen}
            minimalNavigation={minimalNavigation}
            ctaTextOverride={ctaTextOverride}
            ctaLinkOverride={ctaLinkOverride}
            oneNavVariation={oneNavVariation}
          />
          {renderFreeTrialCta ? (
            <FreeTrialWrapper
              onClick={trackCTA}
              data-synthetics-id={SYNTHETIC_TEST_IDS.headerCta}
            >
              <FreeTrialText>{ctaText}</FreeTrialText>
            </FreeTrialWrapper>
          ) : (
            <React.Fragment>
              <Spacer css={mobileNavElement(showTransparent)} />
              <NavLink
                isExternal
                href={ctaLink}
                data-synthetics-id={SYNTHETIC_TEST_IDS.headerCta}
                css={buttonStyles}
                trackClick={openSubnav}
              >
                {ctaText}
              </NavLink>
              {!isHidingProductCtas && (
                <MobileShoppingCart onOpen={closeMenuModals} />
              )}

              <NavLink
                onClick={toggleModalMobile}
                aria-label="Open navigation"
                css={mobileNavElement(showTransparent)}
              >
                <AnimatedMenuIcon
                  isModalOpen={isModalMobileOpen}
                  css={iconStyles}
                />
              </NavLink>
            </React.Fragment>
          )}
          <div css={mobileOnly('block', breakpointSize)}>
            <ModalMobile
              isModalOpen={isModalMobileOpen}
              setIsModalOpen={setIsModalMobileOpen}
              bannerHeight={bannerHeight}
              ctaTextOverride={ctaTextOverride}
              isHidingProductCtas={isHidingProductCtas}
              isServiceStepOpen={isServiceStepOpen}
              isOneNavTest={isOneNavTest(oneNavVariation)}
            />
          </div>
          {!isHidingProductCtas && <ShoppingCart onOpen={closeMenuModals} />}
        </nav>
        {hasHowItWorksBanner && (
          <div css={mobileOnly('block', breakpointSize)}>
            <NavHowItWorksBanner />
          </div>
        )}
        {holidayPromoBanner}
        {belowNavBanners?.map((props, index) => (
          <NavBanner key={index} {...props} />
        ))}
      </header>
      {quizBanner}
    </HeaderContainer>
  );
};
