import type { ReactNode } from 'react';
import React from 'react';

import useCart from 'utils/eCommerce/cart/useCart';

import { LineItem } from './components';

const LineItemsWrapper = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col gap-4 mt-4 flex-1 overflow-y-auto">
    {children}
  </div>
);

export const LineItems = () => {
  const {
    cart: { itemCount, items },
  } = useCart();

  if (itemCount === 0) {
    return (
      <LineItemsWrapper>
        <p className="mx-auto mt-4 font-bold">Your cart is empty</p>
      </LineItemsWrapper>
    );
  }

  return (
    <LineItemsWrapper>
      {items.map((item) => (
        <LineItem key={item.id} item={item} />
      ))}
    </LineItemsWrapper>
  );
};
