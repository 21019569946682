import { parseQueryString } from '@curology-engineering/great-gatsby/utils';
import { css } from '@emotion/react';
import { useLocation } from '@gatsbyjs/reach-router';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { SPACER } from 'radiance-ui/lib/constants';
import React from 'react';

import { Banner } from 'components/global/Banner';

export const freeSunscreenBannerStyles = (theme: ThemeType) => css`
  font-size: 0.875rem;
  background: #ff824f;
  padding: ${SPACER.small} ${SPACER.xlarge};
  text-transform: initial;
  letter-spacing: initial;
  font-weight: bold;
  color: ${theme.COLORS.primary};
`;

const HIDE_BANNER_PATHS = [
  /\/free-trial\/clindamycin/,
  /\/free-trial\/hydrocortisone/,
  /\/free-trial\/hydroquinone/,
  /\/free-trial\/metronidazole/,
  /\/free-trial\/niacinamide/,
  /\/free-trial\/rosacea/,
  /\/free-trial\/tretinoin/,
  /\/free-trial\/1i5n/, // teen code
  /\/free-trial\/full-service-skincare/,
  /\/disclaimer/,
  /\/guardian-telehealth-consent/,
  /\/privacy/,
  /\/telehealth-consent/,
  /\/terms/,
  /\/ccpa-employee-notice/,
];

export const FreeSunscreenBanner = () => {
  const location = useLocation();

  const hasNavBanner = HIDE_BANNER_PATHS.some((pattern) =>
    location.pathname.match(pattern),
  );

  const queryPairs = parseQueryString(location);
  const hasVanityBanner = !!queryPairs.t;

  if (hasNavBanner || hasVanityBanner) {
    return null;
  }

  return (
    <React.Fragment>
      <Banner
        css={freeSunscreenBannerStyles}
        text="Say hello to summer ☀️ Get a FREE sunscreen in your first box! While supplies last."
      />
    </React.Fragment>
  );
};
