import styled from '@emotion/styled';
import { Typography } from 'radiance-ui';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const Wrapper = styled.div`
  height: 100vh;
  margin-top: calc(var(--nav-height-mobile) * -1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.COLORS.background};
  color: ${({ theme }) => theme.COLORS.primary};

  ${MEDIA_QUERIES.smUp} {
    margin-top: calc(var(--nav-height-desktop) * -1);
  }
`;

export const Title = styled(Typography.Title)`
  margin-top: ${SPACER.xlarge};
`;

export const BodyText = styled.p`
  margin-top: ${SPACER.small};
`;
