import type { SEOPropsType } from '..';

const title =
  'Treat Acne, Fine Lines & More with Curology Custom Formula | One Bottle to Simplify Your Routine';
const description =
  'Get a Custom Formula made for you by a dermatology provider, tailored to your needs—from clearing blemeshes to fading fine lines and dark spots.';

export const SEO_CUSTOM_FORMULA_CONTENT: SEOPropsType = {
  title,
  description,
  ogTitle: title,
  ogDescription: description,
};
