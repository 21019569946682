import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { ANIMATION, SPACER } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import type { TransitionStatus } from 'react-transition-group/Transition';

import { LOCAL_COLORS } from 'styles/constants';

import { NavLink } from './NavLink';

export const ModalContainer = styled.div<{
  transitionState: TransitionStatus;
}>`
  padding: 0 0 ${SPACER.large} 0;
  background-color: ${({ theme }) => theme.COLORS.white};
  display: ${({ transitionState }) => transitionState === 'exited' && 'none'};
  position: absolute;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  ${({ transitionState }) =>
    transitionState !== 'exited' &&
    `display: flex;
    flex-direction: column;`}
`;

export const PageLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${SPACER.large};
`;

export const AppLinksContainer = styled.div`
  padding: ${SPACER.medium} ${SPACER.large} 0 ${SPACER.large};
  display: flex;
  justify-content: center;
  margin-right: -${SPACER.medium};

  button {
    padding: ${SPACER.small} 0px;
    height: auto;
  }
`;

export const internalLinkStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  text-decoration: underline transparent;
  transition: text-decoration ${ANIMATION.defaultTiming};
  font-weight: ${theme.TYPOGRAPHY.fontWeight.normal};
  font-size: ${theme.TYPOGRAPHY.fontSize.body};
  width: 100%;
  display: block;
  padding: ${SPACER.small} 0;

  &:hover {
    text-decoration: underline;
  }
`;

const primaryButtonStyles = (theme: ThemeType) => `
  border-color: ${theme.COLORS.primary};
  color: ${theme.COLORS.white};
  background-color: ${theme.COLORS.primary};
`;

export const StyledSignupLink = styled(NavLink, {
  shouldForwardProp: (prop) =>
    isPropValid(prop.toString()) ||
    prop === 'isExternal' ||
    prop === 'trackClick',
})`
  ${({ theme }) => TYPOGRAPHY_STYLE.button(theme)};
  color: ${({ theme }) => theme.COLORS.primary};
  text-decoration: none;
  padding: 14px;
  transition: 350ms;
  margin-right: ${SPACER.medium};
  min-width: 125px;
  max-width: 325px;
  text-align: center;
  border-radius: ${SPACER.xsmall};
  flex-grow: 1;

  ${({ theme }) =>
    `${primaryButtonStyles(theme)};
    border-radius: ${theme.BORDER_RADIUS.large};
    font-size: ${theme.TYPOGRAPHY.fontSize.body};
    background-color: ${LOCAL_COLORS.surfaceBrandBold};
    opacity: 1;
    transition: opacity ${ANIMATION.defaultTiming};
    text-transform: inherit;
    &:hover {
      opacity: 0.8;
    }
  `}
`;

interface PrimaryLinkContainerProps {
  hasAccordion?: boolean;
  isAccordionOpen?: boolean;
}

export const PrimaryLinkContainer = styled.div<PrimaryLinkContainerProps>`
  padding-bottom: ${({ hasAccordion, isAccordionOpen }) => {
    if (!hasAccordion) return 'inherit';
    return isAccordionOpen ? '.5rem' : '1rem';
  }};
  padding-top: ${({ hasAccordion }) => (hasAccordion ? '1rem' : 'inherit')};
  border-top: 1px solid #000;

  &:last-of-type {
    border-bottom: 0;
  }

  [role='button'] {
    padding: ${SPACER.small} 0;
  }
`;
