import React from 'react';
import { Link } from 'gatsby';
import { Chip } from 'radiance-ui';
import { v4 as uuid } from 'uuid';

import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';

import * as Style from './TaggedLinks.style';
import type { TaggedLinkType } from '../../../content';

export interface TaggedLinksPropsType {
  links: TaggedLinkType[];
  isEcommerceNavigation: boolean;
  isOpen: boolean;
  setIsModalOpen?: (value: boolean) => void;
  isMobile?: boolean;
}

export const TaggedLinks = ({
  links,
  isEcommerceNavigation,
  isOpen,
  setIsModalOpen,
  isMobile = false,
}: TaggedLinksPropsType) => {
  const handleEcommerceClick =
    (product: string, href: string) => (event: React.SyntheticEvent) => {
      event.preventDefault();
      trackEvent(EVENTS.linkClicked, {
        path: window.location.pathname,
        type: 'PDP',
        name: product,
      });
      setIsModalOpen?.(false);
      navExternalWithQueryString(href);
    };

  const linkStyles = isMobile
    ? Style.mobileLinkStyles
    : Style.desktopLinkStyles;

  return (
    <React.Fragment>
      {links.map(({ label, htmlLabel, href, tags }) => (
        <Style.NestedLinkContainer key={uuid()}>
          {isEcommerceNavigation ? (
            <a
              href={href}
              tabIndex={isOpen ? 0 : -1}
              css={linkStyles}
              onClick={handleEcommerceClick(label, href)}
            >
              {htmlLabel ? htmlLabel : label}
            </a>
          ) : (
            <Link
              to={href}
              tabIndex={isOpen ? 0 : -1}
              css={linkStyles}
              onClick={() => setIsModalOpen?.(false)}
            >
              {htmlLabel ? htmlLabel : label}
            </Link>
          )}
          {tags && (
            <Style.ChipContainer>
              {tags.map((tag, index) => {
                return <Chip text={tag} key={index} status={'info'} />;
              })}
            </Style.ChipContainer>
          )}
        </Style.NestedLinkContainer>
      ))}
    </React.Fragment>
  );
};
