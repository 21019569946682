import React from 'react';
import { IconName } from '@curology/ui-components-web/react';
import { NavBannerProps } from '..';
import { LOCAL_COLORS } from 'styles/constants';

export const POP_REDESIGN_BANNER_CONTENT: NavBannerProps = {
  backgroundColor: LOCAL_COLORS.surfaceOrange,
  textColor: LOCAL_COLORS.surfaceDarkBold,
  iconName: IconName.Gift,
  text: <React.Fragment>First skincare box JUST $15</React.Fragment>,
};
