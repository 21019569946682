import { Accordion } from 'radiance-ui';
import React, { useState } from 'react';

import { CtaButton } from 'components/global/CtaButton';
import {
  CTA_UNLOCK_YOUR_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import { mobileOnly } from 'styles/mixins/displayMobileDesktop';

import {
  OuterContainer,
  InnerContainer,
  textStyles,
  titleStyles,
  listStyles,
  listItemStyles,
  DesktopContainer,
  AccordionContainer,
  ctaButtonStyles,
} from './NavHowItWorksBanner.style';

export const NavHowItWorksBanner = () => {
  const [isExpandedOnMobile, setIsExpandedOnMobile] = useState(false);

  const renderTitle = () => (
    <p className="smallP" css={titleStyles}>
      <strong>How it works:</strong>
    </p>
  );

  const renderList = () => (
    <ul css={listStyles}>
      <li css={listItemStyles}>
        <p className="smallP" css={textStyles}>
          Share your skin goals and snap selfies
        </p>
      </li>
      <li css={listItemStyles}>
        <p className="smallP" css={textStyles}>
          Your dermatology provider prescribes your formula
        </p>
      </li>
      <li css={listItemStyles}>
        <p className="smallP" css={textStyles}>
          Apply nightly for happy, healthy skin
        </p>
      </li>
    </ul>
  );

  return (
    <OuterContainer>
      <InnerContainer>
        <DesktopContainer>
          {renderTitle()}
          {renderList()}
        </DesktopContainer>
        <div css={mobileOnly('block', 'xlUp')}>
          <AccordionContainer>
            <Accordion
              title={renderTitle()}
              isOpen={isExpandedOnMobile}
              onClick={() => setIsExpandedOnMobile(!isExpandedOnMobile)}
              noBorder
              rightAlignArrow
            >
              {renderList()}
              <CtaButton
                buttonText={CTA_UNLOCK_YOUR_OFFER}
                disclaimerText={DISCLAIMER_CONSULTATION_CANCEL}
                module="navHowItWorksBanner"
                css={ctaButtonStyles}
              />
            </Accordion>
          </AccordionContainer>
        </div>
      </InnerContainer>
    </OuterContainer>
  );
};
