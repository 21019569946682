import { Card } from '@curology/ui-components-web/react';
import type { ReactNode } from 'react';
import React from 'react';

import COLORS from 'styles/constants/colors';

export const SkinQuizCardWrapper = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col w-full justify-center mb-6">
    <Card
      className="px-6 py-6"
      style={{ backgroundColor: COLORS.surfaceLight }}
    >
      {children}
    </Card>
  </div>
);

export const skinQuizButtonStyles = {
  textDecorationLine: 'none',
  fontSize: '0.875rem',
  borderColor: COLORS.surfaceBrandLight,
};
