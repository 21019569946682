import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

// import * as Style from '../ModalDesktop/components/ProductsModalDesktop.style';
import * as Style from './OneNavProductsModalDesktop.style';
import { ProductLinks } from '../';
import { Link } from 'gatsby';
import { PAGE_URLS } from 'consts/pageUrls';
import { ProductDrawers } from './components/ProductDrawers';

export const isOneNavTest = (oneNavVariation: string | undefined) =>
  oneNavVariation === 'v1' || oneNavVariation === 'v2';

export const V1ProductsModalDesktop = () => (
  <React.Fragment>
    <Style.OneNavLinksContainer>
      <ProductLinks isOneNavTest={true} />
    </Style.OneNavLinksContainer>
    <Style.OneNavImageContainer>
      <Link to={PAGE_URLS.shop.bestSellers}>
        <StaticImage
          src="../OneNavTest/images/bestSellersDesktop.png"
          alt="Link to shop.curology.com"
          width={300}
        />
      </Link>
    </Style.OneNavImageContainer>
  </React.Fragment>
);

export const V2ProductsModalDesktop = () => (
  <React.Fragment>
    <ProductDrawers />
    <Style.OneNavImageContainer>
      <Link to={PAGE_URLS.shop.bestSellers}>
        <StaticImage
          src="../OneNavTest/images/bestSellersDesktop.png"
          alt="Link to shop.curology.com"
          width={300}
        />
      </Link>
    </Style.OneNavImageContainer>
  </React.Fragment>
);
