import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { ANIMATION, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';

import { desktopOnly } from 'styles/mixins/displayMobileDesktop';
import { LOCAL_COLORS } from 'styles/constants';

const breakpointSize = 'xlUp';

export const LinksContainer = styled.div<{ isHidingProductCtas: boolean }>`
  ${desktopOnly('flex', breakpointSize)};
  align-items: center;
  justify-content: flex-end;
  z-index: ${Z_SCALE.e2};
  margin-right: ${(props) => (props.isHidingProductCtas ? 0 : SPACER.xlarge)};
`;

const linkStyles = (theme: ThemeType) => css`
  ${TYPOGRAPHY_STYLE.button(theme)};
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: none;
  letter-spacing: unset;
  transition: 350ms;
  cursor: pointer;
`;

const linkPrimaryStyles = (theme: ThemeType) => css`
  ${linkStyles(theme)};
  color: ${theme.COLORS.primary};

  & svg {
    transition: unset;
  }
`;

const linkSignupHighContrastStyles = (theme: ThemeType) => css`
  background-color: ${theme.COLORS.primary};
  color: ${theme.COLORS.white};
  text-decoration: none;
`;

const linkSecondaryStyles = (theme: ThemeType) => css`
  ${linkStyles(theme)};
  color: ${theme.COLORS.white};
`;

const signupPrimaryStyles = (theme: ThemeType) => css`
  ${linkPrimaryStyles(theme)};
  border: 1px solid ${theme.COLORS.primary};
  border-radius: ${SPACER.xsmall};
  padding: 14px 18px;

  &:hover {
    ${linkSignupHighContrastStyles(theme)};
  }
`;

const signupSecondaryStyles = (theme: ThemeType) => css`
  ${linkSecondaryStyles(theme)};
  border: 2px solid ${theme.COLORS.white};
  border-radius: ${SPACER.xsmall};
  padding: 14px 18px;

  &:hover {
    background-color: ${theme.COLORS.white};
    color: ${theme.COLORS.primary};
    text-decoration: none;
  }
`;

const signupDarkSecondaryStyles = (theme: ThemeType) => css`
  ${linkSecondaryStyles(theme)};
  border-radius: ${SPACER.xlarge};
  padding: 0.75rem ${SPACER.base};
  margin: 0 ${SPACER.medium};
  background-color: ${LOCAL_COLORS.surfaceBrandBold};
  text-transform: inherit;
  letter-spacing: 0;

  &:hover {
    background-color: ${LOCAL_COLORS.surfaceBrandBolder};
  }
`;

const loginPrimaryStyles = (theme: ThemeType) => css`
  ${linkPrimaryStyles(theme)};
  padding-right: 36px;
`;

const loginSecondaryStyles = (theme: ThemeType) => css`
  ${linkSecondaryStyles(theme)};
  padding-right: 36px;
`;

type NavTypeType = 'primary' | 'secondary';

const parseStyles = (
  navType: NavTypeType,
  linkType: 'login' | 'signup' | 'signup-dark' | undefined,
  theme: ThemeType,
) => {
  switch (true) {
    case linkType === 'signup' && navType === 'primary':
      return signupPrimaryStyles(theme);

    case linkType === 'signup' && navType === 'secondary':
      return signupSecondaryStyles(theme);

    case linkType === 'login' && navType === 'primary':
      return loginPrimaryStyles(theme);

    case linkType === 'login' && navType === 'secondary':
      return loginSecondaryStyles(theme);

    case linkType === 'signup-dark':
      return signupDarkSecondaryStyles(theme);

    case navType === 'secondary':
      return linkSecondaryStyles(theme);

    default:
      return linkPrimaryStyles(theme);
  }
};

export const PrimaryLinkContainer = styled.div<{ navType: NavTypeType }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 2.5rem;
  padding: 10px 12px 10px 12px;
  transition: border-color ${ANIMATION.defaultTiming};
  white-space: nowrap;

  &:hover {
    background-color: ${LOCAL_COLORS.corePurple50};
  }

  [aria-current='page'] & {
    background: ${LOCAL_COLORS.surfaceBrandLight};
  }
`;

export const styleLink =
  (navType: NavTypeType, linkType?: 'login' | 'signup' | 'signup-dark') =>
  (theme: ThemeType) =>
    css`
      ${parseStyles(navType, linkType, theme)};
    `;

export const chevronIconStyles =
  (navType: NavTypeType, isModalOpen: boolean) => (theme: ThemeType) =>
    css`
      color: ${navType === 'secondary' && theme.COLORS.white};
      margin-left: ${SPACER.xsmall};
      transform: ${isModalOpen ? `rotate(270deg)` : `rotate(90deg)`};
      margin-top: -4px;
      margin-right: -${SPACER.x2small};
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      stroke: ${navType === 'secondary'
        ? theme.COLORS.white
        : theme.COLORS.primary};
      stroke-width: 1px;
    `;

export const mainLinkStyles = (navType: NavTypeType) => (theme: ThemeType) =>
  css`
    margin: 0 ${SPACER.xsmall};
    ${styleLink(navType)(theme)};

    &[aria-expanded='true'] {
      & > div {
        background-color: ${LOCAL_COLORS.surfaceBrandLight};

        &:hover {
          background-color: ${LOCAL_COLORS.corePurple50};
        }
      }
    }
  `;
