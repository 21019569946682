import React from 'react';

import useCart from 'utils/eCommerce/cart/useCart';

import { CartHeader, Details, EmptyCart, LineItems } from './components';

export const CartContents = ({
  onClose,
  isOpening,
}: {
  onClose: () => void;
  isOpening: boolean;
}) => {
  const {
    cart: { itemCount },
  } = useCart();

  const standardCart = (
    <div className="w-full h-full p-8 pt-4 sm:pt-10 relative flex flex-col justify-between">
      <CartHeader onClose={onClose} isOpening={isOpening} />
      <LineItems />
      {itemCount > 0 && <Details />}
    </div>
  );

  return (
    <React.Fragment>
      {itemCount === 0 ? (
        <EmptyCart onClose={onClose} isOpening={isOpening} />
      ) : (
        standardCart
      )}
    </React.Fragment>
  );
};
