import { RICH_TEXT_OPTIONS } from 'consts/contentful';
import { NavBanner } from './NavBanner';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { NavBannerEntry } from 'schema';
import { IconName, ModalProps } from '@curology/ui-components-web/react';
import { ContentfulModal } from 'components/global/modal/ContentfulModal';
import { SmartImage } from 'components/global/SmartImage';
import { contentfulModalStyles } from 'components/global/modal/ContentfulModal.style';
import {
  useHairRxLandingPageRoute,
  useHairRxPromoVariant,
} from 'utils/hooks/useHairPromoVariation';

export const formatBannerProps = ({
  backgroundColor,
  badgeImage,
  bannerPosition,
  body,
  hideIfVanityBannerIsPresent,
  iconName,
  modal,
  textColor,
  ...props
}: NavBannerEntry) => {
  const chiaWelcomeOfferVariant = useHairRxPromoVariant();
  const isHairRxLandingPage = useHairRxLandingPageRoute();

  return {
    ...props,
    iconName: Object.values(IconName).find((name) => name === iconName),
    backgroundColor,
    text:
      isHairRxLandingPage && chiaWelcomeOfferVariant ? (
        <strong
          dangerouslySetInnerHTML={{
            __html: chiaWelcomeOfferVariant.bannerText + '*',
          }}
        ></strong>
      ) : (
        documentToReactComponents(body, RICH_TEXT_OPTIONS)
      ),
    textColor,
    aboveNavigation: bannerPosition,
    hideIfVanity: hideIfVanityBannerIsPresent,
    modalProps: modal
      ? {
          ...modal,
          styles: () => contentfulModalStyles(modal as ModalProps),
          children: <ContentfulModal {...(modal as ModalProps)} />,
        }
      : undefined,
    badge: badgeImage ? <SmartImage {...badgeImage} /> : null,
  };
};

export const ContentfulNavBanner = (props: NavBannerEntry) => {
  return <NavBanner {...formatBannerProps(props)} />;
};
