import { Accordion } from 'radiance-ui';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  AccordionContainer,
  NestedAccordionContainer,
} from './NestableAccordion.style';
import { useIsOnScreen } from './useIsOnScreen';
import { NEGATIVE_IDENTITY } from './constants';

type NestableAccordionProps = Omit<
  React.ComponentProps<typeof Accordion>,
  'noBorder' | 'rightAlignArrow'
>;

const findInternalAccordionContentElement = (container: HTMLElement) =>
  container.querySelector("[class*='ExpansionWrapper']") as HTMLElement;

interface NestedAccordionContextType {
  onUpdateHeight: (deltaHeight: number) => void;
}

const NestedAccordionContext = createContext<NestedAccordionContextType>(
  {} as NestedAccordionContextType,
);

export const NestableAccordion = ({
  children,
  onClick,
  isOpen,
  ...accordionProps
}: NestableAccordionProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isVisible = useIsOnScreen(containerRef);

  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);

  const [contentHeight, setContentHeight] = useState(0);

  const { onUpdateHeight } = useContext(NestedAccordionContext);
  const isChild = !!onUpdateHeight;

  const updateContainerHeight = useCallback(() => {
    if (!containerRef.current) return;

    const content = findInternalAccordionContentElement(containerRef.current);
    setContentHeight(content.scrollHeight);
    onUpdateHeight?.(
      isOpen ? NEGATIVE_IDENTITY * content.scrollHeight : content.scrollHeight,
    );
  }, [containerRef.current]);

  const handleClick = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.KeyboardEvent<Element>,
  ) => {
    updateContainerHeight();
    onClick(event);
  };

  const handleUpdateHeight = (deltaHeight: number) => {
    setContentHeight((prevHeight) => prevHeight + deltaHeight);
  };

  // set initial maxHeight for pre-opened accordion
  useEffect(() => {
    if (!isVisible || hasBeenInitialized || !containerRef.current) return;

    if (isOpen) {
      const content = findInternalAccordionContentElement(containerRef.current);
      setContentHeight(content.scrollHeight);
    }
    setHasBeenInitialized(true);
  }, [
    isVisible,
    containerRef.current,
    hasBeenInitialized,
    setHasBeenInitialized,
  ]);

  const accordion = (
    <Accordion
      {...accordionProps}
      onClick={handleClick}
      isOpen={isOpen}
      noBorder
      rightAlignArrow
    >
      {children}
    </Accordion>
  );

  return (
    <React.Fragment>
      {isChild ? (
        <NestedAccordionContainer
          contentHeight={contentHeight}
          isOpen={isOpen}
          ref={containerRef}
        >
          {accordion}
        </NestedAccordionContainer>
      ) : (
        <NestedAccordionContext.Provider
          value={useMemo(
            () => ({ onUpdateHeight: handleUpdateHeight }),
            [handleUpdateHeight],
          )}
        >
          <AccordionContainer contentHeight={contentHeight} ref={containerRef}>
            {accordion}
          </AccordionContainer>
        </NestedAccordionContext.Provider>
      )}
    </React.Fragment>
  );
};
