import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER, ThemeType } from 'radiance-ui/lib/constants';

import { popRedesignBannerStyles, wiggleAnimation } from '.';

export const laborDayBannerStyles = (theme: ThemeType) => css`
  ${popRedesignBannerStyles(theme)}
  ${wiggleAnimation}

  background: #6f5cff;
  z-index: 1;
  padding: ${SPACER.small};

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #ffffff;
  }

  svg,
  & > span > div:first-of-type {
    display: none;
  }

  img {
    animation: mobileWiggle 8s linear infinite;
  }

  ${MEDIA_QUERIES.xlUp} {
    background: none;
    top: 100px;
    right: 72px;
    padding: 0;

    & > span {
      & > div:first-of-type {
        display: block;
      }

      & > :not(div:first-of-type) {
        display: none;
      }
    }

    img {
      animation: none;
    }
  }
`;
