import { v4 } from 'uuid';

let uuid: string;

export const getSingletonUuid = () => {
  if (!uuid) {
    uuid = v4();
  }

  return uuid;
};
