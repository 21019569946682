export default {
  xxsmall: '0.625rem', // 10px
  xsmall: '0.75rem', // 12px
  small: '1.125rem', // 18px
  base: '1.25rem', // 20px
  medium: '2.25rem', // 36px
  large: '2.75rem', // 44px
  xxlarge: '4.75rem', // 76px
  sectionPaddingRight: '21%',
  desktopMainPadding: '72px',
  carouselCenterPadding: '36px',
};
