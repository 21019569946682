import styled from '@emotion/styled';
import { BREAKPOINTS, MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${BREAKPOINTS.xxl}px;
  width: 100%;
  padding: ${SPACER.xlarge} ${SPACER.large};

  ${MEDIA_QUERIES.lgUp} {
    padding: ${SPACER.large} 3.5rem;
  }
`;

export const FeaturedContainer = styled.div`
  display: flex;
  flex: 1;
  gap: ${SPACER.medium};
  justify-content: center;
  border-left: 1px solid black;
  padding: 0 ${SPACER.large};
`;

export const FlexibleCardContainer = styled.div`
  flex: 1;
  max-width: 272px;
`;
