import { parseQueryString } from 'great-gatsby/utils';

/**
 * This function will check to see if a given query param is present and if not, return null.
 * All query parameter values are strings, but this function will conditionally return a boolean
 * or a number based on the value of the parameter.  If the query parameter does not fit the criteria
 * to be a boolean or a number, the string value will be returned.
 *
 * @param queryParam The name of the parameter
 */
export const getQueryParameterValue = (queryParam: string) => {
  const params = parseQueryString(
    typeof window !== 'undefined' && window.location,
  );

  if (params[queryParam]) {
    const paramValue = params[queryParam];

    if (paramValue === 'true' || paramValue === 'false') {
      return paramValue === 'true';
    }

    if (!Number.isNaN(Number(paramValue))) {
      return Number(paramValue);
    }

    return paramValue;
  }

  return undefined;
};
