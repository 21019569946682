import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

export const AnimatedIcon = styled.div`
  height: 1.3125rem;
  position: relative;
  width: 1.3125rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: ${SPACER.xsmall};
  padding: 0.75rem;

  span:nth-of-type(1) {
    top: 0.09375rem;
  }

  span:nth-of-type(2) {
    top: 50%;
  }

  span:nth-of-type(3) {
    top: calc(100% - 0.09375rem);
  }

  span {
    border-top: 0.1875rem solid black;
    display: block;
    left: 0;
    transform: translateY(-50%);
    transition-property: top, transform;
    transition-duration: 100ms, 250ms;
    transition-delay: 150ms, 0ms;
    width: 100%;
    position: absolute;
  }

  &[data-open='true'] {
    & > span {
      position: absolute;
      top: 50%;
      transition-delay: 0ms, 150ms;
    }

    & > span:nth-child(1),
    & > span:nth-child(2) {
      transform: translateY(-50%) rotate(-45deg);
    }

    & > span:nth-child(3) {
      transform: translateY(-50%) rotate(45deg);
    }
  }
`;
