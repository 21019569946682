import React from 'react';

import {
  NAV_CHIA_PRODUCT_CATEGORIES_CONTENT,
  ONE_NAV_TEST_PRODUCT_CATEGORIES_CONTENT,
} from '../../content';
import { ProductCategory } from './components';
import { NestedLinksContainer } from './ProductLinks.style';

interface ProductLinksProps {
  isOpen?: boolean;
  setIsModalOpen?: (value: boolean) => void;
  isMobile?: boolean;
  isOneNavTest?: boolean;
}

export const ProductLinks = ({
  isOpen = true,
  setIsModalOpen,
  isMobile = false,
  isOneNavTest,
}: ProductLinksProps) => {
  const productCategoriesContent = isOneNavTest
    ? ONE_NAV_TEST_PRODUCT_CATEGORIES_CONTENT
    : NAV_CHIA_PRODUCT_CATEGORIES_CONTENT;

  return (
    <NestedLinksContainer>
      {productCategoriesContent.map((category) => (
        <ProductCategory
          key={category.category}
          content={category}
          isOpen={isOpen}
          setIsModalOpen={setIsModalOpen}
          isMobile={isMobile}
        />
      ))}
    </NestedLinksContainer>
  );
};
