import { ElatedEmoji, TruckIcon } from 'radiance-ui/lib/icons';
import React from 'react';

import { FREE_SHIPPING_MINIMUM } from 'utils/eCommerce/cart/constants';
import useCart from 'utils/eCommerce/cart/useCart';

import { Confetti, FreeShippingProgress } from './components';
import {
  MessageLayout,
  MessageText,
  ShippingIconMessage,
  ShippingMessageWrapper,
} from './FreeShippingMessage.style';

export const FreeShippingMessage = ({ isOpening }: { isOpening: boolean }) => {
  const {
    cart: { total },
    hasAchievedFreeShipping,
    setHasAchievedFreeShipping,
  } = useCart();

  if (total >= FREE_SHIPPING_MINIMUM) {
    return (
      <ShippingMessageWrapper>
        <Confetti
          shouldCelebrate={!hasAchievedFreeShipping && !isOpening}
          onActivated={setHasAchievedFreeShipping}
        />
        <MessageLayout>
          <ShippingIconMessage>
            <ElatedEmoji width={32} height={32} />
            <MessageText>
              You&apos;ve qualified for <strong> free shipping! </strong>
            </MessageText>
          </ShippingIconMessage>
          <FreeShippingProgress percentage={100} />
        </MessageLayout>
      </ShippingMessageWrapper>
    );
  }

  const freeShippingProgress = (total / FREE_SHIPPING_MINIMUM) * 100;
  const freeShippingDifference = (FREE_SHIPPING_MINIMUM - total).toFixed(2);

  return (
    <ShippingMessageWrapper>
      <MessageLayout>
        <ShippingIconMessage>
          <TruckIcon width={16} height={16} style={{ display: 'flex' }} />
          <MessageText>
            You are <strong> ${freeShippingDifference} </strong> away from free
            shipping
          </MessageText>
        </ShippingIconMessage>
        <FreeShippingProgress percentage={freeShippingProgress} />
      </MessageLayout>
    </ShippingMessageWrapper>
  );
};
