import type { SmartImageContent } from 'schema';

export const calculateFocalPoint = (smartImage: SmartImageContent) => {
  if (smartImage.focalPoint === null) return ``;
  const {
    image: { width, height },
    focalPoint: {
      focalPoint: { x, y },
    },
  } = smartImage;

  const xValue = (x / width) * 100;
  const yValue = (y / height) * 100;
  return `${xValue}% ${yValue}%`;
};
