import Cookies from 'js-cookie';

import { getCookieDomain } from 'consts/config';
import { IS_SECURE } from 'consts/environmental';
import { notifyException } from 'utils/errorReporter';

const MAX_COOKIE_BYTES = 4096;

const stringByteSize = (string) =>
  encodeURI(string).split(/%(?:u[0-9A-F]{2})?[0-9A-F]{2}|./).length - 1;

/** @deprecated TODO GE-3421 */
const cleanupUnsecureCookie = (key) => {
  Cookies.remove(key, { domain: getCookieDomain(), secure: false });
};

const get = (key) => Cookies.get(key);

const set = (key, value, options = {}) => {
  const newCookieBytes = stringByteSize(`${key}=${value}`);

  if (newCookieBytes > MAX_COOKIE_BYTES) {
    notifyException(Error('Cookie exceeds maximum size'));
  }

  const cookieOptions = {
    domain: getCookieDomain(),
    expires: 90,
    secure: IS_SECURE,
    ...options,
  };

  cleanupUnsecureCookie(key);
  return Cookies.set(key, value, cookieOptions);
};

const remove = (key) => {
  cleanupUnsecureCookie(key);
  Cookies.remove(key, { domain: getCookieDomain() });
};

const has = (key) => !!get(key);

export default {
  get,
  has,
  remove,
  set,
};
