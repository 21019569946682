import { css } from '@emotion/react';
import { SPACER } from 'radiance-ui/lib/constants';

export const promotionBannerStyles = css`
  font-size: 0.875rem;
  padding: 0.75rem ${SPACER.large};
  line-height: 120%;
  text-transform: initial;
  letter-spacing: initial;
  font-weight: initial;
`;
