import React, { useEffect, useRef } from 'react';
import party from 'party-js';

export const Confetti = ({
  shouldCelebrate,
  onActivated,
}: {
  shouldCelebrate: boolean;
  onActivated: (value: boolean) => void;
}) => {
  const confettiRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (confettiRef.current && shouldCelebrate) {
      party.confetti(confettiRef.current, {
        count: party.variation.range(20, 40),
      });

      onActivated?.(true);
    }
  }, [shouldCelebrate]);

  return (
    <span
      ref={confettiRef}
      className="absolute w-12 h-1 -translate-x-1/2 left-1/2"
    >
      {' '}
    </span>
  );
};
