import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

import type { ColorType } from '.';

const fitContent = 'fit-content';
export const buttonContainerStyles = (
  color: ColorType,
  desktopSize: string,
  mobileSize: string,
  small: boolean,
) => css`
  display: grid;
  color: ${(color === 'white' || color === 'dark-blue') &&
  LOCAL_COLORS.textInverse};
  grid-template-rows: 3.5rem auto;
  margin: 0 auto;
  max-width: ${mobileSize === 'Small' || small ? '20.3125rem' : null};
  row-gap: ${SPACER.base};
  text-align: center;
  white-space: pre-line;
  width: ${small ? fitContent : '100%'};

  ${MEDIA_QUERIES.mdUp} {
    margin: 0;
    text-align: left;
    width: ${desktopSize === 'Small' || small ? fitContent : '17rem'};
  }

  button {
    background-color: ${LOCAL_COLORS.surfaceBrandBold};
    border-color: ${LOCAL_COLORS.surfaceBrandBold};
    border-radius: ${SPACER.x3large};
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: unset;
    text-transform: unset;
    width: ${mobileSize === 'Small'
      ? fitContent
      : mobileSize === 'Large'
      ? '100%'
      : null};

    ${color === 'white' &&
    `
      background-color: ${LOCAL_COLORS.surfaceWhite};
      border-color: ${LOCAL_COLORS.surfaceWhite};
      color: ${LOCAL_COLORS.textDefault};
π
      &:hover {
        color: ${LOCAL_COLORS.textDefault} !important;
      }
    `};

    ${color === 'dark-blue' &&
    `
      background-color: ${LOCAL_COLORS.surfaceDarkBold};
      border-color: ${LOCAL_COLORS.surfaceDarkBold};
      color: ${LOCAL_COLORS.surfaceLight};
    `}

    ${MEDIA_QUERIES.mdUp} {
      width: ${desktopSize === 'Small'
        ? fitContent
        : desktopSize === 'Large'
        ? '100%'
        : null};
    }
  }
`;
