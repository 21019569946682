import { useEffect, useRef } from 'react';
import { useCanonicalHistory } from 'components/global/CanonicalHistoryProvider';

// Runs a callback a single time on the client whenever a page is loaded. This works for both initial load and when using client-side navigation.
// IMPORTANT: Make sure that the provided callback is memoized using useCallback or useMemo. Failing to do this will cause the callback to be run at times other than page load.
export function useOnPageLoad(callback: () => void) {
  const currentCanonicalUrlRef = useRef<string>('');
  const { currentCanonicalUrl } = useCanonicalHistory();

  useEffect(() => {
    if (currentCanonicalUrlRef.current === currentCanonicalUrl) {
      return;
    }

    currentCanonicalUrlRef.current = currentCanonicalUrl;
    callback();
  }, [callback, currentCanonicalUrl]);
}
