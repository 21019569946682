/*
 * Can probably be extracted to site-tools and shared between the two brands.
 */
import axios from 'axios';

import { IS_PRODUCTION } from 'consts/environmental';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import APP_URLS from 'consts/appUrls';
import cookie from 'utils/cookie';
import { notify } from 'utils/errorReporter';
import setUuid from 'utils/setUuid';
import { utmParams } from 'utils/analytics/utmParams';

const hasValidUtmParams = () => {
  const { source, medium, name } = utmParams();

  return [source, medium, name].filter((utmParam) => !!utmParam).length > 1;
};

export const reportReferrerUtm = () => {
  setUuid();

  if (
    IS_PRODUCTION &&
    cookie.has(COOKIE_KEYS.curologyUuid) &&
    (hasValidUtmParams() || document.referrer)
  ) {
    axios
      .post(APP_URLS.api.userReferrerUtm, {
        uuid: cookie.get(COOKIE_KEYS.curologyUuid),
        landing_page_url: window.location.href,
        referrer_url: document.referrer,
        user_agent: (!!window.navigator && window.navigator.userAgent) || '',
      })
      .catch(() => {
        notify('Failed to report UTM/Referrer', 'warning');
      });
  }
};
