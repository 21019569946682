import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const fadeIn = keyframes` 
 from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const VanityBanner = styled.div`
  animation: ${fadeIn} 0.25s;
`;
