import React from 'react';
import { css } from '@emotion/react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import { SPACER } from 'radiance-ui/lib/constants';
import { ContentfulImage } from 'components/global/ContentfulImage';

const containerStyles = css`
  gap: ${SPACER.small};
  display: flex;
  flex-direction: column;
`;

const paragraphStyles = css`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
`;

const unorderedListStyles = css`
  padding-left: ${SPACER.xlarge};
  list-style-type: disc;
`;

const orderedListStyles = css`
  padding-left: ${SPACER.xlarge};
`;

export const RICH_TEXT_OPTIONS: Options = {
  renderNode: {
    [BLOCKS.DOCUMENT]: (_node, children) => (
      <div css={containerStyles}>{children}</div>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p css={paragraphStyles}>{children}</p>
    ),
    [BLOCKS.OL_LIST]: (_node, children) => (
      <ol css={orderedListStyles}>{children}</ol>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => (
      <ul css={unorderedListStyles}>{children}</ul>
    ),
    [INLINES.HYPERLINK]: ({ data }, children) => (
      <a href={data.uri}>{children}</a>
    ),
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => (
      <ContentfulImage image={data.target} />
    ),
  },
};
