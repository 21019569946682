import { COMPANY_INFO } from 'consts/companyInfo';
import { SITE_URL } from 'consts/environmental';
import { EXTERNAL_URLS } from 'consts/externalUrls';

export const SCHEMA_ORG_SCRIPT = `{
  "@context": "http://schema.org",
  "@type": "Organization",
  "name" : "Curology",
  "legalName" : "Curology, Inc.",
  "description" : "Curology makes skincare customized for you",
  "url": "${SITE_URL}",
  "email": "${COMPANY_INFO.emails.support}",
  "logo": "${SITE_URL}/images/logos/curology-c-logo.png",
  "sameAs" : [
    "${EXTERNAL_URLS.facebook}",
    "${EXTERNAL_URLS.twitter}",
    "${EXTERNAL_URLS.instagram}",
    "${EXTERNAL_URLS.youtube}",
    "${EXTERNAL_URLS.pinterest}",
    "${EXTERNAL_URLS.linkedin}"
  ]
}`;
