import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import COLORS from 'styles/constants/colors';

export const LinksContainer = styled.div`
  width: 64%;
  padding: ${SPACER.xlarge} ${SPACER.large};

  ${MEDIA_QUERIES.xlUp} {
    width: 66%;
  }
`;

export const Separator = styled.div`
  &:after {
    position: absolute;
    content: '';
    height: calc(100% - 42px);
    width: 1px;
    background: ${COLORS.softBlack};
  }
`;

export const ProductCTAsContainer = styled.div`
  /* width: 33.333%; */
`;
