import React from 'react';

import { ModalContainer } from './SlideDownModal.style';

export const SlideDownModal = ({
  isOpen,
  children,
  distanceFromTop = '0px',
  transitionDuration = '250ms',
  isFullScreen = false,
}: {
  isOpen: boolean;
  children: React.ReactNode;
  distanceFromTop?: string;
  transitionDuration?: string;
  isFullScreen?: boolean;
}) => (
  <ModalContainer
    isOpen={isOpen}
    distanceFromTop={distanceFromTop}
    transitionDuration={transitionDuration}
    isFullScreen={isFullScreen}
  >
    {children}
  </ModalContainer>
);
