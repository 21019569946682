const legacyColors = {
  grey1: '#999999', // numbers go from darkest to lightest
  orange: '#fc7b65',
  greyBackground: '#dce0e8',
} as const;

const staticPagesColors = {
  softBlack: '#444444',
  purple75: '#706c87',
  svgActive: ' #bdbee3',
  lightWhiteBackground: '#fafafa',
  lightBlack: '#666666',
  reasonsWhyBackground: '#b5ace2',
  borderAlternative: '#dddddd',
  legalLink: '#a8a6b3',
  contactUsBorder: '#c2c0cc',
  birthControlBack: '#e9e9e9',
  birthControlNoneEffect: '#d2c587',
  birthControlUnsetEffect: '#cccccc',
  birthControlBarDefault: '#eeeeee',
  birthControlBarGreen: 'rgb(135, 210, 197)',
  birthControlBarRed: 'rgb(210, 135, 148)',
  testimonialsBackground: '#f2d6c7',
  errorAlertBackground: '#f3dedc',
  errorAlertText: '#bd200f',
  skinGoalsBackground: '#dec9b9',
  newLandingPageCircleBackground: '#c4c4c4',
  postcardGreen: '#b8dca6',
} as const;

const brandResponseColors = {
  brandResponseTable: '#d9ccb9',
  brandResponseBackground: '#e0d8c7',
} as const;

const squeezeColors = {
  surfaceLight: '#f6f5e9',
  surfaceWhite: '#ffffff',
  surfaceDarkBold: '#1d1d44',
  surfaceBrandBold: '#6f5cff',
  surfaceBrandBolder: '#6554E8',
  surfaceBrandLight: '#9ca9ff',
  surfaceBrandLightest: '#eaedfd',
  surfaceOrange: '#ff824f',
  surfacePastelPurple: '#d2d9ff',
  textDefault: '#1d1d44',
  textSubtle: '#4a4a69',
  textInverse: '#ffffff',
  textGreyedOut: '#b9b9c5',
  neutral100: '#f2f2f2',
  orange500: '#ff824f',
  corePurple50: '#f1efff',
  corePurple600: '#6554e8',
  navy400: '#4a4a69',
  coreOrange: '#FA824F',
};

export default {
  ...legacyColors,
  ...staticPagesColors,
  ...brandResponseColors,
  ...squeezeColors,
};
