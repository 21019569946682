import React from 'react';

import { Banner } from 'components/global/Banner';
import { PAGE_URLS } from 'consts/pageUrls';

import { promotionBannerStyles } from './style';
import { useActivePromotion } from 'utils/promotions/useActivePromotion';

export interface PromotionDataType {
  id: number;
  code: string;
  isActive: boolean;
  startsAt: string;
  endsAt: string;
  amountInDollars: number;
}

const UrlActivatedPromotionsBanner = () => {
  const promotion = useActivePromotion();

  if (!promotion || promotion.code === 'HAIR2024') {
    return null;
  }

  const text = (
    <React.Fragment>
      Subscribe today to get ${promotion.amountInDollars} off your first paid
      shipment. Limited time offer.{' '}
      <a href={PAGE_URLS.terms} target="_blank" rel="noopener noreferrer">
        See Terms
      </a>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Banner text={text} css={promotionBannerStyles} />
    </React.Fragment>
  );
};

export default UrlActivatedPromotionsBanner;
