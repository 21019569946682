import { GatsbyImage, type Layout } from 'gatsby-plugin-image';

import { calculateFocalPoint } from 'utils/images';
import { imageAssetToGatsbyImageData } from 'utils/contentful/imageAssetToGatsbyImageData';
import type { SmartImageContent } from 'schema';

type SmartImageProps = SmartImageContent & {
  alt?: string;
  layout?: Layout;
  className?: string;
};

export const SmartImage = ({
  image,
  focalPoint,
  alt,
  layout,
  className,
}: SmartImageProps) => {
  const imageData = imageAssetToGatsbyImageData(image, layout);

  const focalPointData = {
    focalPoint,
    image: { ...image, gatsbyImageData: imageData },
  };

  return (
    <GatsbyImage
      alt={alt ?? image.title}
      image={imageData}
      imgStyle={{
        objectPosition: calculateFocalPoint(focalPointData),
      }}
      className={className}
    />
  );
};
