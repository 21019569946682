import { keyframes } from '@emotion/react';

export const NEGATIVE_IDENTITY = -2;

const PLUS_SIGN =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16' class='cur-fill-current'%3E%3Cpath fill='currentColor' d='M7 9v6h2V9h6V7H9V1H7v6H1v2z'%3E%3C/path%3E%3C/svg%3E";
const MINUS_SIGN =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16' class='cur-fill-current'%3E%3Cpath fill='currentColor' d='M1 7h14v2H1z'%3E%3C/path%3E%3C/svg%3E";

const ARROW_ICON =
  'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="cur-text-inherit"%3E%3Ccircle cx="12" cy="12" r="10.5" stroke="currentColor" stroke-width="3"%3E%3C/circle%3E%3Cpath fill="currentColor" fill-rule="evenodd" d="m12 11.904-2.798 2.798L8 13.5l4-4 4 4-1.202 1.202z" clip-rule="evenodd"%3E%3C/path%3E%3C/svg%3E';

const ICON_OFFSETS = {
  closed: {
    top: '0px',
    left: '-4px',
  },
  open: {
    top: '4px',
    left: '-3px',
  },
};

const fromClosedToOpen = keyframes`
  0% {
    content: url("${PLUS_SIGN}");
    opacity: 1;
    top: ${ICON_OFFSETS.closed.top};
    left: ${ICON_OFFSETS.closed.left};
  }
  50% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    content: url("${MINUS_SIGN}");
    opacity: 1;
    transform: rotate(180deg);
    top: ${ICON_OFFSETS.open.top};
    left: ${ICON_OFFSETS.open.left};
  }
`;

const fromOpenToClosed = keyframes`
  0% {
    content: url("${MINUS_SIGN}");
    opacity: 1;
    top: ${ICON_OFFSETS.open.top};
    left: ${ICON_OFFSETS.open.left};
  }
  50% {
    opacity: 0;
    transform: rotate(90deg);
  }
  100% {
    content: url("${PLUS_SIGN}");
    opacity: 1;
    transform: rotate(180deg);
    top: ${ICON_OFFSETS.closed.top};
    left: ${ICON_OFFSETS.closed.left};
  }
`;

export const NESTED_ACCORDION_DATA = {
  icons: {
    closed: PLUS_SIGN,
    opened: MINUS_SIGN,
    arrow: ARROW_ICON,
  },
  animations: {
    fromClosedToOpen,
    fromOpenToClosed,
  },
  animationDurationMS: 300,
  iconOffsets: ICON_OFFSETS,
};
