import React from 'react';

// Retail - "free trial" related copy permitted on this page
export const FAQ_HOW_DOES_MY_OFFER_WORK_ANSWER =
  'Your free online-exclusive personalized formula and other Curology products will be automatically added to your cart after you complete your skin consultation.';

export const CTA_START_TRIAL_RETAIL = 'Start trial';

export const DISCLAIMER_CLINIAL_TRIAL_RETAIL =
  'Real Curology users. *In a clinical trial of 150 Curology patients. Self-reported. Results may vary.';

export const CARD_CAROUSEL_FREE_MONTH =
  'Your first month of custom skincare is free. After that, pick your plan that will be delivered right to your door every two months. Pause or cancel anytime.';

// CTA Buttons
export const CTA_UNLOCK_YOUR_OFFER = 'Unlock your offer';

export const CTA_UNLOCK_YOUR_TEENS_OFFER = 'Unlock your teen’s offer';

export const CTA_GET_STARTED = 'Get started';

export const CTA_UPGRADE_MY_ROUTINE = 'Upgrade my routine';

export const CTA_ADD_TO_MY_BOX_BODY_WASH = 'Add to my box - $9 / mo';

export const CTA_ADD_TO_MY_BOX_SPOT_PATCH = 'Add to my box - $2.48 / mo';

export const CTA_SHOP_NOW = 'Shop now';

export const CTA_SHOP_ROUTINE_ESSENTIALS = 'Shop routine essentials';

export const CTA_START_YOUR_SKIN_QUIZ = 'Start your skin quiz';

export const CTA_TRY_PRESCRIPTION_SKINCARE = 'Try prescription skincare';

export const CTA_GET_ROUTINE_ESSENTIALS = 'Get routine essentials';

export const CTA_FIND_YOUR_TREATMENT = 'Find your treatment';

export const CTA_GET_MY_FORMULA = 'Get my formula';

export const CTA_LEARN_MORE = 'Learn more';

export const CTA_START_TODAY = 'Start today';

// Disclaimers
export const DISCLAIMER_ARTICLE = `We’re here to tell you what we know, but don’t take it as medical advice.
Talk to your medical provider about your specific health concerns.`;

export const DISCLAIMER_BOTTLE_SIZES =
  'Bottles shown are 24g. Trial-size bottles are 12g';

export const DISCLAIMER_CONSULTATION = 'Subject to consultation.';

export const DISCLAIMER_CONSULTATION_CANCEL =
  '30-day trial. Subject to consultation. Cancel anytime.';

export const DISCLAIMER_CONSULTATION_RESULTS_CANCEL =
  '30-day trial. Subject to consultation.\nResults may vary. Cancel anytime.';

export const DISCLAIMER_CONSULTATION_RESULTS =
  'Subject to consultation. Results may vary.';

export const DISCLAIMER_CONSULTATION_RESULTS_INGREDIENTS_CANCEL =
  'Subject to consultation. Results may vary.\nIngredients subject to provider consultation. Cancel anytime.';

export const DISCLAIMER_SUBSCRIPTION_CONSULATION_UV =
  'Subscription required. Subject to consultation.\nUV radiation from the sun, even while using this product, can still be harmful.';

export const IMPORTANT_SAFETY_INFO_LINK_LABEL_DEFAULT =
  'Important safety information';

// Banners
export const PROMO_BANNER_30_DAY_TRIAL = 'Get your 30-day trial';

export const TRY_CUROLOGY_30_DAYS = 'Try Curology for 30 days';

// Products & Pricing
export const ACNE_BODY_WASH_PRICE = '+$6/month';

export const ACNE_BODY_WASH_PRICE_WITH_BILLING_INFO =
  '+$6/month (billed every 60 days at $12).';

export const CLEANSER_AND_MOISTURIZER_PRICE = '+$10/month';

export const CLEANSER_AND_MOISTURIZER_PRICE_WITH_BILLING_INFO =
  '+$10/month (billed every 60 days at $20).';

export const CUSTOM_FORMULA_PRICE = '29.95';

export const CUSTOM_FORMULA_PRICE_FOR_60_DAYS = '$59.90 for a 60-day supply';

export const CUSTOM_FORMULA_PRICE_PER_MONTH = '$29.95/month';

export const CUSTOM_FORMULA_PRICE_WITH_BILLING_INFO =
  '(billed every 60 days at $59.90).';

export const DISCLAIMER_BI_MONTHLY_TAX =
  'Billed as $4.95 every 60 days (plus tax when applicable)';

export const EMERGENCY_SPOT_PATCH_PRICE = '+$2.48/month';

export const EMERGENCY_SPOT_PATCH_PRICE_WITH_BILLING_INFO =
  '+$2.48/month (billed every 60 days at $4.95).';

export const FUTURE_PROOF_PRICE_PER_MONTH = '$29.95/month';

export const THE_CLEANSER_ECOMMERCE_PRICE = '$12.00';

export const THE_MOISTURIZER_ECOMMERCE_PRICE = '$15.00';

export const EMERGENCY_SPOT_PATCHES_ECOMMERCE_PRICE = '$7.00';

export const GET_WITH_CF = 'Get it with your Custom Formula!';

export const HAIR_FORMULA_PRICE_PER_MONTH = '$44.95/month';

// Modules
export const LANDING_HERO_DESCRIPTION_TEEN =
  'Learn all about the most common teen skincare issues and get started with our customized formula.';

export const COMPLETE_SET_HEADER_TEEN =
  'A full routine with your teen’s 30-day trial';

export const COMPLETE_SET_HEADER_ONE_TRIAL = (
  <React.Fragment>
    One trial,
    <br />3 products delivered.
  </React.Fragment>
);

export const ACNE_FREE_GIFT_POP_MODAL_HEADER =
  'Your First Month of Curology for $5';

export const COMPLETE_SET_DESCRIPTION_ANTI_DEWY =
  'Add our dermatologist-designed cleanser and moisturizer to your trial box. Everything you need for dewy, hydrated skin—in one smart box.';

export const COMPLETE_SET_DESCRIPTION_GLOWING =
  'Add our derm-designed cleanser and moisturizer to your trial box. Everything you need for glowing skin—in one smart box.';

export const COMPLETE_SET_DESCRIPTION_TRIAL_BOX =
  'Need a cleanser and moisturizer? Ours are pH-balanced—and come with your trial box. It’s everything your skin wants in one simple set.';

export const PRICING_DESCRIPTION_CF =
  'Your trial includes your Custom Formula, plus an essential skincare routine to work alongside it. Try your products for 30 days. Then, edit your box to fit your needs and budget.';

export const PRICING_DESCRIPTION_ROSACEA =
  'Put your rosacea on ice with a personalized formula designed by one of our providers, plus other routine essentials. Try Curology for 30 days.';

export const PRICING_DESCRIPTION_TRETINOIN =
  'You’ll get your personalized formula, plus recommended routine essentials made to work alongside it—to try for 30 days. After the trial, subscriptions start at $29.95/month. Easily edit your products or cancel anytime.';

export const PRICING_HEADER_GET_MORE_WITH_YOUR_TRIAL =
  'Get more with your trial';

export const STEPS_HEADER_START_TRIAL =
  'Start your 30-day trial to get personalized skincare';

export const BANNER_MODAL_DESCRIPTION_TEEN =
  'Hey parent, Curology here. We know acne isn’t fun, and we want to help your teen get started with a 30-day trial of a skincare formula that’s personalized to their needs. With a real dermatology provider by their side and a huge community of fellow teens dealing with similar skin concerns, you teen will be in good hands and good company.';

export const FAQ_HOW_DOES_A_TRIAL_WORK_QUESTION =
  'How does a Curology trial work?';

export const FAQ_HOW_DOES_A_TRIAL_WORK_ANSWER =
  'We currently offer a 30-day trial of your teen’s recommended routine to all new members. This includes a trial-size bottle of their personalized formula and other products picked for their skin goals. After the 30-day trial, we’ll automatically send their Curology box with full-size refills every 60 days. You can edit what’s in the box or cancel anytime.';

export const FAQ_HOW_DOES_THE_TRIAL_WORK_QUESTION = 'How does the trial work?';

export const FAQ_HOW_DOES_THE_TRIAL_WORK_ANSWER =
  'When you sign up for the trial, you’ll get a month’s supply of your personalized formula—along with other recommended products to complete your routine. After your 30-day trial, we’ll automatically send your Curology box with full-size refills every 60 days. You can edit what’s in your box or cancel anytime.';

export const FAQ_WHAT_DO_I_GET_WITH_MY_SUBSCRIPTION_ANSWER = (
  <React.Fragment>
    <p>
      You take the skin quiz, and then we recommend a complete routine for you
      to try for 30 days. Your trial box includes your personalized prescription
      formula and derm-designed routine essentials made to work with it. Plus,
      you get access to a real dermatology provider who develops your custom
      treatment plan.
    </p>
    <p>
      After your trial, we’ll automatically send your Curology box with
      full-size refills every 60 days. You can edit the products in your box or
      cancel anytime.
    </p>
  </React.Fragment>
);

export const SKIN_QUIZ_HEADER = 'Prescription-powered personalization';

export const SKIN_QUIZ_DESCRIPTION =
  'Target your skin goals—from clearing acne to preventative anti-aging—with a personalized formula prescribed by a licensed dermatology provider.';

export const EMPTY_CART_MESSAGE = 'Nothing here—yet.';

export const BLOG_CTA_BUTTON = 'Get Curology';

export const ARTICLE_CONVERSION_CTA_BUTTON = 'Start your skin quiz';
