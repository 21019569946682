import { Button } from '@curology/ui-components-web/react';
import { css } from '@emotion/react';
import type { MouseEventHandler, ReactNode } from 'react';
import React from 'react';

import type { PhysicalItem } from 'utils/eCommerce/cart/types';
import COLORS from 'styles/constants/colors';

export const CurologyDotSpinner = ({
  loading = false,
}: {
  loading?: boolean;
}) => (
  <div
    className={`${
      loading ? '' : 'hidden'
    } flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
  >
    <span style={{ animationDelay: '0.5s' }} className="curology-loading-dot">
      {' '}
    </span>
    <span style={{ animationDelay: '1s' }} className="curology-loading-dot">
      {' '}
    </span>
    <span style={{ animationDelay: '1.5s' }} className="curology-loading-dot">
      {' '}
    </span>
  </div>
);

export const Quantity = ({ item }: { item: PhysicalItem }) => (
  <span
    className="flex items-center select-none disabled:cursor-not-allowed pointer-events-none justify-center w-full cur-py-2 cur-leading[1.5] cur-h-[3.5rem] font-bold text-center text-curology-blue-400 text-sm"
    css={css`
      border: 2px solid ${COLORS.surfaceBrandLight};
      border-right: 0;
      border-left: 0;
    `}
    data-testid={`item-quantity-${item.id}`}
  >
    {' '}
    {item.quantity}{' '}
  </span>
);

export const ItemError = ({ errorMessage }: { errorMessage: string }) => (
  <div className="w-full rounded-lg bg-curology-red-100 text-curology-red-500 p-4 mb-1 mt-2 text-sm">
    {errorMessage}
  </div>
);

export const QuantityButton = ({
  type = 'increment',
  item,
  onClick,
}: {
  type: 'increment' | 'decrement';
  item: PhysicalItem;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const direction = type === 'increment' ? 'left' : 'right';

  return (
    <Button
      buttonType="secondary"
      size="md"
      aria-label={`${type} quantity of ${item.name}`}
      onClick={onClick}
      css={css`
        width: 40%;
        color: ${COLORS.surfaceBrandBold} !important;
        border-color: ${COLORS.surfaceBrandLight} !important;
        border-top-${direction}-radius: 0 !important;
        border-bottom-${direction}-radius: 0 !important;
        padding-bottom: 0.885rem !important;
        padding-top: 0.885rem !important;
      `}
      data-testid={`${type}-item-${item.id}`}
    >
      {' '}
      {type === 'increment' ? '+' : '-'}{' '}
    </Button>
  );
};

export const ItemQuantitySection = ({ children }: { children: ReactNode }) => (
  <span className="flex justify-between w-full max-w-[320px]">{children}</span>
);
