import { css } from '@emotion/react';

export default css`
  @font-face {
    font-family: 'nocturno';
    font-display: swap;
    src: url('https://assets.curology.com/fonts/nocturno/nocturno.eot');
    src: url('https://assets.curology.com/fonts/nocturno/nocturno.woff2')
        format('woff2'),
      url('https://assets.curology.com/fonts/nocturno/nocturno.woff')
        format('woff'),
      url('https://assets.curology.com/fonts/nocturno/nocturno.svg')
        format('svg'),
      url('https://assets.curology.com/fonts/nocturno/nocturno.eot?#iefix')
        format('embedded-opentype');
  }

  // Squeeze fonts
  @font-face {
    font-family: 'Skintype Display';
    font-display: block;
    src: url('https://assets.curology.com/fonts/skintype-display/SkintypeDisplay-BoldB.woff')
        format('woff'),
      url('https://assets.curology.com/fonts/skintype-display/SkintypeDisplay-BoldB.woff2')
        format('woff2');
  }
  @font-face {
    font-family: 'Skintype Text';
    font-display: block;
    src: url('https://assets.curology.com/fonts/skintype-text/SkintypeText-Bold.woff')
        format('woff'),
      url('https://assets.curology.com/fonts/skintype-text/SkintypeText-Bold.woff2')
        format('woff2');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Skintype Text';
    font-display: block;
    src: url('https://assets.curology.com/fonts/skintype-text/SkintypeText-Regular.woff')
        format('woff'),
      url('https://assets.curology.com/fonts/skintype-text/SkintypeText-Regular.woff2')
        format('woff2');
    font-weight: normal;
    font-style: normal;
  }
`;
