import React from 'react';
import { AnxiousEmoji } from 'radiance-ui/lib/icons';

import { SEO } from 'components/global/SEO';
import { Nav } from 'components/global/Nav';

import { Wrapper, Title, BodyText } from './Error.style';

export const InternalError = () => (
  <React.Fragment>
    <SEO title="Curology - Error" />
    <Nav />
    <Wrapper>
      <AnxiousEmoji width={55} height={55} />
      <Title>Eeek! Something went wrong</Title>
      <BodyText>We’re on the case.</BodyText>
    </Wrapper>
  </React.Fragment>
);
