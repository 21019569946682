import { CrossIcon } from 'radiance-ui/lib/icons';
import type { ReactNode } from 'react';
import React from 'react';

export const CartHeaderWrapper = ({ children }: { children: ReactNode }) => (
  <div className="sticky top-0 left-0 right-0 flex flex-col gap-2 border-solid border-curology-gray-100">
    {children}
  </div>
);

export const CartHeaderHeading = ({ children }: { children: ReactNode }) => (
  <div className="flex justify-between items-center">{children}</div>
);

export const CloseCartButton = ({ onClose }: { onClose: () => void }) => (
  <button
    id="cart-close-button"
    type="button"
    aria-label="Close cart"
    onClick={onClose}
  >
    <CrossIcon height={16} width={16} />
  </button>
);
