import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

export const CategoryContainer = styled.div`
  margin: ${SPACER.medium} ${SPACER.large} 0 0;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-right: 0;
    border: 0;
  }

  ${MEDIA_QUERIES.lgUp} {
    margin-top: 0;
    padding-bottom: 0;
    border: 0;

    &:last-of-type {
      padding-bottom: ${SPACER.small};
    }
  }

  ${MEDIA_QUERIES.xlUp} {
    margin-left: ${SPACER.medium};
  }
`;

export const ProductCategory = styled.strong`
  color: ${LOCAL_COLORS.surfaceBrandBolder};
  display: block;
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.body};
  line-height: 1.5;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: ${SPACER.medium};
  }
`;

export const NestedAccordionContainer = styled.div`
  ${MEDIA_QUERIES.xlUp} {
    margin-left: 0.5rem;
  }
`;
