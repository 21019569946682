import Cookies from 'js-cookie';
import type { Cart, CartResource } from './types';

const total = (data: CartResource) =>
  data.lineItems.physicalItems
    .map((item) => item.quantity * item.listPrice)
    .reduce((sum, current) => sum + current, 0);

const count = (data: CartResource) =>
  data.lineItems.physicalItems
    .map((item) => item.quantity)
    .reduce((sum, current) => sum + current, 0);

const parseRxName = (name: string) => {
  return `${name.split('\u211e')[0]}<sup>Rx</sup>`;
};

export const fromCartResource = (data: CartResource): Cart => {
  if (data.containsRx) {
    data.lineItems.physicalItems = data.lineItems.physicalItems.map((item) => ({
      ...item,
      name: item.isRx ? parseRxName(item.name) : item.name,
    }));
  }

  return {
    id: data.id,
    itemCount: count(data),
    items: data.lineItems.physicalItems,
    total: total(data),
    containsRx: data.containsRx,
    checkoutUrl: data.checkoutUrl,
  };
};

export const setCartCookie = (cartId: string, currentOrigin: string) => {
  // lets ensure we are setting the cookie on the top level domain
  const tldDomain = '.' + currentOrigin.split('.').slice(-2).join('.');
  Cookies.set('cartId', cartId, { expires: 90, domain: tldDomain });
};
