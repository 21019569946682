import styled from '@emotion/styled';
import type { TransitionStatus } from 'react-transition-group/Transition';

import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

export const ModalContainer = styled.div`
  width: 100vw;
  position: absolute;
  background-color: ${({ theme }) => theme.COLORS.white};
  cursor: default;
  ${desktopOnly('block', 'xlUp')};
`;

export const ContentContainer = styled.div<{
  transitionState: TransitionStatus;
}>`
  margin: 0 auto;
  display: ${({ transitionState }) =>
    transitionState === 'exited' ? 'none' : 'flex'};
  width: 100%;
`;
