import { keyframes } from '@emotion/react';

export const slideIn = keyframes`
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`;

export const slideOut = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
`;

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const CART_SLIDE_DURATION_MS = 350;
