import type { ReactNode } from 'react';
import React from 'react';
import { LOCAL_COLORS } from 'styles/constants';

import type { PhysicalItem } from 'utils/eCommerce/cart/types';

export const LineItemWrapper = ({ children }: { children: ReactNode }) => (
  <div className="flex gap-6  group py-4">{children}</div>
);

export const LineItemDetails = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col justify-between h-full flex-1 relative">
    {children}
  </div>
);

export const ItemView = ({ children }: { children: ReactNode }) => (
  <div className="w-full flex flex-col gap-1">{children}</div>
);

export const NameRow = ({ children }: { children: ReactNode }) => (
  <div className="flex items-start justify-between w-full -mb-1">
    {children}
  </div>
);

export const PriceAndVariantRow = ({ children }: { children: ReactNode }) => (
  <div className="flex items-start gap-2 w-full -mb-1">{children}</div>
);

export const ItemName = ({ item }: { item: PhysicalItem }) => (
  <h4 className="text-base">
    <span dangerouslySetInnerHTML={{ __html: item.name }} />
    {item.isRx && <ProductTag text={'Rx'} />}
  </h4>
);

export const ItemVariantName = ({ item }: { item: PhysicalItem }) => {
  const variantName = item.productVariant.option?.selection.name;

  if (!variantName) return null;

  return (
    <React.Fragment>
      <p className="mt-[2px] text-sm">
        <strong>•</strong>
      </p>
      <p className="mt-[2px] text-sm">{variantName}</p>
    </React.Fragment>
  );
};

export const ItemTotal = ({ item }: { item: PhysicalItem }) => (
  <p className="mt-[2px] text-sm">
    $<span>{(item.listPrice * item.quantity).toFixed(2)}</span>{' '}
  </p>
);

export const LineItemThumbnail = ({ item }: { item: PhysicalItem }) => (
  <div className="w-32 h-32">
    <img className="h-full flex" alt={item.name} src={item.imageUrl} />
  </div>
);

export const ProductTag = ({ text }: { text: string }) => (
  <span
    className="w-fit px-1 font-bold text-xs inline-block"
    style={{
      backgroundColor: LOCAL_COLORS.corePurple50,
      color: LOCAL_COLORS.surfaceDarkBold,
      lineHeight: '18px',
      padding: '0 4px',
    }}
  >
    {text}
  </span>
);
