import React, {
  useEffect,
  useState,
  type ReactElement,
  type ReactNode,
} from 'react';
import { useAtomValue } from 'jotai';
import { useLocation } from '@gatsbyjs/reach-router';
import { Icon, IconName } from '@curology/ui-components-web/react';

import { COOKIE_KEYS } from 'consts/cookieKeys';
import APP_URLS from 'consts/appUrls';
import { FREE_TRIAL_URLS } from 'consts/freeTrialUrls';
import cookie from 'utils/cookie';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';

import { Banner } from 'components/global/Banner';
import { bannerTextAtom } from 'components/pages/VanityRedirect';
import {
  CtaModal,
  CtaModalProps,
  Modal,
  ModalProps,
} from 'components/global/modal';
import { VanityBanner } from '.';
import { Badge, NavBannerBox } from './NavBanner.style';
import { LOCAL_COLORS } from 'styles/constants';
import { Z_SCALE } from 'radiance-ui/lib/constants';
import { ldClientReady, useFlag } from 'utils/featureFlagsAndMetrics';

export interface NavBannerProps {
  iconName?: IconName;
  backgroundColor?: string;
  text?: ReactNode;
  textColor?: string;
  ctaText?: string | ReactElement;
  modalProps?: ModalProps | CtaModalProps;
  showModalOnLoad?: boolean;
  cookieKey?: string;
  isClosable?: boolean;
  floatingOnDesktop?: boolean;
  hideIfVanity?: boolean;
  aboveNavigation?: boolean;
  excludePaths?: string[];
  badge?: ReactNode;
}

interface PromoFlag {
  bannerText: string;
  freeTrialBannerText?: string;
  imageUrl: string;
}

const getBannerText = (promoFlag?: PromoFlag, text?: ReactNode) => {
  if (
    typeof window !== 'undefined' &&
    window?.location?.pathname &&
    FREE_TRIAL_URLS.includes(window.location.pathname)
  ) {
    return promoFlag?.freeTrialBannerText ?? text;
  }

  return promoFlag?.bannerText ?? text;
};

const hasActiveGiftWithPurchase = (promoFlag?: PromoFlag): boolean => {
  return promoFlag?.bannerText !== undefined;
};

export const NavBanner = ({
  iconName,
  backgroundColor = LOCAL_COLORS.surfaceBrandBold,
  text,
  textColor = LOCAL_COLORS.surfaceWhite,
  ctaText,
  modalProps,
  showModalOnLoad = false,
  cookieKey,
  isClosable,
  floatingOnDesktop,
  hideIfVanity = false,
  excludePaths = [],
  badge,
  ...props
}: NavBannerProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const vanityBannerText = useAtomValue(bannerTextAtom);

  const location = useLocation();

  const hideBanner = excludePaths.some((pattern) =>
    location.pathname.match(pattern),
  );

  useEffect(() => {
    const hasBanner = !!props || !!vanityBannerText;
    if (hasBanner && cookie.has(COOKIE_KEYS.promotionCode)) {
      cookie.remove(COOKIE_KEYS.promotionCode);
    }
  }, []);

  useEffect(() => {
    if (showModalOnLoad && !cookie.has(COOKIE_KEYS.viewedRetailOfferModal)) {
      setModalIsOpen(true);

      cookie.set(COOKIE_KEYS.viewedRetailOfferModal, 'true', {
        expires: 1,
      });
    }
  }, []);

  if (hideIfVanity && vanityBannerText) {
    return (
      <VanityBanner>
        <Banner text={vanityBannerText} />
      </VanityBanner>
    );
  }

  if (!text || hideBanner) {
    return null;
  }

  const navigateToApp = () => navExternalWithQueryString(APP_URLS.welcome);
  const handleBannerClick = () => {
    if (modalProps) {
      setModalIsOpen(true);
    }
  };
  const handleModalClose = () => setModalIsOpen(false);
  const promoFlag = useFlag<PromoFlag | undefined>(
    'curology-gift-with-purchase',
  );

  const bannerText = getBannerText(promoFlag, text);
  const showGiftWithPurchaseBanner = hasActiveGiftWithPurchase(promoFlag);
  const bannerIcon = showGiftWithPurchaseBanner ? IconName.Gift : iconName;
  const bannerTextColor = showGiftWithPurchaseBanner
    ? LOCAL_COLORS.surfaceWhite
    : textColor;
  const bannerBackgroundColor = showGiftWithPurchaseBanner
    ? LOCAL_COLORS.surfaceDarkBold
    : backgroundColor;

  if (!bannerText) {
    return null;
  }

  return (
    <React.Fragment>
      {modalProps &&
        modalIsOpen &&
        ('content' in modalProps ? (
          <CtaModal {...modalProps} onClose={handleModalClose} />
        ) : (
          <Modal {...(modalProps as ModalProps)} onClose={handleModalClose} />
        ))}

      {ldClientReady && (
        <NavBannerBox
          isClickable={!ctaText && !!modalProps}
          isFloating={!badge && !!floatingOnDesktop}
          hasBadge={!!badge}
          backgroundColor={bannerBackgroundColor}
          textColor={bannerTextColor}
          zIndex={props.aboveNavigation ? Z_SCALE.modal + 1 : 1}
          isClosable={isClosable}
          text={
            <React.Fragment>
              {bannerIcon && <Icon size="sm" name={bannerIcon} />}
              <span>{bannerText}</span>
            </React.Fragment>
          }
          ctaText={ctaText}
          onClick={ctaText ? undefined : handleBannerClick}
          onCtaClick={modalProps ? handleBannerClick : navigateToApp}
          cookieKey={cookieKey}
        />
      )}

      {badge && (
        <Badge
          isClickable={!!modalProps}
          onClick={modalProps ? handleBannerClick : navigateToApp}
        >
          {badge}
        </Badge>
      )}
    </React.Fragment>
  );
};
