import React from 'react';

import * as Style from './ProductsModalDesktop.style';
import { ProductLinks, ProductCTAs } from '../..';

export const ProductsModalDesktop = () => (
  <React.Fragment>
    <Style.LinksContainer>
      <ProductLinks />
    </Style.LinksContainer>
    <Style.Separator />
    <Style.ProductCTAsContainer>
      <ProductCTAs />
    </Style.ProductCTAsContainer>
  </React.Fragment>
);
