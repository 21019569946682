import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER, Z_SCALE } from 'radiance-ui/lib/constants';

export const HoverImageContainer = styled.div<{ isClickable?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin-bottom: ${SPACER.medium};
  opacity: 0;
  will-change: opacity;
  transition: opacity 200ms;
`;

export const linkContainer = (theme: ThemeType) => css`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${SPACER.medium};
  text-decoration: none;

  strong {
    text-decoration: underline transparent;
    transition: text-decoration 200ms;
  }

  ${MEDIA_QUERIES.lgUp} {
    &:hover strong {
      text-decoration: underline ${theme.COLORS.primary};
    }

    &:hover ${HoverImageContainer} {
      opacity: 1;
    }
  }
`;

export const OuterContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${SPACER.medium};
`;

export const ChipContainer = styled.div`
  position: absolute;
  top: ${SPACER.medium};
  left: ${SPACER.medium};
  z-index: ${Z_SCALE.e2};
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  z-index: ${Z_SCALE.e0};
`;

export const TagsContainer = styled.div`
  margin-bottom: ${SPACER.medium};
  display: flex;
  gap: ${SPACER.small};
`;

export const titleStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
`;

export const bodyStyles = css`
  margin: ${SPACER.small} 0;
`;

export const FeatureListItem = styled.div`
  display: flex;
  align-items: center;
  margin: ${SPACER.small} 0;

  svg {
    overflow: visible;
    margin-right: ${SPACER.medium};
  }

  p {
    color: ${({ theme }) => theme.COLORS.primary};
  }
`;
