export const deviceId = () => {
  return (
    window?.amplitude?.getInstance?.()?.options?.deviceId ??
    window?.amplitude?.getDeviceId?.() ??
    ''
  );
};

export const isAmplitudeInitialized = () => {
  return !!window?.amplitude;
};

export const isAmplitudeLegacySdk = () => {
  return !!window?.amplitude?.getInstance;
};
