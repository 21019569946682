import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Banner } from 'components/global/Banner';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const wiggleAnimation = css`
  @keyframes desktopWiggle {
    1.25% {
      transform: translateX(-20px) rotate(-7deg);
    }
    3.75% {
      transform: translateX(20px) rotate(7deg);
    }
    5%,
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }

  @keyframes mobileWiggle {
    1.25% {
      transform: translateX(-5px);
    }
    3.75% {
      transform: translateX(5px);
    }
    5%,
    100% {
      transform: translateX(0);
    }
  }
`;

export const NavBannerBox = styled(Banner)<{
  isClickable: boolean;
  isFloating: boolean;
  hasBadge: boolean;
  backgroundColor: string;
  textColor: string;
  zIndex: number;
}>`
  ${wiggleAnimation}
  ${({ hasBadge }) => hasBadge && mobileOnly()}
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1.25;
  gap: ${SPACER.xsmall};
  justify-content: center;
  letter-spacing: initial;
  padding: 0.75rem ${SPACER.medium};
  text-transform: initial;
  z-index: ${({ zIndex }) => zIndex};

  ${({ isFloating }) =>
    isFloating &&
    css`
      ${MEDIA_QUERIES.mdUp} {
        animation: desktopWiggle 8s linear infinite;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
        position: fixed;
        right: 72px;
        top: 146px;
        width: fit-content;
        background-color: none;

        b {
          display: block;
          font-size: 14px;
          font-weight: 700;
          text-align: left;
        }

        svg {
          animation: none;
          flex-shrink: 0;
          height: ${SPACER.xlarge};
          width: ${SPACER.xlarge};
        }
      }
    `}

  & > svg {
    animation: mobileWiggle 8s linear infinite;
    position: relative;
    top: 0.1rem;
  }

  button,
  svg {
    color: ${({ textColor }) => textColor};
  }
`;

export const Badge = styled.div<{ isClickable: boolean }>`
  ${desktopOnly()}
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  position: absolute;
  right: ${SPACER.large};
  top: 133px;
  width: 130px;
  height: 130px;
`;
