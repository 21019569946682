import { Button } from '@curology/ui-components-web/react';
import type { ReactNode } from 'react';
import React from 'react';

import { navExternalWithQueryString } from 'utils/marketingSafeDomain';
import { trackEvent } from 'utils/metricsTracking';
import type eCommerceUrls from 'consts/eCommerceUrls';
import { BRAND, type EVENTS } from 'consts/metricsTrackingEvents';
import { MODULES } from 'consts/modules';

interface EcommerceCTAProps {
  children: ReactNode;
  href: (typeof eCommerceUrls)[keyof typeof eCommerceUrls];
  trackingEvent?: (typeof EVENTS)[keyof typeof EVENTS];
  containsRx?: boolean;
}

export const EcommerceCTA = ({
  children,
  containsRx,
  href,
  trackingEvent,
}: EcommerceCTAProps) => {
  const eventPayload = containsRx
    ? {
        brand: BRAND,
        path: window.location.pathname,
        module: MODULES.navigation,
        subscription_type: '1x Rx',
      }
    : {
        brand: BRAND,
        path: window.location.pathname,
        module: MODULES.navigation,
      };

  const handleOnClick = () => {
    if (trackingEvent) trackEvent(trackingEvent, eventPayload);
    navExternalWithQueryString(href);
  };

  return (
    <Button
      buttonType="primary"
      className="text-white"
      disabled={false}
      onClick={handleOnClick}
      state="brand"
      size="md"
      style={{
        textDecorationLine: 'none',
        fontSize: '0.875rem',
      }}
    >
      {children}
    </Button>
  );
};
