export const EVENTS = {
  atcClicked: 'ATC clicked',
  blogLinkClicked: 'Blog Link clicked',
  cardClicked: 'Card clicked',
  ctaClicked: 'CTA clicked',
  loadedAPage: 'Loaded a Page',
  loginClicked: 'Login clicked',
  moduleInteraction: 'Module Interaction',
  moduleViewed: 'Module Viewed',
  offerCookieSet: 'Offer Cookie Set',
  offerCookieUnassign: 'Offer Cookie Unassign',
  shareClicked: 'Share Clicked',
  launchDarklyFlagAssigned: 'Launch Darkly Flag Assigned',
  promoCodeBannerClicked: 'Promo Code Banner Clicked',
  linkClicked: 'Link clicked', // TODO: merge non-CTA clicks; use properties for specificity
  removeRxProduct: 'Removed Rx Product From Cart',
  skinQuizCtaClicked: 'Skin Quiz Link Clicked',
  shopCtaClicked: 'Shop Link Clicked',
  cookieBannerClosed: 'Cookie Banner Closed',
  cookieBannerAccepted: 'Cookie Banner Accepted',
  loudCrowdCtaClicked: 'LoudCrowd Button Clicked',
  flagEvaluated: 'Flag Evaluated',
} as const;

export const BRAND = 'curology';
