import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const NestedLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${SPACER.medium};

  ${MEDIA_QUERIES.mdUp} {
    padding-top: 0;
  }

  ${MEDIA_QUERIES.xlUp} {
    flex-direction: row;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;
  }
`;
