import type { SEOPropsType } from '..';

export const SEO_DARK_SPOT_FORMULA_CONTENT: SEOPropsType = {
  title: 'Treat Dark Spots, Melasma and More | Curology Custom Skincare',
  description:
    'Get a powerful dark spot-correcting formula prescribed by your personal provider.',
};

export const SEO_DARK_SPOT_FORMULA_MARKETING_SAFE_CONTENT: SEOPropsType = {
  title: 'Treat Dark Spots, Fine Lines, and More | Curology Custom Skincare',
  description:
    'Get a powerful dark spot-correcting formula mixed for you by a licensed expert.',
};
