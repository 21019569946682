import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

const cartWrapperStyles = css`
  z-index: 1000001; // must beat mobile nav spacer

  body,
  p,
  a,
  h2,
  h3,
  h4,
  h5 {
    color: #332e54;
    font-family: 'Skintype Text', 'Avant Garde', Avantgarde, 'Century Gothic',
      CenturyGothic, AppleGothic, Verdana, sans-serif;
  }
   {
  }
  span {
    font-family: 'Skintype Text', 'Avant Garde', Avantgarde, 'Century Gothic',
      CenturyGothic, AppleGothic, Verdana, sans-serif;
  }

  .text-white {
    color: white;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
`;

export const CartWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 24px;

  ${cartWrapperStyles};
  ${desktopOnly('block', 'xlUp')};
`;

export const MobileCartWrapper = styled.div`
  margin-right: 12px;
  margin-top: 6px;
  ${cartWrapperStyles};
  ${mobileOnly('block', 'xlUp')};
`;
