import React from 'react';

interface FreeShippingProgressProps {
  percentage: number;
}

export const FreeShippingProgress = ({
  percentage,
}: FreeShippingProgressProps) => (
  <div className="w-full rounded-md h-1 relative bg-white">
    <div
      className="transition-[width] rounded-md h-1 absolute bg-curology-blue-300"
      style={{ width: `${percentage}%` }}
    />
  </div>
);
