import React from 'react';

import type { SVGProps } from 'types/svg';

export const MenuIcon = ({ fill = '#1D1D44' }: SVGProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" fill="white" />
      <line x1="10" y1="12" x2="30" y2="12" stroke={fill} strokeWidth="3" />
      <line x1="10" y1="20" x2="30" y2="20" stroke={fill} strokeWidth="3" />
      <line x1="10" y1="28" x2="30" y2="28" stroke={fill} strokeWidth="3" />
    </svg>
  );
};
