import { Button } from 'radiance-ui';
import React, { useState } from 'react';

import APP_URLS from 'consts/appUrls';
import { BRAND, EVENTS } from 'consts/metricsTrackingEvents';
import { getFormattedLink } from 'utils/getFormattedLink';
import {
  navExternalWithQueryString,
  navInternalWithQueryString,
} from 'utils/marketingSafeDomain';

import {
  isAmplitudeInitialized,
  isAmplitudeLegacySdk,
} from 'utils/analytics/amplitude';
import { buttonContainerStyles } from './CtaButton.style';

import { Modal } from 'components/global/modal';
import { ServicesModalDesktop } from 'components/global/Nav/components/ModalDesktop/components';
import { LOCAL_COLORS } from 'styles/constants';

export type ColorType = 'primary' | 'white' | 'dark-blue';

export interface CtaButtonProps {
  buttonText: string;
  className?: string;
  color?: ColorType;
  desktopSize?: 'Small' | 'Large';
  disclaimerText?: React.ReactNode;
  disclaimerLink?: string;
  isExternal?: boolean;
  link?: string;
  mobileSize?: 'Small' | 'Large';
  /** Used internally for analytics tracking. We should include this prop whenever this button is within a module. Follow camelCase convention. */
  module?: string;
  openServiceModal?: boolean;
  overrideClick?: () => void;
  small?: boolean;
}

export const CtaButton = ({
  buttonText,
  className,
  color = 'primary',
  desktopSize = 'Large',
  disclaimerText,
  disclaimerLink,
  isExternal,
  link,
  mobileSize = 'Large',
  module,
  openServiceModal,
  overrideClick,
  small = false,
  ...rest
}: CtaButtonProps) => {
  const [isModalHidden, setIsModalHidden] = useState(true);

  const handleModalClose = () => {
    setIsModalHidden(true);
  };

  const appUrl = APP_URLS.welcome;
  const formattedLink = link && getFormattedLink(link);
  const redirect = () => {
    if (link && isExternal) navExternalWithQueryString(link);
    else if (formattedLink) navInternalWithQueryString(formattedLink);
    else navExternalWithQueryString(appUrl);
  };

  const handleClick = () => {
    const event = formattedLink ? EVENTS.linkClicked : EVENTS.ctaClicked;
    const payload = {
      path: window.location.pathname,
      url: window.location.href,
      module,
      brand: BRAND,
    };

    let ctaAction = redirect;

    if (overrideClick) {
      ctaAction = () => overrideClick();
    } else if (openServiceModal) {
      ctaAction = () => setIsModalHidden(false);
    }

    if (isAmplitudeInitialized()) {
      isAmplitudeLegacySdk()
        ? window.amplitude.getInstance().logEvent(event, payload, ctaAction)
        : window.amplitude.track(event, payload);
    }

    ctaAction();
  };

  return (
    <React.Fragment>
      {!isModalHidden && (
        <Modal
          children={<ServicesModalDesktop module={''} />}
          backgroundColor={LOCAL_COLORS.surfaceWhite}
          onClose={handleModalClose}
        />
      )}
      <div
        className={className}
        css={buttonContainerStyles(color, desktopSize, mobileSize, small)}
      >
        <Button isFullWidth onClick={handleClick} {...rest}>
          {buttonText}
        </Button>
        {disclaimerText && <small>{disclaimerText}</small>}
        {disclaimerLink && (
          <small
            dangerouslySetInnerHTML={{
              __html: disclaimerLink,
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};
