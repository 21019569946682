import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

export const NestedLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: ${SPACER.medium} 0;
  max-width: ;
  &:hover {
    text-decoration: underline;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #ededf0;
  }
`;

export const ServiceLinkTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
`;

export const ServiceLinkSubtitle = styled.div`
  color: #332e54;
  font-size: 16px;
`;
