import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSetAtom } from 'jotai';

import { captureReferrerUrl } from 'utils/captureReferrer';
import { navExternalWithQueryString } from 'utils/marketingSafeDomain';
import { bannerTextAtom } from '.';
import type { VanityRedirectEntry } from 'schema';

export const VanityRedirect = ({
  token,
  redirectPath,
  utmCampaign,
  utmCreative,
  utmMedium,
  utmSource,
  bannerText,
}: VanityRedirectEntry) => {
  const setBannerText = useSetAtom(bannerTextAtom);

  useEffect(() => {
    captureReferrerUrl();

    if (bannerText) {
      setBannerText(bannerText);
    }

    const { searchParams } = new URL(window.location.href);
    if (utmSource) searchParams.set('utm_source', utmSource);
    if (utmMedium) searchParams.set('utm_medium', utmMedium);
    if (utmCampaign) searchParams.set('utm_campaign', utmCampaign);
    if (utmCreative) searchParams.set('utm_creative', utmCreative);
    searchParams.set('t', token);

    if (redirectPath.startsWith('https://')) {
      const redirectUrl = new URL(redirectPath);
      redirectUrl.search = searchParams.toString();
      navExternalWithQueryString(redirectUrl.toString());
    }

    navigate(`${redirectPath}?${searchParams}`, {
      replace: true,
    });
  }, [
    token,
    redirectPath,
    utmCampaign,
    utmCreative,
    utmMedium,
    utmSource,
    bannerText,
    setBannerText,
  ]);

  return null;
};
