import React from 'react';
import { CheckmarkIcon, SparkleEmoji } from 'radiance-ui/lib/icons';
import { useTheme } from '@emotion/react';
import { v4 } from 'uuid';
import { StaticImage } from 'gatsby-plugin-image';

import { Modal } from './Modal';
import {
  CTA_UNLOCK_YOUR_TEENS_OFFER,
  DISCLAIMER_CONSULTATION_CANCEL,
} from 'consts/copy';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';
import { CtaButton } from 'components/global/CtaButton';

import {
  ModalBody,
  ModalContent,
  HorizontalRuleContainer,
  horizontalRule,
  BodyTitle,
  CheckmarkIconContainer,
  list,
  listItem,
  accessDescriptionText,
  SparkleEmojiContainer,
  headerImage,
  ImageContainer,
} from './CtaModal.style';

export interface CtaModalContent {
  header: string;
  description: string;
  accessDescription: string;
  accessList: Array<string | React.ReactElement>;
}

export interface CtaModalProps {
  onClose?: () => void;
  content?: CtaModalContent;
}

export const CtaModal = ({
  onClose,
  content = {
    header: '',
    description: '',
    accessDescription: '',
    accessList: [],
  },
}: {
  onClose: () => void;
  content?: CtaModalContent;
}) => {
  const theme = useTheme();
  const { header, description, accessDescription, accessList } = content;
  const moduleName = 'Modal';

  return (
    <Modal onClose={onClose}>
      <ModalBody>
        <ImageContainer>
          <StaticImage
            src="./images/headerDesktop@2x.jpg"
            alt="Smiling Curology patient"
            css={[headerImage, desktopOnly()]}
          />
          <StaticImage
            src="./images/header@2x.jpg"
            alt="Smiling Curology patient"
            css={[headerImage, mobileOnly()]}
          />
        </ImageContainer>
        <ModalContent>
          {header && <BodyTitle>{header}</BodyTitle>}
          {description && <p>{description}</p>}
          <HorizontalRuleContainer>
            <hr css={horizontalRule(theme)} />
            <SparkleEmojiContainer>
              <SparkleEmoji height={48} width={48} />
            </SparkleEmojiContainer>
          </HorizontalRuleContainer>

          {accessDescription && (
            <p css={accessDescriptionText(theme)}>{accessDescription}</p>
          )}
          {list && (
            <ul css={list}>
              {accessList.map((item) => (
                <li key={v4()} css={listItem(theme)}>
                  <CheckmarkIconContainer>
                    <CheckmarkIcon size={16} />
                  </CheckmarkIconContainer>
                  {item}
                </li>
              ))}
            </ul>
          )}
          <CtaButton
            buttonText={CTA_UNLOCK_YOUR_TEENS_OFFER}
            disclaimerText={DISCLAIMER_CONSULTATION_CANCEL}
            module={moduleName}
          />
        </ModalContent>
      </ModalBody>
    </Modal>
  );
};
