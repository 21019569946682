import { IS_PRODUCTION } from 'consts/environmental';

export const getCookieDomain = (hostname = window.location.hostname) => {
  if (
    hostname.includes('localhost') ||
    hostname.includes('pages.dev') ||
    /^\d+(\.\d+){3}$/.test(hostname)
  ) {
    return undefined;
  }

  // This assumes that the format is in subdomain.domain.tld or domain.tld
  // and that tld does not have multiple periods.
  // This would break for things like curology.co.uk
  const hostnameArray = hostname.split('.');
  const domain = hostnameArray.slice(hostnameArray.length - 2).join('.');
  return `.${domain}`;
};

const config = {
  AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
  APP_ENV: process.env.APP_ENV,
  APP_URL: process.env.APP_URL,
  GTM_KEY: process.env.GTM_KEY,
  GTM_DEV_KEY: process.env.GTM_DEV_KEY,
  IS_PRODUCTION,
  LD_CLIENT_SIDE_ID: process.env.LD_CLIENT_SIDE_ID,
  MARKETING_SAFE_DOMAIN: 'curology.co',
  SITE_URL: process.env.SITE_URL,
  ECOMMERCE_URL:
    process.env.BIGCOMMERCE_STORE_URI || 'https://shop.curology.com',
};

export default config;
