import React from 'react';
import { NavBannerProps } from '../NavBanner';
import { POP_REDESIGN_BANNER_CONTENT } from './popRedesign';
import {
  ModalPop,
  RETAIL_OFFER_MODAL_CONTENT,
} from 'components/modules/pop-redesign/ModalPop';

export const RETAIL_OFFER_BANNER_CONTENT: NavBannerProps = {
  ...POP_REDESIGN_BANNER_CONTENT,
  text: (
    <React.Fragment>
      <strong>EXCLUSIVE OFFER</strong> when you subscribe today
    </React.Fragment>
  ),
  showModalOnLoad: true,
  modalProps: {
    renderCloseButton: false,
    children: <ModalPop {...RETAIL_OFFER_MODAL_CONTENT} />,
  },
};
