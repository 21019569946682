import maleImageSrc from 'images/seo/male-og-image.jpg';

import { SEO_CORE_CONTENT, type SEOPropsType } from '..';

export const SEO_MALE_CONTENT: SEOPropsType = {
  ...SEO_CORE_CONTENT,
  ogImage: maleImageSrc,
};

export const SEO_MALE_MARKETING_SAFE_CONTENT: SEOPropsType = {
  title: 'Curology Custom Skin Care',
  ogImage: maleImageSrc,
};
