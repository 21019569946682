import React from 'react';

import COLORS from 'styles/constants/colors';

export const ItemCountBubble = ({ itemCount }: { itemCount: number }) => {
  if (itemCount <= 0) return null;

  return (
    <span
      data-testid="shopping-cart-item-count"
      className="absolute rounded-full items-center justify-center w-4 h-4 pt-[1px] pl-[1px] flex"
      style={{
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '1.67',
        letterSpacing: '1px',
        color: COLORS.surfaceDarkBold,
        background: COLORS.surfaceOrange,
        top: '-0.15rem',
        right: '-0.55rem',
      }}
    >
      {itemCount}
    </span>
  );
};
