import { LOCAL_COLORS } from 'styles/constants';

const accessibleColorPairs = {
  [LOCAL_COLORS.surfaceLight]: LOCAL_COLORS.textDefault,
  [LOCAL_COLORS.surfaceWhite]: LOCAL_COLORS.textDefault,
  [LOCAL_COLORS.surfaceDarkBold]: LOCAL_COLORS.textInverse,
  [LOCAL_COLORS.surfaceBrandBold]: LOCAL_COLORS.textInverse,
  [LOCAL_COLORS.surfaceBrandBolder]: LOCAL_COLORS.textInverse,
  [LOCAL_COLORS.corePurple600]: LOCAL_COLORS.textInverse,
  [LOCAL_COLORS.surfaceBrandLight]: LOCAL_COLORS.textDefault,
  [LOCAL_COLORS.surfaceBrandLightest]: LOCAL_COLORS.textDefault,
};

const getContrastingColor = (color: string) => {
  return accessibleColorPairs[color] || LOCAL_COLORS.textDefault;
};

export default getContrastingColor;
