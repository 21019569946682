import { useCallback, useState } from 'react';

import { useTrackPage } from './useTrackPage';
import { utmParams } from './utmParams';
import { useOnPageLoad } from './useOnPageLoad';
import { reportReferrerUtm } from '../reportReferrerUtm';

export function useAnalyticsInitialize() {
  const [shouldAddGtmScript, setShouldAddGtmScript] = useState(false);
  const trackPage = useTrackPage();

  const initialize = useCallback(() => {
    setShouldAddGtmScript(true);
    reportReferrerUtm();
    trackPage();
  }, [trackPage]);

  const initializeOnEvent = (events: string[]) => {
    const initializeAndCleanup = () => {
      initialize();
      cleanup();
    };

    const eventRemovers = events.map((event) => {
      document.addEventListener(event, initializeAndCleanup);
      return () => document.removeEventListener(event, initializeAndCleanup);
    });

    const cleanup = () => eventRemovers.forEach((remover) => remover());
  };

  const onPageLoad = useCallback(() => {
    const isSnapchat = utmParams()?.source === 'snapchat';
    if (isSnapchat) {
      // Mousemove for desktop, touchstart for mobile / touch screen devices
      initializeOnEvent(['mousemove', 'touchstart']);
      return;
    }

    if (document.visibilityState === 'hidden') {
      initializeOnEvent(['visibilitychange']);
      return;
    }

    initialize();
  }, [initialize]);

  useOnPageLoad(onPageLoad);

  return shouldAddGtmScript;
}
