import { css } from '@emotion/react';

import { CartIcon, ItemCountBubble } from '.';

export const CartButton = ({
  itemCount,
  onClick,
}: {
  itemCount: number;
  onClick: () => void;
}) => (
  <button
    aria-label="Open shopping cart"
    className="relative"
    css={css`
      cursor: pointer;

      &:focus-visible {
        --tw-ring-offset-shadow: none;
        --tw-ring-shadow: none;
        --tw-ring-color: none;
        --tw-ring-offset-width: 0;
        --tw-ring-offset-color: none;
        box-shadow: none;
      }
    `}
    onClick={onClick}
    type="button"
  >
    <CartIcon />
    <ItemCountBubble itemCount={itemCount} />
  </button>
);
