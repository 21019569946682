type GetMetadataConfig = {
  ogTitle: string;
  ogDescription: string;
  ogImage: string;
  ogUrl: string;
};

type MetaType = {
  name?: string;
  content: string;
  'http-equiv'?: string;
  property?: string;
};

const META: MetaType[] = [
  {
    name: 'apple-mobile-web-app-capable',
    content: 'yes',
  },
  {
    'http-equiv': 'Content-Type',
    content: 'text/html;charset=utf-8',
  },
];

const getTwitterMeta = ({
  ogImage,
  ogTitle,
  ogDescription,
}: GetMetadataConfig): MetaType[] => [
  { name: 'twitter:card', content: 'summary_large_image' },
  { name: 'twitter:site', content: '@CurologyUSA' },
  {
    name: 'twitter:title',
    content: ogTitle,
  },
  {
    name: 'twitter:description',
    content: ogDescription,
  },
  { name: 'twitter:image', content: ogImage },
];

const getFacebookMeta = ({
  ogImage,
  ogTitle,
  ogDescription,
  ogUrl,
}: GetMetadataConfig): MetaType[] => [
  { property: 'og:type', content: 'website' },
  { property: 'og:url', content: ogUrl },
  {
    property: 'og:title',
    content: ogTitle,
  },
  {
    property: 'og:description',
    content: ogDescription,
  },
  { property: 'og:site_name', content: 'Curology' },
  { property: 'og:image', content: ogImage },
  { property: 'og:image_url', content: ogImage },
  { property: 'og:image:secure_url', content: ogImage },
  { property: 'og:image:type', content: 'image/jpg' },
  { property: 'og:video:width', content: '640' },
  { property: 'og:video:height', content: '360' },
  { property: 'fb:admins', content: '2209811' },
  { property: 'fb:app_id', content: '1067184460064927' },
];

export const getMetadata = (config: GetMetadataConfig): MetaType[] => [
  ...META,
  ...getTwitterMeta(config),
  ...getFacebookMeta(config),
];
