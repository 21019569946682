import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

export const squeezeButtonStyles = css`
  border-color: ${LOCAL_COLORS.surfaceBrandLight} !important;
  margin-right: ${SPACER.medium};
  width: 7.5rem;
  line-height: 1.3;
`;

export const UnstyledButton = styled.button`
  border: unset;
  background-color: transparent;
  text-align: unset;
  padding: unset;
  margin: unset;
  cursor: pointer;
`;
