import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import type { ThemeType } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

export const freeGiftBannerStyles = (theme: ThemeType) => css`
  background: ${LOCAL_COLORS.surfaceOrange};
  color: ${theme.COLORS.primary};
  display: flex;
  font-size: 0.8rem;
  font-weight: normal;
  gap: ${SPACER.xsmall};
  justify-content: center;
  letter-spacing: initial;
  padding: 0.75rem ${SPACER.medium};
  text-transform: initial;
  z-index: 0;

  & > svg {
    position: relative;
    top: 0.1rem;
  }

  ${MEDIA_QUERIES.mdUp} {
    font-size: 0.875rem;
    gap: ${SPACER.small};

    & > svg {
      position: relative;
      top: 0.15rem;
    }
  }
`;
