import React, { useState } from 'react';
import { ONE_NAV_TEST_PRODUCT_CATEGORIES_CONTENT } from '../../../content';
import { ProductCategory } from './ProductCategory';
import {
  DrawersContainer,
  ProductCategoriesContainer,
  ProductsContainer,
  Separator,
} from './ProductDrawers.style';
import { LinksContainer } from './LinksContainer';

export const ProductDrawers = () => {
  const [activeCategory, setActiveCategory] = useState<string>(
    ONE_NAV_TEST_PRODUCT_CATEGORIES_CONTENT[0].category,
  );

  const handleMouseEnter = (category: string) => {
    setActiveCategory(category);
  };

  return (
    <DrawersContainer>
      <ProductCategoriesContainer>
        {ONE_NAV_TEST_PRODUCT_CATEGORIES_CONTENT.map(({ category }) => (
          <ProductCategory
            key={category}
            categoryName={category}
            handleMouseEnter={() => handleMouseEnter(category)}
            handleClick={() => handleMouseEnter(category)}
            isActive={activeCategory === category}
          />
        ))}
      </ProductCategoriesContainer>
      <Separator />
      <ProductsContainer>
        {ONE_NAV_TEST_PRODUCT_CATEGORIES_CONTENT.map(({ category, links }) => (
          <LinksContainer
            key={category}
            categoryName={category}
            links={links}
            isActive={activeCategory === category}
          />
        ))}
      </ProductsContainer>
    </DrawersContainer>
  );
};
