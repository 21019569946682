import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import { OffClickWrapper } from 'radiance-ui';
import { CrossIcon } from 'radiance-ui/lib/icons';

import { Overlay, ModalContainer, CloseIconContainer } from './Modal.style';
import { SerializedStyles, useTheme } from '@emotion/react';
import { ThemeType } from 'radiance-ui/lib/constants';

const REACT_PORTAL_SECTION_ID = 'reactPortalSection';

const getHtmlNode = () => document.querySelector('html') ?? document.body;
const getDomNode = () =>
  document.getElementById(REACT_PORTAL_SECTION_ID) ?? document.body;

const closeContext = React.createContext<() => void>(() => {
  // Do nothing
});

export const useModalClose = () => React.useContext(closeContext);

export interface ModalProps {
  children: ReactNode;
  onClose?: () => void;
  renderCloseButton?: boolean;
  backgroundColor?: string;
  styles?: (theme: ThemeType) => SerializedStyles | null;
}

export const Modal = ({
  children,
  onClose,
  renderCloseButton = true,
  backgroundColor,
  styles,
}: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const theme = useTheme();

  const htmlNode = useRef<HTMLElement>(getHtmlNode());
  const domNode = useRef<HTMLElement>(getDomNode());

  const handleCloseIntent = () => {
    if (isClosing) {
      return;
    }

    setIsClosing(true);
    htmlNode.current.classList.remove('no-scroll');

    if (onClose) {
      setTimeout(onClose, 350);
    }
  };

  useEffect(() => {
    htmlNode.current = getHtmlNode();
    domNode.current = getDomNode();

    htmlNode.current.classList.add('no-scroll');

    return () => {
      htmlNode.current.classList.remove('no-scroll');
    };
  }, []);

  return ReactDOM.createPortal(
    <Transition
      timeout={{
        appear: 0,
        enter: 0,
        exit: 350,
      }}
      in={!isClosing}
      unmountOnExit
      appear
    >
      {(transitionState): JSX.Element => (
        <React.Fragment>
          <Overlay className={transitionState}>
            <ModalContainer
              className={transitionState}
              backgroundColor={backgroundColor}
              css={styles ? styles(theme) : {}}
            >
              <OffClickWrapper onOffClick={handleCloseIntent}>
                {renderCloseButton && (
                  <CloseIconContainer
                    onClick={handleCloseIntent}
                    backgroundColor={backgroundColor}
                  >
                    <CrossIcon />
                  </CloseIconContainer>
                )}
                <closeContext.Provider value={handleCloseIntent}>
                  {children}
                </closeContext.Provider>
              </OffClickWrapper>
            </ModalContainer>
          </Overlay>
        </React.Fragment>
      )}
    </Transition>,
    domNode.current,
  );
};
