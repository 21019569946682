import React from 'react';

import COLORS from 'styles/constants/colors';

export const CartIcon = ({
  width = 22,
  height = 22,
  fill = COLORS.surfaceDarkBold as `#${string}`,
}: {
  width?: number;
  height?: number;
  fill?: `#${string}`;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 6.5C5 3.18629 7.68629 0.5 11 0.5C14.3137 0.5 17 3.18629 17 6.5H20C20.8284 6.5 21.5 7.17157 21.5 8V21.5C21.5 22.3284 20.8284 23 20 23H2C1.17157 23 0.5 22.3284 0.5 21.5V8C0.5 7.17157 1.17157 6.5 2 6.5H5ZM8 6.5C8 4.84315 9.34315 3.5 11 3.5C12.6569 3.5 14 4.84315 14 6.5H8Z"
      fill={fill}
    />
  </svg>
);
