import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const LinksContainer = styled.div`
  width: 100%;
  padding: ${SPACER.large};
  display: flex;
  flex-direction: column;
  ${MEDIA_QUERIES.smUp} {
    width: 64%;
    padding: ${SPACER.large} ${SPACER.x5large} ${SPACER.xlarge}
      ${SPACER.x2large};
  }
`;

export const TitleContainer = styled.div`
  color: ${LOCAL_COLORS.surfaceBrandBold};
  font-size: 40px;
  font-weight: 700;
  line-height: 110%;
`;
