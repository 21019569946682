import type { ReactNode } from 'react';
import React from 'react';
import { LOCAL_COLORS } from 'styles/constants';
import { Icon, IconName, IconSize } from '@curology/ui-components-web/react';
import { Alert } from 'radiance-ui';
import { css } from '@emotion/react';

export const DetailsWrapper = ({ children }: { children: ReactNode }) => (
  <div
    className="flex flex-col gap-4 w-full justify-center border-t border-solid"
    style={{ display: 'flex', borderColor: '#EDEDF0' }}
  >
    {children}
  </div>
);

export const RxNotice = () => (
  <div
    className="flex gap-2 py-7 px-6"
    style={{ backgroundColor: LOCAL_COLORS.corePurple50 }}
  >
    <div className="flex items-center p-5">
      <Icon size={IconSize.Medium} name={IconName.Treatment} />
    </div>
    <div style={{ lineHeight: '1.32rem' }}>
      Your cart contains an Rx product, so we'll check your eligibility and then
      ask questions after checkout so your provider can create your formula.
    </div>
  </div>
);

export const Subtotal = ({ value }: { value: number }) => (
  <div className="flex justify-between items-center w-full pt-4">
    <h3> Subtotal </h3>
    <p className="text-2xl font-bold" id="cart-sidebar-subtotal">
      ${value.toFixed(2)}
    </p>
  </div>
);

export const alertStyles = css`
  background-color: ${LOCAL_COLORS.errorAlertBackground};
  font-weight: bold;
  width: 100%;

  div {
    color: ${LOCAL_COLORS.errorAlertText};
  }

  div > div:first-child {
    display: none;
  }
`;

export const AlertWrapper = ({ alertText }: { alertText: string }) => (
  <Alert content={alertText} duration="30" type="danger" css={alertStyles} />
);
