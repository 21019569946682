import React, { ReactNode, useState } from 'react';
import { Link } from 'gatsby';

import { NAV_SKINCARE_101_LINK_CONTENT } from '../content';
import {
  ColumnContainer,
  LinkHeader,
  LinksContainer,
  linkStyles,
  NestedAccordionContainer,
} from './Skincare101Links.style';

import { NestableAccordion } from './NestableAccordion';

interface Skincare101LinksProps {
  isOpen?: boolean;
  isMobile?: boolean;
  setIsModalOpen?: (value: boolean) => void;
}

interface LinkType {
  label: string;
  htmlLabel?: ReactNode;
  href: string;
}

export const Skincare101Links = ({
  isOpen = true,
  setIsModalOpen,
  isMobile = false,
}: Skincare101LinksProps) => {
  const ServiceLinkAccordion = ({
    category,
    links,
  }: {
    category: string;
    links: LinkType[];
  }) => {
    const [isCategoryOpen, setIsCategoryOpen] = useState(false);

    return (
      <NestedAccordionContainer>
        <NestableAccordion
          title={<LinkHeader>{category}</LinkHeader>}
          key={category}
          isOpen={isCategoryOpen}
          onClick={() => setIsCategoryOpen(!isCategoryOpen)}
        >
          {links.map((link) => (
            <Link
              to={link.href}
              tabIndex={isOpen ? 0 : -1}
              key={link.label}
              css={linkStyles}
              onClick={() => setIsModalOpen?.(false)}
            >
              {link.label}
            </Link>
          ))}
        </NestableAccordion>
      </NestedAccordionContainer>
    );
  };

  return (
    <LinksContainer>
      {NAV_SKINCARE_101_LINK_CONTENT.map(({ category, links }) => (
        <ColumnContainer key={category}>
          {isMobile ? (
            <ServiceLinkAccordion category={category} links={links} />
          ) : (
            <>
              <LinkHeader>{category}</LinkHeader>
              {links.map((link) => (
                <Link
                  to={link.href}
                  tabIndex={isOpen ? 0 : -1}
                  key={link.label}
                  css={linkStyles}
                  onClick={() => setIsModalOpen?.(false)}
                >
                  {link.label}
                </Link>
              ))}
            </>
          )}
        </ColumnContainer>
      ))}
    </LinksContainer>
  );
};
