import React from 'react';

import { navExternalWithQueryString } from 'utils/marketingSafeDomain';

import type { ProductCtaContentType } from '../content/index';
import * as Style from './ProductCTA.style';

interface ProductCTAPropsType {
  trackCTAClick: () => void;
  content: ProductCtaContentType;
}

export const ProductCTA = ({ trackCTAClick, content }: ProductCTAPropsType) => {
  const useTitleAsElement = typeof content.title !== 'string';

  const handleLinkClick = (href: string) => (event: React.SyntheticEvent) => {
    event.preventDefault();
    trackCTAClick();
    navExternalWithQueryString(href);
  };

  return (
    <Style.ProductCTAContainer enlarged={!content.description}>
      <Style.ProductCTAImageContainer>
        <a
          href={content.url}
          onClick={handleLinkClick(content.url)}
          css={Style.inlineBlock}
        >
          {content.image}
        </a>
      </Style.ProductCTAImageContainer>
      <Style.ProductCTACopyContainer withDescription={!!content.description}>
        {useTitleAsElement ? content.title : <h3>{content.title}</h3>}
        <Style.ProductCTADescription>
          {content.description}
        </Style.ProductCTADescription>
        <Style.ProductCTAButton
          isExternal
          href={content.url}
          trackClick={trackCTAClick}
          useSqueeze
        >
          {content.ctaText}
        </Style.ProductCTAButton>
      </Style.ProductCTACopyContainer>
    </Style.ProductCTAContainer>
  );
};
