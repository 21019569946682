import React from 'react';
import { Chip } from 'radiance-ui';
import { v4 as uuid } from 'uuid';

import { navExternalWithQueryString } from 'utils/marketingSafeDomain';

import {
  linkContainer,
  OuterContainer,
  ChipContainer,
  ImageContainer,
  TagsContainer,
  HoverImageContainer,
  titleStyles,
  bodyStyles,
  FeatureListItem,
} from './FlexibleCard.style';

export interface FlexibleCardProps {
  /** Max Character Count: 60, Preferred: 35 */
  title: string;
  body: React.ReactNode;
  subtitle?: string;
  featureList?: {
    symbol?: React.ReactNode;
    listItems: string[];
  };
  /** Determines what the link should be for this card. For internal links, use the slug i.e. curology.com/slug-value/. For external, include the full URL. */
  link?: string;
  media: React.ReactNode;
  /** A link is required to use this feature */
  hoverMedia?: React.ReactNode;
  /** Max Character Count: 15, Preferred: <10 */
  mediaTag?: string;
  /** Max Character Count: 15, Preferred: <10, 2 Tags Max */
  tags?: string[];
}

export const FlexibleCard = ({
  mediaTag,
  media,
  hoverMedia,
  title,
  subtitle,
  body,
  featureList,
  link,
  tags,
}: FlexibleCardProps) => {
  const handleLinkClick = (href: string) => (event: React.SyntheticEvent) => {
    /**
     * If navigating to ecommerce shop, we do want to use our navExternalWithQueryString utility
     */
    if (/^([a-z0-9-:/]{0,})(shop)?\.?curology.com([a-z0-9-/]{0,})/.test(href)) {
      event.preventDefault();
      navExternalWithQueryString(href);
    }
  };

  const renderCardContent = () => (
    <React.Fragment>
      <ImageContainer>
        {mediaTag && (
          <ChipContainer>
            <Chip text={mediaTag} />
          </ChipContainer>
        )}
        {media}
        {link && hoverMedia && (
          <HoverImageContainer>{hoverMedia}</HoverImageContainer>
        )}
      </ImageContainer>
      <div>
        {tags && (
          <TagsContainer>
            {tags.map((tag) => (
              <Chip isLowContrast text={tag} key={tag} />
            ))}
          </TagsContainer>
        )}
        {title && (
          <p css={titleStyles}>
            <strong>{title}</strong>
          </p>
        )}
        {subtitle && <p className="smallP">{subtitle}</p>}
        <div className="smallP" css={bodyStyles}>
          {body}
        </div>
        {featureList &&
          featureList.listItems.map((listItem) => (
            <FeatureListItem key={uuid()}>
              {featureList.symbol}
              <p className="smallP">{listItem}</p>
            </FeatureListItem>
          ))}
      </div>
    </React.Fragment>
  );

  return link ? (
    <a href={link} css={linkContainer} onClick={handleLinkClick(link)}>
      {renderCardContent()}
    </a>
  ) : (
    <OuterContainer>{renderCardContent()}</OuterContainer>
  );
};
