import React from 'react';
import { LinkType } from '../../../content';
import { LinksContainer as StyledLinksContainer } from './ProductDrawers.style';

interface LinksContainerProps {
  categoryName: string;
  links: LinkType[];
  isActive: boolean; // New prop to control visibility
}

export const LinksContainer = ({
  categoryName,
  links,
  isActive,
}: LinksContainerProps) => {
  const linkElements = links.map((link) => (
    <a key={link.label} href={link.href}>
      {link.label}
    </a>
  ));

  return (
    <StyledLinksContainer
      id={categoryName}
      className={isActive ? 'isActive' : ''}
    >
      {linkElements}
    </StyledLinksContainer>
  );
};
