import React from 'react';

import { CTA_SHOP_ROUTINE_ESSENTIALS, EMPTY_CART_MESSAGE } from 'consts/copy';
import eCommerceUrls from 'consts/eCommerceUrls';
import { EVENTS } from 'consts/metricsTrackingEvents';

import { CartHeader, EcommerceCTA, SkinQuizCard } from '..';
import {
  ButtonWrapper,
  CTAAligner,
  CartContentWrapper,
  EmptyCartBackgroundImage,
  EmptyCartMessage,
  HeaderAligner,
} from './EmptyCart.style';

export const EmptyCart = ({
  onClose,
  isOpening,
}: {
  onClose: () => void;
  isOpening: boolean;
}) => (
  <React.Fragment>
    <EmptyCartBackgroundImage />
    <CartContentWrapper>
      <HeaderAligner>
        <CartHeader onClose={onClose} isOpening={isOpening} />
        <EmptyCartMessage>{EMPTY_CART_MESSAGE}</EmptyCartMessage>
      </HeaderAligner>
      <CTAAligner>
        <SkinQuizCard />
        <ButtonWrapper>
          <EcommerceCTA
            href={eCommerceUrls.main}
            trackingEvent={EVENTS.shopCtaClicked}
          >
            {CTA_SHOP_ROUTINE_ESSENTIALS}
          </EcommerceCTA>
        </ButtonWrapper>
      </CTAAligner>
    </CartContentWrapper>
  </React.Fragment>
);
