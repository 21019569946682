/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import type { ReactNode } from 'react';
import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  createContext,
} from 'react';

import type {
  Cart,
  CartResource,
  EcommerceUtils,
  ItemUpdateOptions,
} from './cart/types';
import { fromCartResource, setCartCookie } from './cart/utils';
import Cookies from 'js-cookie';

export const ECommerceContext = createContext<EcommerceUtils | undefined>(
  undefined,
);

const emptyCart = {
  id: '',
  itemCount: 0,
  items: [],
  total: 0,
  containsRx: false,
  checkoutUrl: '',
};

export const ECommerceProvider = ({ children }: { children: ReactNode }) => {
  const [cart, setCart] = useState<Cart>(emptyCart);
  const baseEndpoint = `${process.env.POCKETDERM_URL}/${process.env.CART_ENDPOINT}`;
  const [hasAchievedFreeShipping, setHasAchievedFreeShipping] = useState(false);
  const [openCart, setOpenCart] = useState(false);

  const setCartFromResponse = useCallback(
    (response: Response) => {
      if (response.status === 200) {
        response.json<{ data: CartResource }>().then(({ data }) => {
          setCart(fromCartResource(data));
          setCartCookie(data.id, window.location.host);
        });
      } else {
        setCart(emptyCart);
      }
    },
    [setCart, fromCartResource],
  );

  const updateItem = (options: ItemUpdateOptions) => {
    if (options.quantity <= 0) {
      return removeItem(options.itemId);
    }

    return fetch(`${baseEndpoint}/${cart?.id}/items/${options.itemId}`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productId: options.productId,
        quantity: options.quantity,
      }),
    }).then(setCartFromResponse);
  };

  const removeItem = (itemId: string) => {
    return fetch(`${baseEndpoint}/${cart?.id}/items/${itemId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(setCartFromResponse);
  };

  const slideOpenCart = () => {
    setOpenCart(true);

    setTimeout(() => {
      setOpenCart(false);
    }, 1000);
  };

  const showCartAlert = (alert: string) => {
    setCart({
      cartAlert: alert,
      ...cart,
    });

    setTimeout(() => {
      setCart((cart) => {
        const { cartAlert, ...rest } = cart;
        return rest;
      });
    }, 3000);
  };

  const addToCart = (netsuiteId: string, showAlert?: string) => {
    return fetch(`${baseEndpoint}/add-to-cart`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        netsuiteId: netsuiteId,
      }),
    })
      .then(setCartFromResponse)
      .then(() => {
        if (showAlert) {
          showCartAlert(showAlert);
        }

        slideOpenCart();
      });
  };

  const addRxProduct = (netsuiteId: string) => {
    if (cart.containsRx) {
      const rxProduct = cart.items.find(
        (thisProduct) => thisProduct.isRx === true,
      );

      if (rxProduct?.id && rxProduct?.sku !== netsuiteId) {
        slideOpenCart();
        showCartAlert('Right now you can only buy one Rx product at a time');

        return;
      } else {
        slideOpenCart();
        return;
      }
    }

    return addToCart(netsuiteId);
  };

  useEffect(() => {
    if (Cookies.get('cartId')) {
      fetch(baseEndpoint, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      })
        .then(setCartFromResponse)
        .catch(() => setCart(emptyCart));
    } else {
      setCart(emptyCart);
    }
  }, []);

  const eCommerceUtils = useMemo(() => {
    return {
      cart,
      openCart,
      removeItem,
      updateItem,
      addRxProduct,
      hasAchievedFreeShipping,
      setHasAchievedFreeShipping: () => setHasAchievedFreeShipping(true),
    };
  }, [
    hasAchievedFreeShipping,
    setHasAchievedFreeShipping,
    removeItem,
    updateItem,
    addRxProduct,
  ]);

  return (
    <ECommerceContext.Provider value={eCommerceUtils}>
      {children}
    </ECommerceContext.Provider>
  );
};
