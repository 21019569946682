import styled from '@emotion/styled';
import { SPACER } from 'radiance-ui/lib/constants';

export const NestedProductCTAsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductCTASection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${SPACER.xlarge};
`;
