import { useCallback } from 'react';
import { useCanonicalHistory } from 'components/global/CanonicalHistoryProvider';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';
import { getPageDefaults } from './analyticsHelpers';

export const useTrackPage = () => {
  const canonicalHistoryContext = useCanonicalHistory();

  return useCallback(() => {
    const { currentCanonicalUrl, previousCanonicalUrl } =
      canonicalHistoryContext;

    trackEvent(EVENTS.loadedAPage, {
      ...getPageDefaults(currentCanonicalUrl),
      immediate_referrer: previousCanonicalUrl,
    });
  }, [canonicalHistoryContext]);
};
