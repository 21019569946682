import CONFIG from 'consts/config';

const APP_PATHS = {
  login: '/auth/login',
  welcome: '/auth/welcome',
  register: '/auth/welcome/register',
  requestMedicalRecord: '/app/dashboard/request-medical-record',
  dashboardPlans: '/dashboard/account/plan',
  trackingOptOut: '/user/tracking/opt-out',
  findYourTreatment: '/sign-up/find-your-treatment',
  signUpSkin: '/sign-up/start/skin',
  signUpHair: '/sign-up/start/hair',
  api: {
    promotions: '/api/promotions',
    userReferrerUtm: '/api/user-referrer-utm',
  },
};

/**
 * This updates the path by prefixing the BASE_URL
 */
const convertPathToFullUrl = (paths) =>
  Object.entries(paths).reduce(
    (obj, [key, val]) => ({
      ...obj,
      [key]:
        typeof val === 'object'
          ? convertPathToFullUrl(val)
          : `${CONFIG.APP_URL}${val}`,
    }),
    {},
  );

export default { ...convertPathToFullUrl(APP_PATHS) };
