import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { desktopOnly, mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const ModalPopContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerBox = styled.div`
  position: relative;
  background: #ffffff;

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    width: 800px;
    min-height: 500px;
  }
`;

export const DesktopImageContainer = styled.div`
  ${desktopOnly()}
  max-width: 400px;

  & > div {
    height: 100%;
  }
`;

export const MobileImageContainer = styled.div`
  ${mobileOnly()}
  position: relative;
  z-index: 0;
`;

export const ModalContent = styled.div`
  padding: ${SPACER.xlarge} ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    max-width: 400px;
    padding: ${SPACER.x5large} ${SPACER.x2large} ${SPACER.x2large};
  }
`;

export const ModalHeader = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.64px;
  margin-bottom: ${SPACER.medium};

  &:not(.smallHeadline) {
    ${MEDIA_QUERIES.mdUp} {
      font-size: 52px;
    }
  }
`;

export const ModalBody = styled.p`
  font-size: 16px;
  line-height: 132%;
  margin-bottom: ${SPACER.xlarge};

  &:not(.newYear) {
    ${MEDIA_QUERIES.mdUp} {
      font-size: 24px;
      line-height: 140%;
    }
  }
`;

export const ctaStyles = css`
  grid-template-rows: unset;
  margin-bottom: ${SPACER.x2large};

  button {
    font-size: 16px;
    line-height: 132%;
    letter-spacing: -0.32px;
  }

  ${MEDIA_QUERIES.mdUp} {
    width: fit-content;
    margin-bottom: ${SPACER.x2large};
  }

  &.freeGift {
    width: 100%;
  }
`;

export const Disclaimer = styled.small`
  font-size: 12px;
  line-height: 140%;

  &.holiday {
    ${MEDIA_QUERIES.mdUp} {
      position: absolute;
      z-index: 1;
      max-width: 700px;
    }
  }
`;

export const closeButtonStyles = css`
  position: absolute;
  background-color: #ffffff;
  top: 20px;
  right: 20px;
  padding: 12px;
  border-radius: 999px;
  z-index: 1;

  ${MEDIA_QUERIES.mdUp} {
    top: ${SPACER.large};
    right: ${SPACER.large};
  }
`;
