import * as urlParser from 'component-url';
import { dropTrailingSlash } from '@curology-engineering/great-gatsby/utils';

export const convertUrlToPath = (url: string) => {
  const parsedUrl = urlParser.parse(url);

  return parsedUrl.pathname.length === 1
    ? parsedUrl.pathname
    : dropTrailingSlash(parsedUrl.pathname);
};

export const getPageDefaults = (currentCanonicalUrl: string) => {
  return {
    path: convertUrlToPath(currentCanonicalUrl),
    referrer: document.referrer,
    search: window.location.search,
    url: `${currentCanonicalUrl}${window.location.search}`,
    host: window.location.hostname,
    userAgent: window?.navigator.userAgent,
  };
};
