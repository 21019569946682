import React, { useEffect, useState } from 'react';
import { OffClickWrapper } from 'radiance-ui';

import { CART_SLIDE_DURATION_MS } from './shared/animations';
import { CartContents } from '..';
import { ActivatedBackground, CartSidebar } from './components';

export const CartModal = ({ onClose }: { onClose: () => void }) => {
  const [isOpening, setIsOpening] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const openingHandler = setTimeout(() => {
      setIsOpening(false);
    }, CART_SLIDE_DURATION_MS);

    return () => {
      clearTimeout(openingHandler);
    };
  }, []);

  const closeCart = () => {
    setIsClosing(true);
    setTimeout(onClose, CART_SLIDE_DURATION_MS);
  };

  return (
    <ActivatedBackground isClosing={isClosing}>
      <OffClickWrapper onOffClick={closeCart}>
        <CartSidebar isClosing={isClosing}>
          <CartContents onClose={closeCart} isOpening={isOpening} />
        </CartSidebar>
      </OffClickWrapper>
    </ActivatedBackground>
  );
};
