import { css } from '@emotion/react';

export const wiggleAnimation = css`
  @keyframes desktopWiggle {
    1.25% {
      transform: translateX(-20px) rotate(-7deg);
    }
    3.75% {
      transform: translateX(20px) rotate(7deg);
    }
    5%,
    100% {
      transform: translateX(0) rotate(0deg);
    }
  }

  @keyframes mobileWiggle {
    1.25% {
      transform: translateX(-5px);
    }
    3.75% {
      transform: translateX(5px);
    }
    5%,
    100% {
      transform: translateX(0);
    }
  }
`;
