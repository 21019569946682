import React from 'react';

import * as Style from './ServicesModalDesktop.style';
import { NAV_SERVICES_CONTENT } from '../../../content';
import { ServiceLink } from '../../ServiceLink';

export const ServicesModalDesktop = ({ module }: { module: string }) => (
  <Style.LinksContainer>
    <Style.TitleContainer>{NAV_SERVICES_CONTENT.title}</Style.TitleContainer>
    {NAV_SERVICES_CONTENT.services.map((service, index) => (
      <ServiceLink service={service} key={index} module={module} />
    ))}
  </Style.LinksContainer>
);
