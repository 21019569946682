import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import APP_URLS from 'consts/appUrls';
import ECOMMERCE_URLS from 'consts/eCommerceUrls';

export interface ProductCtaContentType {
  url: string;
  title: JSX.Element | string;
  ctaText: string;
  image: JSX.Element;
  description?: string;
}

export const RX_PRODUCT_WITH_DESCRIPTION: ProductCtaContentType = {
  url: APP_URLS.welcome as string,
  title: 'Try prescription skincare',
  ctaText: 'Start skin quiz',
  description:
    'Get your personalized Rx formula—prescribed for you by a dermatology provider.',
  image: (
    <StaticImage
      src="../images/customFormula.png"
      alt="Link to sign up for Curology"
      width={160}
    />
  ),
};

export const ECOMMERCE_PRODUCT: ProductCtaContentType = {
  url: ECOMMERCE_URLS.main,
  title: 'Get your routine essentials',
  ctaText: 'Shop products',
  description:
    'Complete your skincare routine with dermatologist-designed essentials.',
  image: (
    <StaticImage
      src="../images/routineEssentials.png"
      alt="Link to shop.curology.com"
      width={160}
    />
  ),
};
