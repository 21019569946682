import { ModalEntry } from 'schema/modalSchema';
import React from 'react';
import {
  ContentfulModalBox,
  ctaStyles,
  Title,
  Body,
  MainBox,
  desktopImageStyles,
} from './ContentfulModal.style';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { CtaButton } from '../CtaButton';
import { SmartImage } from '../SmartImage';
import { mobileOnly } from 'styles/mixins/displayMobileDesktop';

export const ContentfulModal = ({
  title,
  body,
  ctaButton,
  desktopImage,
  mobileImage,
}: ModalEntry) => {
  return (
    <ContentfulModalBox>
      {desktopImage && (
        <SmartImage css={desktopImageStyles} {...desktopImage} />
      )}
      {mobileImage && <SmartImage css={mobileOnly()} {...mobileImage} />}
      <MainBox hasImage={!!desktopImage}>
        {title && <Title>{title}</Title>}
        {body && <Body>{documentToReactComponents(body)}</Body>}
        {ctaButton && (
          <CtaButton {...ctaButton} small={false} css={ctaStyles} />
        )}
      </MainBox>
    </ContentfulModalBox>
  );
};
