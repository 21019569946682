import React from 'react';

import {
  trackSignupCTAClick,
  trackECommerceCTAClick,
} from 'utils/metricsTracking';
import { MODULES } from 'consts/modules';

import * as Style from './ProductCTAs.style';
import { RX_PRODUCT_WITH_DESCRIPTION, ECOMMERCE_PRODUCT } from './content';
import { ProductCTA } from './components';

export const ProductCTAs = () => {
  return (
    <Style.NestedProductCTAsContainer>
      <Style.ProductCTASection>
        <ProductCTA
          trackCTAClick={() => trackSignupCTAClick(MODULES.navigation)}
          content={RX_PRODUCT_WITH_DESCRIPTION}
        />
      </Style.ProductCTASection>
      <Style.ProductCTASection>
        <ProductCTA
          trackCTAClick={() => trackECommerceCTAClick(MODULES.navigation)}
          content={ECOMMERCE_PRODUCT}
        />
      </Style.ProductCTASection>
    </Style.NestedProductCTAsContainer>
  );
};
