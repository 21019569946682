import type { SEOPropsType } from '..';

const description =
  'Start your teen’s journey to clear skin with personalized skincare, one-on-one guidance, and a 30-day trial.';
const ogTitle =
  'Custom skincare for your teen delivered to your door - Curology';

export const SEO_MOMS_OF_TEENS_CONTENT: SEOPropsType = {
  title: 'Personalized Acne Treatment for Teens - Curology',
  ogTitle,
  description,
};

export const SEO_MOMS_OF_TEENS_MARKETING_SAFE_CONTENT: SEOPropsType = {
  title: 'Personalized Skincare Treatment for Teens - Curology',
  ogTitle,
  description,
};
