import React from 'react';

import { CartActions } from './components';
import { CartWrapper } from './styles/styles';

export const ShoppingCart = ({ onOpen }: { onOpen?: () => void }) => (
  <CartWrapper>
    <CartActions onOpen={onOpen} />
  </CartWrapper>
);
