import { EVENTS } from 'consts/metricsTrackingEvents';
import { CrossIcon } from 'radiance-ui/lib/icons';
import React, { useCallback } from 'react';

import type { PhysicalItem } from 'utils/eCommerce/cart/types';
import useCart from 'utils/eCommerce/cart/useCart';
import { trackEvent } from 'utils/metricsTracking';

export const RemoveItemButton = ({
  item,
  onRemovalStarted,
  onRemovalFinished,
}: {
  item: PhysicalItem;
  onRemovalStarted?: () => void;
  onRemovalFinished?: () => void;
}) => {
  const { removeItem } = useCart();

  const remove = useCallback(() => {
    onRemovalStarted?.();
    removeItem(item.id)
      .then(onRemovalFinished)
      .catch(() => onRemovalFinished?.());

    if (item.isRx) trackEvent(EVENTS.removeRxProduct);
  }, [onRemovalStarted, removeItem]);

  return (
    <button
      className="remove-item hover:underline py-1 px-2"
      type="button"
      aria-label={`Remove ${item.name} from cart`}
      onClick={remove}
    >
      <CrossIcon height={12} width={12} />
    </button>
  );
};
