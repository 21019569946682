import React from 'react';
import { css } from '@emotion/react';

import { CART_SLIDE_DURATION_MS, fadeIn } from '../shared/animations';
import type { ClosableComponentProps } from '../shared/types';

const constantStyles = css`
  background-color: rgba(0, 0, 0, 0.51);
  animation: ${fadeIn} ${CART_SLIDE_DURATION_MS}ms forwards;
  z-index: 1000002; // must beat shopping cart button
`;

export const ActivatedBackground = ({
  isClosing,
  children,
}: ClosableComponentProps) => {
  const rules = isClosing
    ? css`
        ${constantStyles}
        background-color: rgba(0, 0, 0, 0);
      `
    : constantStyles;

  return (
    <div
      className="fixed inset-0 w-full h-full transition-all overflow-hidden"
      css={rules}
    >
      {children}
    </div>
  );
};
