import { transparentize } from 'radiance-ui/lib/utils';

const boxShadowColor = '#2d2d30';

export default {
  nav: `0 2px 10px 0 ${transparentize(boxShadowColor, 0.06)}`,
  communityCards: '0 40px 40px 0 rgba(0, 0, 0, 0.05)',
  communitySocialPostCard: '0 5px 40px 0 rgba(0, 0, 0, 0.1)',
  customizedCard: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
  textShadow: `0 2px 5px ${transparentize(boxShadowColor, 0.15)}`,
  providerQuoteCard: '0px 12px 20px rgba(51, 46, 84, 0.1)',
  customCard: `0 10px 40px 0 ${transparentize(boxShadowColor, 0.13)}`,
};
