import type { ReactElement, ReactNode } from 'react';
import React, { useState, useEffect } from 'react';
import { CrossIcon } from 'radiance-ui/lib/icons';
import { useTheme } from '@emotion/react';

import cookie from 'utils/cookie';
import { trackEvent } from 'utils/metricsTracking';
import { EVENTS } from 'consts/metricsTrackingEvents';

import { banner, closeButton, ctaLink } from './Banner.style';

export interface BannerProps {
  isClosable?: boolean;
  cookieKey?: string;
  text?: ReactNode;
  ctaText?: string | ReactElement;
  onClick?: () => void;
  onCtaClick?: (value: boolean) => unknown; // TODO: fix this type
  className?: string;
}

export const Banner = ({
  isClosable = false,
  cookieKey,
  text,
  ctaText,
  onClick,
  onCtaClick = () => undefined,
  className,
}: BannerProps) => {
  const [isHidden, setIsHidden] = useState(false);
  const theme = useTheme();

  const handleClose = () => {
    if (cookieKey) {
      cookie.set(cookieKey, 'true', { expires: 14 });
    }
    trackEvent(EVENTS.moduleInteraction, {
      path: window.location.pathname,
      'Module Name': `Banner`,
      'Module Element Section': 'Close Button',
      'Module Element Interaction Type': 'click',
      'Element Content': `${text} ${ctaText}`,
    });
    setIsHidden(true);
  };

  const handleCtaClick = () => {
    onCtaClick(true);
    trackEvent(EVENTS.moduleInteraction, {
      path: window.location.pathname,
      'Module Name': `Banner`,
      'Module Element Section': 'CTA',
      'Module Element Interaction Type': 'click',
      'Element Content': `${text} ${ctaText}`,
    });
  };

  if (cookieKey) {
    useEffect(() => {
      setIsHidden(cookie.get(cookieKey) === 'true');
    }, []);
  }

  if (isHidden) {
    return null;
  }

  return (
    <div
      css={banner(theme)}
      className={className}
      onClick={onClick}
      data-testid="banner"
    >
      {text}{' '}
      {ctaText && (
        <button onClick={handleCtaClick} type="button" css={ctaLink(theme)}>
          {ctaText}
        </button>
      )}
      {isClosable && (
        <button
          css={closeButton}
          type="button"
          onClick={handleClose}
          id="banner_close"
          aria-label="close banner"
        >
          <CrossIcon fill={theme.COLORS.white} height={16} width={16} />
        </button>
      )}
    </div>
  );
};
