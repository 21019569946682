import React, { useState } from 'react';

import type { PhysicalItem } from 'utils/eCommerce/cart/types';

import { QuantityUpdater, RemoveItemButton } from './components';
import {
  ItemName,
  ItemTotal,
  ItemVariantName,
  ItemView,
  LineItemDetails,
  LineItemThumbnail,
  LineItemWrapper,
  NameRow,
  PriceAndVariantRow,
} from './LineItem.style';

export const LineItem = ({ item }: { item: PhysicalItem }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LineItemWrapper>
      <LineItemThumbnail item={item} />
      <LineItemDetails>
        <ItemView>
          <NameRow>
            <ItemName item={item} />
            <RemoveItemButton
              item={item}
              onRemovalStarted={() => setIsLoading(true)}
              onRemovalFinished={() => setIsLoading(false)}
            />
          </NameRow>
          <PriceAndVariantRow>
            <ItemTotal item={item} />
            <ItemVariantName item={item} />
          </PriceAndVariantRow>
        </ItemView>
        {!item.isRx && (
          <QuantityUpdater
            item={item}
            isLoading={isLoading}
            onUpdatingStarted={() => setIsLoading(true)}
            onUpdatingFinished={() => setIsLoading(false)}
          />
        )}
      </LineItemDetails>
    </LineItemWrapper>
  );
};
