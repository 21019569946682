import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  ModalPop,
  POP_REDESIGN_FREE_GIFT_MODAL_CONTENT,
} from 'components/modules/pop-redesign/ModalPop';

import { NavBannerProps } from '..';

export const LABOR_DAY_BANNER_CONTENT: NavBannerProps = {
  text: (
    <React.Fragment>
      <StaticImage
        src="../images/labor-day/desktop@2x.png"
        alt="A purple badge with five animated Curology products that reads 'Celebrate labor day. Free gift!'"
        width={160}
        height={160}
      />
      <StaticImage
        src="../images/labor-day/mobile@2x.png"
        alt="A purple badge with five animated Curology products."
        width={37}
        height={24}
      />
      <span>
        <strong>Celebrate Labor Day. FREE Gift!</strong>
      </span>
    </React.Fragment>
  ),
  modalProps: {
    renderCloseButton: false,
    children: <ModalPop {...POP_REDESIGN_FREE_GIFT_MODAL_CONTENT} />,
  },
};
