import React from 'react';

import type { SVGProps } from 'types/svg';

export const CrossIcon = ({ fill = '#1D1D44' }: SVGProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" fill="white" />
      <line
        x1="12.9394"
        y1="26.0813"
        x2="27.0815"
        y2="11.9392"
        stroke={fill}
        strokeWidth="3"
      />
      <line
        x1="13.0607"
        y1="11.9392"
        x2="27.2029"
        y2="26.0814"
        stroke={fill}
        strokeWidth="3"
      />
    </svg>
  );
};
