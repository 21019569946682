import { Typography } from '@curology/ui-components-web/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import type { ReactNode } from 'react';
import React from 'react';

export const CartContentWrapper = ({ children }: { children: ReactNode }) => (
  <div
    className="w-full h-full p-8 pt-4 sm:pt-10 relative flex flex-col justify-between"
    data-testid="empty-shopping-cart"
  >
    {children}
  </div>
);

export const EmptyCartBackgroundImage = () => (
  <div
    css={css`
      position: absolute;
      top: 10rem;

      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    `}
  >
    <StaticImage
      src="./images/SPF_Launch Campaign_Lifestyle_Reed.jpg"
      alt="Person applying prescription face cream"
      loading="eager"
      objectFit="contain"
      objectPosition="bottom"
    />
  </div>
);

export const EmptyCartMessage = ({ children }: { children: ReactNode }) => (
  <Typography size="body" className="mt-7">
    {children}
  </Typography>
);

export const ButtonWrapper = ({ children }: { children: ReactNode }) => (
  <div className="w-full flex flex-col px-6">{children}</div>
);

export const HeaderAligner = styled.div();
export const CTAAligner = styled.div();
