import React from 'react';
import { Global, css } from '@emotion/react';
import { MEDIA_QUERIES } from 'radiance-ui/lib/constants';

import { LOCAL_COLORS } from 'styles/constants';

const GlobalTypography = () => (
  <Global
    styles={(theme) => css`
      :root {
        --base-font: 'Skintype Text', 'Avant Garde', Avantgarde,
          'Century Gothic', CenturyGothic, AppleGothic, Verdana, sans-serif;
        --header-font: 'Skintype Display', 'Avant Garde', Avantgarde,
          'Century Gothic', CenturyGothic, AppleGothic, Verdana, sans-serif;
      }
      h2,
      h3,
      h4,
      h5 {
        font-family: var(--base-font);
        font-weight: bold;
        color: ${theme.COLORS.primary};
      }
      body,
      p,
      a {
        font-family: var(--base-font);
        color: ${LOCAL_COLORS.surfaceDarkBold};
        strong {
          font-weight: bold;
        }

        u {
          // Fixes double underlines on links
          text-decoration: none;
        }
      }

      h1 {
        font-family: var(--base-font);
        font-size: 2.25rem; //36px
        font-weight: normal;
        line-height: 1.3;
        color: ${LOCAL_COLORS.surfaceDarkBold};

        strong {
          font-weight: bold;
        }

        ${MEDIA_QUERIES.lgUp} {
          font-size: 3.125rem; //50px
        }
      }

      h2 {
        font-size: 1.75rem; //28px
        line-height: 1.6;

        ${MEDIA_QUERIES.lgUp} {
          font-size: 2.25rem; //36px
        }
      }

      h3 {
        font-size: 1.25rem; //20px
        line-height: 1.6;
        ${MEDIA_QUERIES.lgUp} {
          font-size: 1.5rem; //24px
        }
      }

      h4 {
        font-size: 1rem; //16px
        line-height: 1.7;

        ${MEDIA_QUERIES.lgUp} {
          font-size: 1.25rem; //20px
        }
      }

      h5 {
        font-size: 0.75rem; //12px
        line-height: 1.3;
      }

      p {
        font-size: 1rem; //16px
        line-height: 1.7;
      }

      .smallP {
        font-size: 0.875rem; //14px
        line-height: 1.5;
      }

      strong {
        font-weight: bold;
      }

      i,
      em {
        font-style: italic;
      }

      small {
        font-size: 0.75rem; //12px
        line-height: 1.7;
        display: block;
      }

      // Squeeze fonts
      .typography-size-d1 {
        font-weight: 700;
        line-height: 92%;
        letter-spacing: -0.04em;
        font-size: 44px;

        ${MEDIA_QUERIES.mdUp} {
          font-size: 60px;
        }

        ${MEDIA_QUERIES.xlUp} {
          font-family: var(--header-font);
          font-size: 96px;
        }
      }

      // Osano cookie banner
      .osano-cm-button,
      .osano-cm-buttons,
      .osano-cm-content,
      .osano-cm-description,
      .osano-cm-dialog,
      .osano-cm-info-dialog,
      .osano-cm-info-dialog-close,
      .osano-cm-info-dialog-wrapper,
      .osano-cm-link,
      .osano-cm-toggle,
      .osano-cm-toggle input[type='checkbox'],
      .osano-cm-window,
      .osano-cm-window a.osano-cm-link,
      label.osano-cm-label,
      ul.osano-cm-info-list,
      ul.osano-cm-info-list .osano-cm-label {
        font-family: 'Skintype Text', 'Avant Garde', Avantgarde,
          'Century Gothic', CenturyGothic, AppleGothic, Verdana, sans-serif;
      }

      .osano-cm-dialog,
      .osano-cm-window {
        z-index: 2001;
      }

      .osano-cm-description {
        p {
          font-size: 12px;
        }
      }

      /* TODO: Still TBD on whether we hide the deny button or not. Leaving here for now. */
      /* .osano-cm-deny, */
      .osano-cm-widget {
        display: none;
      }
    `}
  />
);

export default GlobalTypography;
