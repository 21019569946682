import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { type ModalPopProps } from '../ModalPop';
import {
  CTA_GET_MY_FORMULA,
  ACNE_FREE_GIFT_POP_MODAL_HEADER,
} from 'consts/copy';

export const POP_REDESIGN_FREE_GIFT_MODAL_CONTENT: ModalPopProps = {
  images: {
    desktop: (
      <StaticImage
        src="../images/desktop@2x.jpg"
        alt="Six skincare products shown on beige background, acne body wash, micellar makeup remover, moisturizer, rich moisturizer, emergency spot patch, lip balm."
        loading="eager"
      />
    ),
    mobile: (
      <StaticImage
        src="../images/mobile@2x.jpg"
        alt="Six skincare products shown on beige background, acne body wash, micellar makeup remover, moisturizer, rich moisturizer, emergency spot patch, lip balm."
        loading="eager"
      />
    ),
  },
  header: ACNE_FREE_GIFT_POP_MODAL_HEADER,
  body: 'Get started on the path to better skin. For a limited time, you can get a 30 day supply of our Custom FormulaRx and up to 6 skincare minis for only $5! That’s $50 worth of products, and an online consultation with a licensed dermatology provider. Subscribe today!',
  ctaButton: {
    buttonText: CTA_GET_MY_FORMULA,
  },
  className: 'smallHeadline',
  disclaimerText:
    '*Offer available until 1/28/24. Advertised price not inclusive of handling fees. Subject to consultation. New subscribers only. Trial-size products only. Only Rx products are personalized.',
};

export const ACNE_FREE_GIFT_MODAL_CONTENT: ModalPopProps = {
  ...POP_REDESIGN_FREE_GIFT_MODAL_CONTENT,
  className: 'smallHeadline',
  header: ACNE_FREE_GIFT_POP_MODAL_HEADER,
};

export const RETAIL_OFFER_MODAL_CONTENT: ModalPopProps = {
  images: {
    desktop: (
      <StaticImage
        src="../images/retailOfferModalDesktop.jpg"
        alt="Six skincare products shown on beige background, acne body wash, micellar makeup remover, moisturizer, rich moisturizer, emergency spot patch, lip balm."
        loading="eager"
      />
    ),
    mobile: (
      <StaticImage
        src="../images/retailOfferModalMobile.jpg"
        alt="Six skincare products shown on beige background, acne body wash, micellar makeup remover, moisturizer, rich moisturizer, emergency spot patch, lip balm."
        loading="eager"
      />
    ),
  },
  header: 'Redeem your free personalized skincare product',
  className: 'smallHeadline',
  body: <React.Fragment>Your custom skincare routine awaits.</React.Fragment>,
  ctaButton: {
    buttonText: 'Claim your offer',
  },
};
