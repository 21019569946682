import { useContext } from 'react';

import { ECommerceContext } from '../../ECommerceProvider';
import type { EcommerceUtils } from '../types';

/**
 * useCart
 *
 * Public hook for using the previously fetched
 * shopping cart object, useful as a shorthand
 * to access the cart
 */
const useCart = () => {
  return useContext(ECommerceContext) as EcommerceUtils;
};

export default useCart;
