export const COOKIE_KEYS = {
  amplitudeDeviceId: 'amp_device_id',
  amplitudeBrowserSdk: 'amplitudeBrowserSdk',
  auth: 'hasAuth',
  curologyUuid: 'curologyUuid',
  invite: 'inviteToken',
  lpVariant: 'landingPageVariant',
  lpUrl: 'landingPageUrl',
  promotionCode: 'promotionCode',
  bannerPromotionCode: 'bannerPromotionCode',
  referrerUrl: 'httpReferrerUrl',
  trackingOptOut: 'trackingOptOut',
  hideTeenBanner: 'hideTeenBanner',
  hideTretinoinBanner: 'hideTretinoinBanner',
  hideNiacinamideBanner: 'hideNiacinamideBanner',
  hideMetronidazoleBanner: 'hideMetronidazoleBanner',
  hideClindamycinBanner: 'hideClindamycinBanner',
  hideHydrocortisoneBanner: 'hideHydrocortisoneBanner',
  hideRosaceaBanner: 'hideRosaceaBanner',
  viewedRetailOfferModal: 'viewedRetailOfferModal',
  offer: 'offer',
  ldUuid: 'ldUuid',
  convertOptOut: 'convert_optout',
} as const;

export type CookieKeysType = keyof typeof COOKIE_KEYS;
