import { css } from '@emotion/react';
import { MEDIA_QUERIES, SPACER, ThemeType } from 'radiance-ui/lib/constants';

import { freeGiftBannerStyles, wiggleAnimation } from '.';

export const popRedesignBannerStyles = (theme: ThemeType) => css`
  ${freeGiftBannerStyles(theme)}
  ${wiggleAnimation}
  font-weight: normal;

  & > span {
    display: flex;
    align-items: center;
    gap: ${SPACER.xsmall};
    ${MEDIA_QUERIES.xlUp} {
      flex-direction: column;
      gap: 0;
    }
  }

  svg {
    animation: mobileWiggle 8s linear infinite;
  }

  ${MEDIA_QUERIES.xlUp} {
    position: fixed;
    animation: desktopWiggle 8s linear infinite;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    width: fit-content;
    top: 146px;
    right: 72px;

    strong {
      display: block;
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;
      text-align: left;
    }

    svg {
      animation: none;
      width: 32px;
      height: 32px;
    }
  }
`;
