import React, { ReactNode } from 'react';
import { Icon, IconName, IconSize } from '@curology/ui-components-web/react';

import { CtaButton, CtaButtonProps } from 'components/global/CtaButton';

import {
  Disclaimer,
  ModalHeader,
  ModalPopContainer,
  ctaStyles,
  ModalBody,
  ModalContent,
  DesktopImageContainer,
  MobileImageContainer,
  closeButtonStyles,
  InnerBox,
} from './ModalPop.style';
import { useModalClose } from 'components/global/modal';

export interface ModalPopProps {
  images: {
    desktop: JSX.Element;
    mobile: JSX.Element;
  };
  header?: ReactNode;
  body?: ReactNode;
  ctaButton?: CtaButtonProps;
  disclaimerText?: string;
  className?: string;
}

const moduleName = 'modalPop';

export const ModalPop = ({
  images,
  header,
  body,
  ctaButton,
  disclaimerText,
  className,
}: ModalPopProps) => {
  const close = useModalClose();

  return (
    <ModalPopContainer data-module={moduleName} className={className}>
      <InnerBox>
        <MobileImageContainer>{images.mobile}</MobileImageContainer>
        <ModalContent>
          {header && <ModalHeader className={className}>{header}</ModalHeader>}
          {body && <ModalBody className={className}>{body}</ModalBody>}
          {ctaButton && (
            <CtaButton className={className} css={ctaStyles} {...ctaButton} />
          )}
          {disclaimerText && (
            <Disclaimer className={className}>{disclaimerText}</Disclaimer>
          )}
        </ModalContent>
        <DesktopImageContainer>{images.desktop}</DesktopImageContainer>
        <button role="button" css={closeButtonStyles} onClick={close}>
          <Icon size={IconSize.Small} name={IconName.Cross} />
        </button>
      </InnerBox>
    </ModalPopContainer>
  );
};
