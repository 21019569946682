import type { SEOPropsType } from '..';

const title = 'Custom Rosacea Treatment to Reduce Redness | Curology';
const description =
  'Take the guesswork out of treating your rosacea. Curology’s Custom Formula is designed by our dermatologists to care for your skin’s unique concerns.';

export const SEO_ROSACEA_CONTENT: SEOPropsType = {
  title,
  description,
  isIndexableOverride: true,
};

export const SEO_ROSACEA_MARKETING_SAFE_CONTENT: SEOPropsType = {
  title,
  description,
};
