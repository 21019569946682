import styled from '@emotion/styled';
import {
  BREAKPOINTS,
  MEDIA_QUERIES,
  SPACER,
  Z_SCALE,
  ANIMATION,
} from 'radiance-ui/lib/constants';
import getContrastingColor from 'utils/getContrastingColor';

export const Overlay = styled.div`
  background-color: ${({ theme }) => theme.COLORS.overlay};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${Z_SCALE.modal};
  overflow-y: auto;
  display: flex;
  align-items: center;

  transition: opacity ${ANIMATION.defaultTiming}
    cubic-bezier(0.075, 0.82, 0.165, 1);

  &.entering,
  &.exiting,
  &.exited {
    opacity: 0;
  }

  &.entered {
    opacity: 1;
  }

  ${MEDIA_QUERIES.mdUp} {
    overflow-y: hidden;
  }
`;

export const ModalContainer = styled.div<{ backgroundColor?: string }>`
  height: 100vh;
  margin: 0 auto;
  max-width: 1032px;
  overflow-y: auto;
  top: 0;
  position: absolute;
  width: 100%;
  transition: transform ${ANIMATION.defaultTiming}
    cubic-bezier(0.075, 0.82, 0.165, 1);

  & > div {
    ${({ backgroundColor }) =>
      backgroundColor ? `background: ${backgroundColor};` : ''};
  }

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.medium};
    height: auto;
    position: relative;
  }

  &.entered {
    transform: translateY(0%);
  }

  &.entering,
  &.exited {
    transform: translateY(25%);
  }

  &.exiting {
    transform: translateY(0%);
  }
`;

export const CloseIconContainer = styled.button<{ backgroundColor?: string }>`
  cursor: pointer;
  position: absolute;
  right: ${SPACER.medium};
  top: ${SPACER.medium};
  z-index: ${Z_SCALE.e2000};
  padding: ${SPACER.small};
  transition: all 0.4s ease 0.2s;
  transform-origin: center;

  border-radius: ${SPACER.large};
  border: none;

  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'none'};

  path {
    fill: ${({ theme, backgroundColor }) =>
      backgroundColor
        ? getContrastingColor(backgroundColor)
        : theme.COLORS.white};

    ${MEDIA_QUERIES.mdUp} {
      fill: ${({ theme }) => theme.COLORS.primary};
    }
  }

  ${MEDIA_QUERIES.mdUp} {
    right: ${SPACER.medium};
    top: ${SPACER.medium};
  }

  &:hover {
    transform: rotate(90deg);
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: ${BREAKPOINTS.lg};
`;
