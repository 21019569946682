import React from 'react';

import { IconName } from '@curology/ui-components-web/react';
import { NavBannerProps } from '../NavBanner';
import { LOCAL_COLORS } from 'styles/constants';

export const LIMITED_TIME_OFFER_BANNER_CONTENT: NavBannerProps = {
  textColor: LOCAL_COLORS.surfaceDarkBold,
  backgroundColor: LOCAL_COLORS.surfaceOrange,
  iconName: IconName.Gift,
  text: (
    <React.Fragment>
      <strong>Limited Time Offer</strong> for new customers!
    </React.Fragment>
  ),
};
