import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const NestedLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${MEDIA_QUERIES.lgUp} {
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ChipContainer = styled.div`
  margin-left: ${SPACER.xsmall};
  display: flex;

  div {
    background-color: ${LOCAL_COLORS.surfaceOrange};
    border-radius: 0px;
    padding: ${SPACER.xsmall};
    height: 100%;
    margin-right: ${SPACER.xsmall};

    span {
      color: black !important;
      line-height: 100%;
    }
  }
`;

const commonLinkStyles = (theme: ThemeType) => css`
  color: ${theme.COLORS.primary};
  font-size: ${theme.TYPOGRAPHY.fontSize.body};
  text-decoration: underline transparent;
  transition: text-decoration 250ms;
  line-height: 1.5;
  padding: 0.5rem 0 0.5rem 0;

  ${MEDIA_QUERIES.lgUp} {
    padding: 0;
  }
`;

export const desktopLinkStyles = (theme: ThemeType) => css`
  ${commonLinkStyles(theme)}
  font-weight: ${theme.TYPOGRAPHY.fontWeight.bold};
  padding-bottom: ${SPACER.small};
  white-space: nowrap;

  &:hover {
    text-decoration: solid underline ${LOCAL_COLORS.surfaceOrange} 4px;
    text-decoration-skip-ink: none;
  }
`;

export const mobileLinkStyles = (theme: ThemeType) => css`
  ${commonLinkStyles(theme)}
  font-weight: ${theme.TYPOGRAPHY.fontWeight.normal};
`;
