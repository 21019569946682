import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

const errorInstancesAtom = atom(0);

export const useIsError = () => {
  const setInstances = useSetAtom(errorInstancesAtom);
  useEffect(() => {
    setInstances((instances) => ++instances);
    return () => {
      setInstances((instances) => --instances);
    };
  }, []);
};

export const useErrorCount = () => useAtomValue(errorInstancesAtom);
