import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import type { ThemeType } from 'radiance-ui/lib/constants';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';

export const BodyTitle = styled.h2`
  font-family: ${({ theme }) => theme.FONTS.headerFont};
  font-weight: ${({ theme }) => theme.TYPOGRAPHY.fontWeight.normal};
  font-size: 30px;
  line-height: 38px;
  color: ${({ theme }) => theme.COLORS.primary};
  margin-bottom: 30px;
`;

export const ModalBody = styled.div`
  background-color: ${({ theme }) => theme.COLORS.white};
  color: ${({ theme }) => theme.COLORS.primary};

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    max-height: calc(100vh - ${SPACER.x2large});
  }
`;

export const ModalContent = styled.div`
  padding: ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    padding: ${SPACER.x4large} ${SPACER.x3large} ${SPACER.x3large};
    overflow: auto;
  }
`;

export const headerImage = css`
  width: 100%;
`;

export const list = css`
  margin-bottom: ${SPACER.x2large};
`;

export const listItem = (theme: ThemeType) => css`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${SPACER.medium};
  ${TYPOGRAPHY_STYLE.caption(theme)};
  color: ${theme.COLORS.primary};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const accessDescriptionText = (theme: ThemeType) => css`
  margin-bottom: ${SPACER.medium};
  ${TYPOGRAPHY_STYLE.caption(theme)};
  color: ${theme.COLORS.primary};
`;

export const CheckmarkIconContainer = styled.span`
  margin-right: ${SPACER.large};
  height: 28px;
  display: flex;
  align-items: center;
`;

export const ImageContainer = styled.div`
  background-color: #a49de0;
  display: flex;
  align-items: flex-end;

  ${MEDIA_QUERIES.mdUp} {
    width: 38%;
    flex-shrink: 0;
    overflow: hidden;
  }
`;

export const HorizontalRuleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: ${SPACER.xlarge} 0;
`;

export const SparkleEmojiContainer = styled.div`
  padding: 0 ${SPACER.small};
  background-color: ${({ theme }) => theme.COLORS.white};
  position: absolute;
`;

export const horizontalRule = (theme: ThemeType) => css`
  border: 1px solid ${theme.COLORS.primary};
  width: 100%;
`;
