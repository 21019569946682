import React from 'react';

import useCart from 'utils/eCommerce/cart/useCart';

import {
  CartHeaderHeading,
  CartHeaderWrapper,
  CloseCartButton,
} from './CartHeader.style';
import { FreeShippingMessage } from './components';

export const CartHeader = ({
  onClose,
  isOpening,
}: {
  onClose: () => void;
  isOpening: boolean;
}) => {
  const {
    cart: { itemCount },
  } = useCart();

  return (
    <CartHeaderWrapper>
      <CartHeaderHeading>
        <h2>Cart</h2>
        <CloseCartButton onClose={onClose} />
      </CartHeaderHeading>
      {itemCount > 0 && <FreeShippingMessage isOpening={isOpening} />}
    </CartHeaderWrapper>
  );
};
