import React from 'react';
import {
  chevronIconStyles,
  ProductCategory as StyledProductCategory,
} from './ProductDrawers.style';
import { ChevronIcon } from 'radiance-ui/lib/icons';
import { ONE_NAV_TEST_PRODUCT_CATEGORIES } from 'consts/products';
interface ProductCategoryProps {
  categoryName: valueof<typeof ONE_NAV_TEST_PRODUCT_CATEGORIES>;
  handleMouseEnter: () => void;
  handleClick: () => void;
  isActive: boolean;
}

export const ProductCategory = ({
  categoryName,
  handleMouseEnter,
  handleClick,
  isActive,
}: ProductCategoryProps) => {
  return (
    <StyledProductCategory
      id={categoryName}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      className={isActive ? 'isActive' : ''}
    >
      {categoryName}
      <ChevronIcon css={chevronIconStyles(isActive)} />
    </StyledProductCategory>
  );
};
