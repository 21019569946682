import React from 'react';

import { FlexibleCard } from 'components/global/FlexibleCard';

import {
  Container,
  FeaturedContainer,
  FlexibleCardContainer,
} from './Skincare101ModalDesktop.style';
import { Skincare101Links } from '../../Skincare101Links';
import { NAV_SKINCARE_101_FEATURED_ARTICLES_CONTENT } from '../../../content';

export const Skincare101ModalDesktop = () => (
  <Container>
    <Skincare101Links />
    <FeaturedContainer>
      {NAV_SKINCARE_101_FEATURED_ARTICLES_CONTENT.map((article) => (
        <FlexibleCardContainer key={article.title}>
          <FlexibleCard
            media={article.media}
            title={article.title}
            subtitle={article.published}
            body=""
            link={article.href}
            tags={[article.timeToRead]}
          />
        </FlexibleCardContainer>
      ))}
    </FeaturedContainer>
  </Container>
);
