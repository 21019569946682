import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { LOCAL_COLORS } from 'styles/constants';

export const separatorGrey = '#e2e2e2';

export const DrawersContainer = styled.div`
  display: flex;
  padding: ${SPACER.xlarge} ${SPACER.large};
  margin-left: auto;
`;

export const ProductCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  max-width: 310px;
  margin-left: auto;
  width: 310px;
`;

export const ProductCategory = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  justify-content: space-between;
  &.isActive {
    color: ${LOCAL_COLORS.surfaceBrandBold}};
  }
`;

export const Separator = styled.div`
  border-right: 1px solid ${separatorGrey};
  margin: -${SPACER.xlarge} ${SPACER.x2large} 0;
  height: calc(100% + ${SPACER.x5large});
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 670px;
  width: 670px;

  ${MEDIA_QUERIES.xlUp} {
    max-width: 590px;
    width: 590px;
  }
`;

export const LinksContainer = styled.div`
  display: none;
  flex-direction: column;
  gap: ${SPACER.medium};
  width: 100%;

  > a {
    font-weight: 400;
  }

  &.isActive {
    display: flex;
  }
`;

export const chevronIconStyles = (isActive: boolean) => css`
  width: 24px;
  height: 24px;
  stroke: ${isActive
    ? LOCAL_COLORS.surfaceBrandBold
    : LOCAL_COLORS.surfaceDarkBold};
`;
