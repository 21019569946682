export const LOGGING_ENABLED = process.env.LOGGING_ENABLED === 'true';
export const IS_CLIENT = typeof window !== 'undefined';
export const JEST_DEFINED = typeof jest !== 'undefined';
export const IS_DEV = (process.env.APP_ENV ?? '') === 'development';
export const IS_PREVIEW =
  process.env.CF_PAGES && process.env.APP_ENV !== 'production';
export const IS_PRODUCTION =
  process.env.CF_PAGES && process.env.APP_ENV === 'production';
export const IS_BUILD_OR_TEST = !IS_CLIENT || JEST_DEFINED;
export const IS_SECURE =
  ['production', 'staging', 'review-app'].indexOf(process.env.APP_ENV ?? '') !==
  -1;
export const SITE_URL = process.env.SITE_URL;
export const AMPLITUDE_API_KEY = process.env.AMPLITUDE_API_KEY;
export const GTM_KEY = process.env.GTM_KEY;
export const GTM_DEV_KEY = process.env.GTM_DEV_KEY;
