import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES, SPACER } from 'radiance-ui/lib/constants';
import { ModalEntry } from 'schema/modalSchema';
import { LOCAL_COLORS } from 'styles/constants';
import { desktopOnly } from 'styles/mixins/displayMobileDesktop';

export const contentfulModalStyles = ({
  backgroundColor,
  textColor,
}: ModalEntry) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textColor ?? LOCAL_COLORS.surfaceDarkBold};
  padding: 0 12px;
  position: fixed;

  & > div {
    position: relative;
    background-color: ${backgroundColor ?? LOCAL_COLORS.surfaceWhite};
  }

  ${MEDIA_QUERIES.mdUp} {
    max-width: 900px;
    padding: 0;

    button {
      width: fit-content;
    }
  }

  small {
    color: ${textColor ?? LOCAL_COLORS.surfaceDarkBold};
  }
`;

export const desktopImageStyles = css`
  ${desktopOnly()};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const MainModalBoxStyles = css`
  max-width: 90vw;
  min-height: 350px;
  left: 5vw;
  top: 25vh;

  p {
    margin-bottom: ${SPACER.large};
  }

  ${MEDIA_QUERIES.smUp} {
    max-width: 450px;
    min-height: 350px;
    top: 0;
    left: 0;
  }
`;

export const ContentfulModalBox = styled.div`
  margin: auto;
  overflow: auto;
  max-height: 90vh;

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const MainBox = styled.div<{ hasImage?: boolean }>`
  padding: ${SPACER.medium} ${SPACER.large};

  ${MEDIA_QUERIES.mdUp} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    ${({ hasImage }) => hasImage && 'max-width: 400px;'};
    padding: ${SPACER.x2large};
  }
`;

export const Title = styled.h2`
  font-size: ${SPACER.large};
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.64px;
  margin-bottom: ${SPACER.small};
  text-wrap: pretty;

  ${MEDIA_QUERIES.mdUp} {
    margin-bottom: ${SPACER.medium};
  }
`;

export const Body = styled.div`
  font-size: ${SPACER.medium};
  line-height: 140%;

  ul {
    list-style: unset;
    padding: ${SPACER.small};
    margin-bottom: ${SPACER.small};

    ${MEDIA_QUERIES.mdUp} {
      padding: ${SPACER.medium} 0;
      padding-left: ${SPACER.large};
    }
  }
`;

export const ctaStyles = css`
  small {
    text-align: left;
  }
`;
