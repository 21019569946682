import React from 'react';
import { Button as SqueezeButton } from '@curology/ui-components-web/react';

import {
  navInternalWithQueryString,
  navExternalWithQueryString,
} from 'utils/marketingSafeDomain';

import { UnstyledButton, squeezeButtonStyles } from './NavLink.style';

export const NavLink = ({
  href,
  isExternal = false,
  children,
  trackClick,
  onClick,
  onMouseEnter,
  onFocus,
  tabIndex,
  useSqueeze = false,
  ...rest
}: {
  href?: string;
  isExternal?: boolean;
  children: React.ReactNode;
  trackClick?: () => void;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  tabIndex?: number;
  useSqueeze?: boolean;
}) => {
  const handleClick = (
    event: React.SyntheticEvent<HTMLButtonElement, Event>,
  ) => {
    event.preventDefault();
    if (onClick) onClick();
    if (trackClick) trackClick();
    if (!href) return;

    if (isExternal) {
      return navExternalWithQueryString(href);
    }

    return navInternalWithQueryString(href);
  };

  return (
    <React.Fragment>
      {useSqueeze ? (
        <SqueezeButton
          css={squeezeButtonStyles}
          type="button"
          buttonType="secondary"
          onClick={handleClick}
          onFocus={onFocus}
          onMouseEnter={onMouseEnter}
          tabIndex={tabIndex}
          {...rest}
        >
          {children}
        </SqueezeButton>
      ) : (
        <UnstyledButton
          type="button"
          onClick={handleClick}
          onFocus={onFocus}
          onMouseEnter={onMouseEnter}
          tabIndex={tabIndex}
          {...rest}
        >
          {children}
        </UnstyledButton>
      )}
    </React.Fragment>
  );
};
