/*
 * Much of this is taken from site-tools.
 */
import { useMemo } from 'react';
import { parseQueryString } from '@curology-engineering/great-gatsby/utils';
import { Script } from 'gatsby';

import {
  GTM_DEV_KEY,
  GTM_KEY,
  IS_CLIENT,
  IS_PRODUCTION,
} from 'consts/environmental';

const getGtmScript = (key: string) => `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),
  event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),
  dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
  f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${key}');
`;

const isProdGtmKeyOverride = () => {
  if (!IS_CLIENT) {
    return false;
  }

  const queryParams = parseQueryString(window.location);
  return 'gtm_prod' in queryParams;
};

/**
 * Use query parameter `gtm_prod` to use the production GTM container in non-production environments
 */
const useGtmKey = () => {
  const shouldUseProductionGtmKey = IS_PRODUCTION || isProdGtmKeyOverride();

  return useMemo(
    () => (shouldUseProductionGtmKey ? GTM_KEY : GTM_DEV_KEY),
    [GTM_KEY, GTM_DEV_KEY, shouldUseProductionGtmKey],
  );
};

export const GtmScript = () => {
  const key = useGtmKey();

  return key ? (
    <Script id="gtm-script" type="text/javascript">
      {getGtmScript(key)}
    </Script>
  ) : null;
};
