import React from 'react';
import { css } from '@emotion/react';

import {
  CART_SLIDE_DURATION_MS,
  slideIn,
  slideOut,
} from '../shared/animations';
import type { ClosableComponentProps } from '../shared/types';

const constantStyles = css`
  right: 0;
  animation: ${slideIn} ${CART_SLIDE_DURATION_MS}ms forwards;
`;

export const CartSidebar = ({
  isClosing,
  children,
}: ClosableComponentProps) => {
  const rules = isClosing
    ? css`
        ${constantStyles}
        animation: ${slideOut} ${CART_SLIDE_DURATION_MS}ms forwards;
      `
    : constantStyles;

  return (
    <div
      data-testid="shopping-cart-sidebar"
      className="bg-white absolute top-0 bottom-0 w-full max-w-none md:max-w-[510px] transition-all"
      css={rules}
    >
      {children}
    </div>
  );
};
