import { convertToDisplayName, convertToSlug } from 'utils/copywriting';

export const PRODUCTS = {
  customFormula: 'Custom Formula',
  bodyCleanse: 'Body Cleanse',
  hairFormula: 'Hair Formula',
  emergencySpotPatches: 'Emergency Spot Patch',
  acneBodyWash: 'Acne Body Wash',
  cleanser: 'Cleanser',
  moisturizer: 'Moisturizer',
  richMoisturizer: 'Rich Moisturizer',
  // TBD: Name should be updated to Cleanser and Moisturizer Set
  cleanserMoisturizer: 'Cleanser and Moisturizer Duo',
  sunscreen: 'Sunscreen',
  lipBalm: 'Lip Balm',
  micellarMakeupRemover: 'Micellar Makeup Remover',
  curologyFutureProof: 'Curology Future-Proof',
  deepCleanse: 'Deep Cleanse',
  acneCleanser: 'Acne Cleanser',
  futureProof: 'Future Proof',
  starterSet: 'Starter Set',
  summerEssentialsSet: 'Summer Essentials Set',
  acneCareSet: 'Acne Care Set',
  travelMinisSet: 'Travel Minis Set',
  barrierBalm: 'Barrier Balm',
  emergencySpotPatchClusters: 'Emergency Spot Patch Clusters',
  liquidPoreExfoliant: 'Liquid Pore Exfoliant',
  darkSpotSerum: 'Dark Spot Serum',
  hydrationSerum: 'Hydration Serum',
} as const;

export const PRODUCT_DISPLAY_NAMES = {
  customFormula: PRODUCTS.customFormula,
  bodyCleanse: PRODUCTS.bodyCleanse,
  hairFormula: PRODUCTS.hairFormula,
  emergencySpotPatches: PRODUCTS.emergencySpotPatches,
  acneBodyWash: PRODUCTS.acneBodyWash,
  cleanser: convertToDisplayName(PRODUCTS.cleanser),
  moisturizer: convertToDisplayName(PRODUCTS.moisturizer),
  richMoisturizer: convertToDisplayName(PRODUCTS.richMoisturizer),
  cleanserMoisturizer: convertToDisplayName(PRODUCTS.cleanserMoisturizer),
  sunscreen: convertToDisplayName(PRODUCTS.sunscreen),
  lipBalm: convertToDisplayName(PRODUCTS.lipBalm),
  micellarMakeupRemover: PRODUCTS.micellarMakeupRemover,
  curologyFutureProof: PRODUCTS.curologyFutureProof,
  deepCleanse: PRODUCTS.deepCleanse,
  acneCleanser: PRODUCTS.acneCleanser,
  futureProof: PRODUCTS.futureProof,
  starterSet: PRODUCTS.starterSet,
  summerEssentialsSet: PRODUCTS.summerEssentialsSet,
  acneCareSet: PRODUCTS.acneCareSet,
  travelMinisSet: PRODUCTS.travelMinisSet,
  barrierBalm: PRODUCTS.barrierBalm,
  emergencySpotPatchClusters: PRODUCTS.emergencySpotPatchClusters,
  liquidPoreExfoliant: PRODUCTS.liquidPoreExfoliant,
  darkSpotSerum: PRODUCTS.darkSpotSerum,
  hydrationSerum: PRODUCTS.hydrationSerum,
} as const;

export const PRODUCT_SLUGS = {
  customFormula: convertToSlug(PRODUCT_DISPLAY_NAMES.customFormula),
  bodyCleanse: convertToSlug(PRODUCT_DISPLAY_NAMES.bodyCleanse),
  emergencySpotPatches: convertToSlug(
    PRODUCT_DISPLAY_NAMES.emergencySpotPatches,
  ),
  acneBodyWash: convertToSlug(PRODUCT_DISPLAY_NAMES.acneBodyWash),
  cleanser: convertToSlug(PRODUCT_DISPLAY_NAMES.cleanser),
  moisturizer: convertToSlug(PRODUCT_DISPLAY_NAMES.moisturizer),
  richMoisturizer: convertToSlug(PRODUCT_DISPLAY_NAMES.richMoisturizer),
  cleanserMoisturizer: 'cleanser-moisturizer',
  sunscreen: convertToSlug(PRODUCT_DISPLAY_NAMES.sunscreen),
  lipBalm: convertToSlug(PRODUCT_DISPLAY_NAMES.lipBalm),
  micellarMakeupRemover: convertToSlug(
    PRODUCT_DISPLAY_NAMES.micellarMakeupRemover,
  ),
  curologyFutureProof: convertToSlug(PRODUCT_DISPLAY_NAMES.curologyFutureProof),
  deepCleanse: convertToSlug(PRODUCT_DISPLAY_NAMES.deepCleanse),
  acneCleanser: convertToSlug(PRODUCT_DISPLAY_NAMES.acneCleanser),
  starterSet: convertToSlug(PRODUCT_DISPLAY_NAMES.starterSet),
  summerEssentialsSet: convertToSlug(PRODUCT_DISPLAY_NAMES.summerEssentialsSet),
  acneCareSet: convertToSlug(PRODUCT_DISPLAY_NAMES.acneCareSet),
  travelMinisSet: convertToSlug(PRODUCT_DISPLAY_NAMES.travelMinisSet),
  barrierBalm: convertToSlug(PRODUCT_DISPLAY_NAMES.barrierBalm),
  emergencySpotPatchClusters: convertToSlug(
    PRODUCT_DISPLAY_NAMES.emergencySpotPatchClusters,
  ),
  liquidPoreExfoliant: convertToSlug(PRODUCT_DISPLAY_NAMES.liquidPoreExfoliant),
  darkSpotSerum: convertToSlug(PRODUCT_DISPLAY_NAMES.darkSpotSerum),
  hydrationSerum: convertToSlug(PRODUCT_DISPLAY_NAMES.hydrationSerum),
} as const;

export const ECOMMERCE_PRODUCT_DESCRIPTIONS = {
  emergencySpotPatches: 'Fast-acting healing support',
  acneBodyWash: '2% salicylic acid treatment • Gentle foaming formula',
  cleanser: 'Lightly foaming face wash',
  moisturizer: 'Lightweight daily hydration',
  richMoisturizer: 'Rich hydration for dry skin',
  sunscreen: 'Broad-spectrum SPF 30 • No clog UV defense',
  lipBalm: 'Nourishing vegan formula',
  micellarMakeupRemover: 'Silky gel-water concentrate',
  acneCleanser: '2.5% benzoyl peroxide treatment • Gentle clearing face wash',
  darkSpotSerum: 'Discoloration Fading Complex with niacinamide',
  hydrationSerum: 'Hydrating serum with hyaluronic acid',
} as const;

export const PRODUCT_CATEGORIES = {
  prescription: 'Prescription formulas',
  overTheCounter: 'Routine essentials',
  sets: 'Value sets',
} as const;

export const PRODUCT_LINKS = {
  internal: 'internal',
  eCommerce: 'eCommerce',
} as const;

export const ONE_NAV_TEST_PRODUCT_CATEGORIES = {
  allProducts: 'All products',
  shopbyConcern: 'Shop by concern',
  forSkin: 'For skin',
  forBody: 'For body',
  forHair: 'For hair',
};
