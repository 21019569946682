import styled from '@emotion/styled';
import { TYPOGRAPHY_STYLE } from 'radiance-ui';
import { MEDIA_QUERIES, SPACER, BREAKPOINTS } from 'radiance-ui/lib/constants';
import { css } from '@emotion/react';

import { NavLink } from '../../NavLink';

export const ProductCTAContainer = styled.div<{ enlarged?: boolean }>`
  max-width: ${BREAKPOINTS.lg}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${SPACER.large};
  h3 {
    ${({ theme, enlarged }) =>
      enlarged
        ? `font-size: ${theme.TYPOGRAPHY.fontSize.heading};`
        : TYPOGRAPHY_STYLE.bodyBold(theme)};
  }

  ${MEDIA_QUERIES.lgUp} {
    flex-direction: row;
    align-items: center;
  }

  ${MEDIA_QUERIES.xlUp} {
    max-width: ${BREAKPOINTS.sm}px;
    justify-content: ${({ enlarged }) =>
      enlarged ? 'space-between' : 'center'};
  }
`;

export const ProductCTAImageContainer = styled.div`
  ${MEDIA_QUERIES.lgUp} {
    margin-right: ${SPACER.medium};
  }
`;

export const ProductCTACopyContainer = styled.div<{
  withDescription?: boolean;
}>`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${MEDIA_QUERIES.xlUp} {
    ${({ withDescription }) => withDescription && 'width: 300px;'}
  }
`;

export const ProductCTADescription = styled.div`
  ${({ theme }) => TYPOGRAPHY_STYLE.caption(theme)};
  color: ${({ theme }) => theme.COLORS.primaryTint1};
  font-size: ${({ theme }) => theme.TYPOGRAPHY.fontSize.body};
`;

export const ProductCTAButton = styled(NavLink)`
  width: 178px;
  margin-top: ${SPACER.small};
  line-height: 1.3 !important;
`;

export const inlineBlock = css`
  display: inline-block;
`;
