import { css } from '@emotion/react';
import { MEDIA_QUERIES, ThemeType } from 'radiance-ui/lib/constants';

import { laborDayBannerStyles } from '.';

export const blackFridayFreeGiftBannerStyles = (theme: ThemeType) => css`
  ${laborDayBannerStyles(theme)}
  background: #be247a;

  ${MEDIA_QUERIES.xlUp} {
    background: none;
  }
`;
