import { IS_DEV } from 'consts/environmental';

const cookieDomain = IS_DEV ? '.curology.dev' : '.curology.com';

const date = new Date();
date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);

const utcDateOneYearFromNow = date.toUTCString();
const utcDateInThePast = 'Thu, 01 Jan 1970 00:00:01 GMT';

// The IIFE function at the top comes from Osano's official API docs: https://developers.osano.com/cmp/javascript-api/developer-documentation-consent-javascript-api
// This code snippet exists to set or delete the "trackingOptOut" cookie whenever Osano consent is set or changed.
// To do that, the code below has to be loaded as the VERY FIRST script on the entire site, even before osano.js. This is necessary to hook into the internals of Osano and listen for the "onConsentSaved" event.
// Whenever consent is accepted or denied, "onConsentSaved" fires and we set or delete the "trackingOptOut" cookie accordingly.
// The "trackingOptOut" cookie follows the patient through their user journey and prevents back-end tracking events from firing.
export const OSANO_PRE_LOAD_SCRIPT = `
  (function (w, o, d) {
    w[o] =
      w[o] ||
      function () {
        w[o][d].push(arguments);
      };
    w[o][d] = w[o][d] || [];
  })(window, 'Osano', 'data');

  window.Osano('onConsentSaved', function (consent) {
    if (consent.OPT_OUT === 'ACCEPT') { // Yes, user wants to opt out of tracking.
      document.cookie = 'trackingOptOut=true; expires=${utcDateOneYearFromNow}; domain=${cookieDomain}; path=/';
    } else if (consent.OPT_OUT === 'DENY') { // No, user does not want to opt out of tracking.
      document.cookie = 'trackingOptOut=true; expires=${utcDateInThePast}; domain=${cookieDomain}; path=/';
    }
  });
`;
