import { GatsbyImage, type GatsbyImageProps } from 'gatsby-plugin-image';

import { imageAssetToGatsbyImageData } from 'utils/contentful/imageAssetToGatsbyImageData';
import type { ImageAsset } from 'schema';

type ContentfulImageProps = Omit<GatsbyImageProps, 'image' | 'alt'> & {
  image: ImageAsset;
  alt?: string;
};

export const ContentfulImage = ({
  image,
  alt,
  ...props
}: ContentfulImageProps) => (
  <GatsbyImage
    alt={alt ?? image.title}
    image={imageAssetToGatsbyImageData(image)}
    {...props}
  />
);
