/**
 * Lets add a listener for the amplitude_init event that fires once
 * amplitude is successfully loaded to remove the amp_device_id and/or
 * devicId query param.
 *
 * The amp_device_id & deviceId param is passed to us from our other
 * properties. This is our attempt to clean up the parameter to prevent
 * users from sharing device ids when copy/pasting links.
 *
 */
export const REMOVE_AMP_DEVICE_ID_QUERY_PARAM_SCRIPT = `
  (function(){
    window.addEventListener('amplitude_init', function () {
      let ampDeviceIdCurrentLocation = new URL(window.location);
      const hasAmpDeviceId = ampDeviceIdCurrentLocation.searchParams.get('amp_device_id');
      const hasDeviceId = ampDeviceIdCurrentLocation.searchParams.get('deviceId');
      if (hasAmpDeviceId) {
        ampDeviceIdCurrentLocation.searchParams.delete('amp_device_id');
      }
      if (hasDeviceId) {
        ampDeviceIdCurrentLocation.searchParams.delete('deviceId');
      }
      if (hasAmpDeviceId || hasDeviceId) {
        window.history.replaceState({}, null, ampDeviceIdCurrentLocation);
      }
    });
  }())
  `;
