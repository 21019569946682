import antiAgingImageSrc from 'images/seo/anti-aging-og-image.jpg';

import { SEO_CORE_CONTENT, type SEOPropsType } from '..';

export const SEO_ANTI_AGING_CONTENT: SEOPropsType = {
  ...SEO_CORE_CONTENT,
  ogImage: antiAgingImageSrc,
};

export const SEO_ANTI_AGING_MARKETING_SAFE_CONTENT: SEOPropsType = {
  ...SEO_CORE_CONTENT,
  title: 'Curology Custom Skin Care',
  ogImage: antiAgingImageSrc,
};
