import React, { useEffect, useState } from 'react';

import useCart from 'utils/eCommerce/cart/useCart';

import { CartButton, CartModal } from '.';

import '@curology/ui-tokens/curology';
import '../styles/eCommTailwind.scss';
import '@curology/ui-components-web/react/index.css';

export const CartActions = ({ onOpen }: { onOpen?: () => void }) => {
  const cartUtils = useCart();
  const [isShowingCart, setIsShowingCart] = useState(false);

  if (!cartUtils?.cart) return null;

  const {
    cart: { itemCount },
    openCart,
  } = cartUtils;

  const openShoppingCart = () => {
    onOpen?.();
    setIsShowingCart(true);
  };

  useEffect(() => {
    if (openCart && !isShowingCart) {
      setIsShowingCart(true);
    }
  }, [openCart]);

  return (
    <div className="eCommTailwind" data-testid="shopping-cart">
      <CartButton itemCount={itemCount} onClick={openShoppingCart} />
      {isShowingCart && <CartModal onClose={() => setIsShowingCart(false)} />}
    </div>
  );
};
