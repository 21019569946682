import React from 'react';
import { AnimatedIcon } from './Icon.style';

export const AnimatedMenuIcon = ({ isModalOpen }: { isModalOpen: boolean }) => {
  return (
    <AnimatedIcon data-open={isModalOpen}>
      <span></span>
      <span></span>
      <span></span>
    </AnimatedIcon>
  );
};
