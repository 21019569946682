import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from '@gatsbyjs/reach-router';

import { IS_CLIENT, SITE_URL } from 'consts/environmental';

export type CanonicalHistoryType = string[];

interface CanonicalHistoryContextType {
  canonicalHistory: CanonicalHistoryType;
  currentCanonicalUrl: string;
  previousCanonicalUrl: string;
}

const CanonicalHistoryContext =
  createContext<CanonicalHistoryContextType | null>(null);

export const CanonicalHistoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { pathname } = useLocation();
  const currentCanonicalUrl = `${SITE_URL}${pathname}`;

  const initialCanonicalHistory = useMemo(() => {
    const history = [currentCanonicalUrl];
    if (IS_CLIENT) {
      history.push(document.referrer);
    }
    return history;
  }, []);

  const [canonicalHistory, setCanonicalHistory] =
    useState<CanonicalHistoryType>(initialCanonicalHistory);

  const pushToCanonicalHistory = (url: string) => {
    setCanonicalHistory((prev) => (url === prev[0] ? prev : [url, ...prev]));
  };

  useEffect(() => pushToCanonicalHistory(currentCanonicalUrl), [pathname]);

  return (
    <CanonicalHistoryContext.Provider
      value={{
        canonicalHistory,
        currentCanonicalUrl: canonicalHistory[0],
        previousCanonicalUrl: canonicalHistory[1],
      }}
    >
      {children}
    </CanonicalHistoryContext.Provider>
  );
};

export const useCanonicalHistory = (): CanonicalHistoryContextType => {
  const context = useContext(CanonicalHistoryContext);
  if (!context) {
    throw new Error(
      'useCanonicalHistory must be used within a CanonicalHistoryProvider',
    );
  }
  return context;
};
