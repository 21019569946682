import { useLocation } from '@gatsbyjs/reach-router';
import { ChiaWelcomeOfferFlagValue } from 'consts/featureFlagsAndMetrics';
import { useFlag } from 'utils/featureFlagsAndMetrics';

export const useHairRxLandingPageRoute = () => {
  const { pathname } = useLocation();

  return pathname.includes('hair-loss') || pathname.includes('hair-growth');
};

export const useHairRxPromoVariant = () => {
  const chiaWelcomeOffer: ChiaWelcomeOfferFlagValue | undefined = useFlag(
    'curology-promotions-welcome-offer-chia',
  );

  const isVariant = chiaWelcomeOffer?.code !== 'HAIR2024';

  if (isVariant) {
    return chiaWelcomeOffer;
  }

  return undefined;
};
