const DataDogUserAgent = 'datadogsynthetics';

exports.isDataDogSynthTestBot = (sentryEventRequest) => {
  // check if we're in a browser
  if (typeof window !== 'undefined') {
    return window.navigator.userAgent?.toLowerCase().includes(DataDogUserAgent);
  }

  if (sentryEventRequest.headers) {
    return sentryEventRequest.headers['User-Agent']
      ?.toLowerCase()
      .includes(DataDogUserAgent);
  }

  return false;
};
