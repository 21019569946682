import React from 'react';
import { Button, Typography } from '@curology/ui-components-web/react';

import { navExternalWithQueryString } from 'utils/marketingSafeDomain';
import { EVENTS } from 'consts/metricsTrackingEvents';
import { trackEvent } from 'utils/metricsTracking';
import APP_URLS from 'consts/appUrls';
import {
  CTA_START_YOUR_SKIN_QUIZ,
  SKIN_QUIZ_DESCRIPTION,
  SKIN_QUIZ_HEADER,
} from 'consts/copy';

import {
  SkinQuizCardWrapper,
  skinQuizButtonStyles,
} from './SkinQuizCard.style';

export const SkinQuizCard = () => {
  const handleOnClick = () => {
    trackEvent(EVENTS.skinQuizCtaClicked);
    navExternalWithQueryString(APP_URLS.welcome);
  };

  return (
    <SkinQuizCardWrapper>
      <Typography size="h2" className="pb-4" style={{ lineHeight: '1.0' }}>
        {SKIN_QUIZ_HEADER}
      </Typography>
      <Typography size="body" className="pb-6">
        {SKIN_QUIZ_DESCRIPTION}
      </Typography>
      <Button
        buttonType="secondary"
        disabled={false}
        onClick={handleOnClick}
        state="brand"
        size="md"
        style={skinQuizButtonStyles}
      >
        {CTA_START_YOUR_SKIN_QUIZ}
      </Button>
    </SkinQuizCardWrapper>
  );
};
