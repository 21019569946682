import React, { useEffect, useState } from 'react';

import useCart from 'utils/eCommerce/cart/useCart';

import {
  AlertWrapper,
  DetailsWrapper,
  RxNotice,
  Subtotal,
} from './Details.style';
import { EcommerceCTA } from '.';
import { EVENTS } from 'consts/metricsTrackingEvents';

export const Details = () => {
  const {
    cart: { containsRx, cartAlert, checkoutUrl, total },
  } = useCart();
  const [showCartAlert, setShowCartAlert] = useState('');

  useEffect(() => {
    if (cartAlert) {
      setShowCartAlert(cartAlert);
    }
  }, [cartAlert]);

  return (
    <DetailsWrapper>
      <Subtotal value={total} />
      <p> Shipping &amp; Taxes calculated at checkout </p>
      {showCartAlert !== '' && <AlertWrapper alertText={showCartAlert} />}
      {containsRx && <RxNotice />}
      <EcommerceCTA
        containsRx={containsRx}
        href={checkoutUrl}
        trackingEvent={EVENTS.ctaClicked}
      >
        Checkout
      </EcommerceCTA>
    </DetailsWrapper>
  );
};
