/**
 * Taken from site-tools.
 */
import { IS_CLIENT } from 'consts/environmental';

export function utmParams(): Record<string, string> {
  if (!IS_CLIENT) return {};

  const utmParams: Record<string, string> = {};

  new URLSearchParams(window.location.search).forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utmParams[key.substring(4)] = value;
    }
  });

  return utmParams;
}
