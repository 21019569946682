/* NOTE: As a rule, constant styles come from Radiance-ui.
 * These are styles that were hardcoded into the static pages
 * styling and do not correlate to radiance. This is TEMPORARY
 * place to store these styles until reviewed with design,
 * and they are either encorporated into Radiance or changed.
 */

const LOCAL_CONTENT_MAX_WIDTH = '1280px';
const LOCAL_EASE_FUNCTION = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)';

export { default as LOCAL_BOX_SHADOWS } from './boxShadows';
export { default as LOCAL_COLORS } from './colors';
export { default as LOCAL_SPACING } from './spacing';
export { default as LOCAL_TYPOGRAPHY_CONSTANTS } from './typography';
export { default as LOCAL_MEDIA_QUERIES } from './mediaQueries';
export { LOCAL_CONTENT_MAX_WIDTH, LOCAL_EASE_FUNCTION };
