// These functions are used when the site is loaded
// on the separate curology domain for you tube ads.

import { navigate } from 'gatsby';
import { generateQueryString, parseQueryString } from 'great-gatsby/utils';

import CONFIG from 'consts/config';
import { COOKIE_KEYS } from 'consts/cookieKeys';
import cookie from 'utils/cookie';
import { deviceId, isAmplitudeLegacySdk } from './analytics/amplitude';

export const isMarketingSafeDomain = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  const { host } = window.location;
  return host === CONFIG.MARKETING_SAFE_DOMAIN;
};

const persistMarketingSafeQueryString = (href) => {
  if (!isMarketingSafeDomain()) {
    return href;
  }
  if (typeof window === 'undefined') {
    return href;
  }

  const { search } = window.location;

  return search ? `${href}${search}` : href;
};

export const navInternalWithQueryString = (href) => {
  navigate(persistMarketingSafeQueryString(href));
};

const getAmplitudeDeviceQueryString = () => {
  const ampDeviceId = deviceId();

  return ampDeviceId
    ? { [isAmplitudeLegacySdk() ? 'amp_device_id' : 'deviceId']: ampDeviceId }
    : {};
};

export const navExternalWithQueryString = (href) => {
  if (typeof window === 'undefined') {
    return;
  }
  const [baseUrl, providedQueryString] = href.split('?');
  let newQueryString = {};
  // If the provided href already has query parameters, lets
  // add them to our new query string
  if (providedQueryString) {
    const providedQueryObject = new URLSearchParams(providedQueryString);

    newQueryString = {
      ...newQueryString,
      ...Object.fromEntries(providedQueryObject),
    };
  }

  if (isMarketingSafeDomain()) {
    newQueryString = {
      ...newQueryString,
      ...parseQueryString(window.location),
    };
  }

  if (!cookie.has(COOKIE_KEYS.trackingOptOut)) {
    newQueryString = { ...newQueryString, ...getAmplitudeDeviceQueryString() };
  }

  if (cookie.has(COOKIE_KEYS.curologyUuid)) {
    newQueryString = {
      ...newQueryString,
      ...{ uuid: cookie.get(COOKIE_KEYS.curologyUuid) },
    };
  }

  window.location.href = `${baseUrl}${generateQueryString(newQueryString)}`;
};
