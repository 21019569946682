/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onClientEntry = async () => {
  // Ensure datalayer is initialized
  window.dataLayer = window.dataLayer || [];

  // Intersection Observer Polyfill
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const disableCorePrefetching = () => {
  return window.location.host === 'curology.co';
};
